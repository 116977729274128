
export const stringValue = {
    joinThousandTextValue: 'Join thousands of Canadians who have found their pep with Pepstep. Start your online visit today in three easy steps:',
    fillOutTextValue: 'Fill out a quick online questionnaire',
    getPrescriptionTextValue: 'Get a prescription from a licensed  doctor',
    enjoyFreeTextValue: 'Enjoy free and fast delivery to your door',
    dontwaitTextValue: 'Don`t wait another day, sign up to get started!',
    RegisterTodayTextValue:"Register Today to find your pep with Pepstep!",
    firstName: 'First Name',
    lastName: 'Last Name',
    emailAddress: 'Email Address',
    phoneNumber: 'Phone Number',
    dob: 'Date of Birth',
    gender: 'Gender',
    password: 'Password',
    newPassword: 'New Password',
    oldpassword: 'Old Password',
    confirmpassword: 'Confirm Password',
    address: 'Address',
    city: 'City',
    countryText: 'Country',
    stateText: 'State',
    state: 'State',
    province: 'Province',
    medicalNumber: 'Personal Health Number',
    medicalNumberProvince: 'Personal Health Number Province',
    thisPrivacyPolicy: 'This privacy policy explains how Pepstep (the “Clinic”, “we”, “us”, or “our”) collects, uses, discloses, and protects the personal information of our patients and website visitors (the “Users”, “you”, or “your”). We are committed to your privacy and ensure that we comply with all applicable provincial health legislation laws as well as the Personal Information Protection and Electronic Document Act (“PIPEDA”). This policy applies to the website ',
    andAnyproduct: ' and any products or services that we provide through the website (the “Services”).',
    byAccessing: 'By accessing or using our Services, you agree to this privacy policy. If you do not agree with this policy, please do not access or use our Services.',
    weCollect: 'We collect personal information that you voluntarily provide to us when you register for an account, request an appointment, communicate with us, or otherwise use our Services to ensure we can match your identity. Personal information is any information that can identify you or be linked to you, such as your name, email address, phone number, date of birth, health card number, copy of your government- issued photo identification, medical history, symptoms, diagnosis, treatment, prescriptions, referrals, test results, billing information, or feedback (“',
    personalInformation: 'Personal Information',
    weCollectNonPersonal: 'We also collect non-personal information that does not directly identify you but may be associated with your device or browser, such as your IP address, device ID, browser type, operating system, language preference, location data, cookie data, or browsing activity on our website. We use this information to improve our Services, analyze trends, monitor performance, and enhance security.',
    weCollectAndUse: 'We collect and use your personal information for the following (but not limited to) purposes:',
    weUseyourNonPersonal: 'We use your non-personal information for the following purposes:',
    weDontSell: 'We DO NOT sell or rent your personal information to third parties. We only share your personal information with your consent or as described in this policy for the ability to provide care, quality control and abide by compliance rules. Any personal data that is shared will be anonymized and aggregated (when possible).',
    however: 'However, no method of transmission or storage is completely secure and we cannot guarantee the absolute security of your information. If you have any questions about our security practices or if you suspect any security breach that directly or indirectly impacts you, please contact us immediately at ',
    consentForCollect: 'Consent for the collection of Personal Information is obtained before it is used or disclosed, and it can be given orally, electronically, or in writing. The specific form of consent, whether expressed or implied, depends on the sensitivity of the information and the reasonable expectations of the user. In some cases, a third party may obtain your consent on our behalf for sharing Personal Information. You have the right to withdraw your consent by notifying us and explicitly instructing that your personal health information not be used or disclosed for healthcare purposes without your consent.',
    weRetainYour: 'We retain your personal information for as long as necessary to provide you with our Services and fulfill the purposes described in this policy. We may also keep your personal information for longer periods if required or permitted by law or professional standards, such as for tax, accounting, legal, or audit purposes.',
    whenWeNoLonger: 'When we no longer need your personal information, we will securely delete or destroy it in accordance with applicable laws and regulations.',
    pleaseNoteThat: 'Please note that some of these rights may be subject to limitations or exceptions under applicable laws or regulations. We will respond to your requests within a reasonable time frame and in accordance with our legal obligations. Users may be requested to provide sufficient information to permit access to the existence, use, or disclosure of their Personal Information.',
    weUseCookies: 'We use cookies and other tracking technologies on our website to collect and store non-personal information about your device and browsing activity. Cookies are small text files that are placed on your device when you visit a website. They help us improve our Services and customize your user experience. For example, we use cookies to:',
    youCanChhoseAccept: 'You can choose to accept or reject cookies by adjusting your browser settings. However, if you disable cookies, some features and functionalities of our Services may not work properly.',
    weMayAlsoUse: 'We may also use other tracking technologies on our website, such as web beacons, pixels, tags, or scripts, to collect and store non-personal information about your device and browsing activity. These technologies help us track the effectiveness of our marketing campaigns and promotions, monitor and analyze the usage and performance of our Services, and enhance the security and reliability of our Services.',
    thisWebsiteUsing: 'This website is using Tidio, a chat platform that connects users with the customer support of Pepstep. We are collecting email addresses/names/ only with the consent of the users, in order to start the chat. The messages, your personal information and data exchanged are stored within the Tidio application and transmitted in accordance with the General Data Protection Regulation (GDPR). Please refer to Tidio privacy policy for more information ',
    youCanOptOut: 'You can opt out of some of these tracking technologies by adjusting your browser settings or using third-party tools. However, if you disable these technologies, some features and functionalities of our Services may not work properly.',
    ourWebsiteMayContain: 'Our website may contain links to other websites that are not operated by us. These links are provided for your convenience and reference only. We do not endorse or control these websites and we are not responsible for their privacy practices or content. We encourage you to review the privacy policies of any third-party websites that you visit before providing them with any personal information.',
    weMayUpdateThispolicy: 'We may update this policy from time to time to reflect changes in our Services, legal requirements, or best practices. We will notify you of any material changes by posting a notice on our website or sending you an email. The changes will take effect as soon as they are posted on our site. Your continued use of our Services after the effective date of the updated policy will constitute your acceptance of the changes. We encourage you to review this policy periodically to stay informed about how we collect, use, share, and protect your personal information. If you do not agree to the new Policy, you must stop using the Services and cancel any services with us.',
    weWillAttempt: 'We will attempt to respond to your inquiries within a reasonable time frame and in accordance with our legal obligations and will advise users in writing if this cannot be met.',
    welcomeToPepstepPlatform: 'Welcome to Pepstep, a platform that connects you with health professionals and provides personalized wellness plans. These health care professionals do not replace your relationship with your family medical doctor (FMD) and if you have a medical emergency, immediately call your FMD or dial 911. We encourage to continue seeing your family medical doctor for any pre-existing or ongoing medical issues during your time with Pepstep. These Terms of Use (“Terms”) govern the access and/ or use by individuals of our website, mobile application, and any other products or services we offer (collectively, the “Services”) from Pepstep ("you", " your", " User"). By using the Services, you agree to be bound by these Terms and our Privacy Policy which establish a contractual relationship between you and Pepstep.',
    allPhysicsNurse: 'All physicians and nurse practitioners providing Healthcare Services or Informational Services, (“',
    youMustBeAtleast18Year: 'You must be at least 18 years old to use the Services and are located in a province where we operate (depending on the type of service). By using the Services, you represent and warrant that you are 18 years of age or older, have a government issued health care card/number and that you have the right, authority, and capacity to enter into and abide by these Terms.',
    thePersonalInformationYou: 'The Personal Information you provide to Pepstep and its Authorized Clinicians is collected and is governed by the Terms and Privacy Policy. The Privacy Policy details the collection, usage, disclosure, retention, and destruction of Personal Information. Since the Privacy Policy is an integral part of these Terms, it is crucial that you review it before agreeing to these Terms which you can find here https://www.pepstep.com/privacy-policy.',
    toAccessSomeFeature: 'To access some features of the Services, you may need to register for an account. When you register for an account, you agree to provide accurate, current, and complete information about yourself and to update your information as necessary. You are responsible for maintaining the confidentiality of your account password and for any activity that occurs under your account. You agree to notify us immediately if you suspect any unauthorized access or use of your account.',
    youUnderstandThatYour: 'You understand that your personal health information will be stored securely and confidentially by Pepstep and its service providers in accordance with applicable privacy laws . Please refer to the Privacy Policy for more information. You can access, correct, or request a copy of your personal health information at any time by contacting Pepstep at ',
    weOwnAllRight: 'We own all rights, title, and interest in and to the Services and the content on the Services, including but not limited to trademarks, logos, graphics, text, images, videos, software, and code. Except as expressly permitted by these Terms, you may not use, reproduce, display, perform, distribute, or otherwise exploit any of our intellectual property rights without our prior written consent.',
    youRetainAllRightInAndToANy: 'You retain all rights in and to any content that you post, upload, or transmit through the Services, such as reviews, comments, feedback, or suggestions. However, by doing so, you grant us a non-exclusive, royalty-free, perpetual, irrevocable, and sublicensable right to use, modify, adapt, publish, translate, create derivative works from, distribute, and display such content in any media format and through any media channel for any purpose related to the Services. You also waive any moral rights you may have in such content and represent and warrant that you have all necessary rights and consents to grant us such license.',
    willBeUsedForTheDelivery: ' will be used for the delivery of healthcare and other services. You will be asked by the Authorized Clinician to provide certain Personal Information for identification purposes and to ensure they are able to assist you in the best possible way with their service(s).',
    youAreResponsible: 'You are responsible for ensuring the security and privacy of your individual device(s) that are used to interact with the Pepstep virtual platform. Furthermore, you must ensure that virtual visits and communications are received in a suitable environment that facilitates appropriate privacy and security with respect to your personal health information.',
    althoughThePepstep: 'Although the Pepstep virtual clinic or Authorized Clinicians will make an effort to promptly review and respond to your electronic communication, there is no guarantee that all electronic communications will be reviewed and addressed within a specific timeframe. Please note that the Services provided should not be relied upon for medical urgencies, emergencies or time-sensitive matters.',
    ifYouSendAnElectronic: 'If you send an electronic communication that necessitates a response from the Authorized Clinicians and you haven t received a reply within a reasonable timeframe, it is your responsibility to follow up and confirm whether the intended recipient received your message and when you can expect a response. Please be aware that electronic communication cannot substitute for in-person, telephone conversations, or clinical examinations when necessary, nor should it replace seeking immediate medical attention in emergency situations',
    youAreAccountable: 'You are accountable for ensuring that you follow up on the Authorized Clinicians electronic communication and schedule appointments as needed.Any electronic communications regarding may be documented and included in your medical record, with authorized individuals such as staff and billing personnel potentially accessing those communications.These staff are bound by the relevant privacy policies of the organization.The Authorized Clinicians may also share electronic communications with relevant individuals involved in your care using one or more of the services available.',
    theAuthorizedClinicians: 'The Authorized Clinicians will not disclose electronic communications to third parties, including family members, without obtaining your prior written consent, unless authorized or mandated by law. It is important for you to inform the Pepstep platform and the authorized clinicians of any specific types of information you prefer not to be transmitted through the aforementioned communication methods.',
    byUsingThePepstepPlatform: 'By using the Pepstep platform, you acknowledge that downloading and viewing of Content is undertaken entirely at your own risk. Pepstep cannot and does not provide any guarantee or warranty regarding the compatibility of the Pepstep platform or the Content with your computer or mobile system. It is your responsibility to implement necessary safeguards to protect the security and integrity of your computer system. You are also accountable for any costs incurred for services, repairs, or connections required for your computer system as a result of using our platform.',
    somefeatureOfService: 'Some features of the Services may require payment of fees. You agree to pay all applicable fees for the Services you select. We may change our fees at any time by posting a new pricing structure on our website or through the Services. Your continued use of the Services after such change constitutes your acceptance of the new fees.',
    weuseThirdParty: 'We use third-party payment processors to process your payments for the Services. You agree to comply with their terms and conditions when making payments through the Services. We are not responsible for any errors or issues that may arise from their processing of your payments.',
    youMaycancelYourSubscription: 'You may cancel your subscription to the Services at any time by following the instructions on our website or through the Services. Your cancellation will take effect at the end of your current billing cycle. You will not receive any refunds or credits for any unused portion of your subscription.',
    weMayCancelOrSuspend: 'We may cancel or suspend your account or access to the Services at any time, for any reason, and without notice. If we do so, you will not be entitled to any refunds or compensation. We may also delete any content or data associated with your account. We are not liable for any loss or damage that may result from such cancellation or suspension.',
    weGladYouChose: 'We’re glad you chose Pepstep, a virtual clinic that connects you with qualified physicians online. Before you start using our service, we want to make sure you understand a few things about how it works and what your rights and responsibilities are. By using our service, you agree to these terms and conditions in full.',
    medicalDiagnosisTreatment: 'Medical diagnosis and treatment may rely upon certain self-reported medical data. This medical data includes but is not limited to height, weight, and blood pressure. You acknowledge that you possess the ability to accurately obtain (i.e. have access to the required devices and the skill to appropriately measure these parameters) and report this information to the Pepstep platform. Should you not possess this ability, you agree to regularly update this information at a location where these measurements can be physically obtained (i.e. medical office, pharmacy, etc...). Should you be unable or unwilling to comply with this request, you may not be eligible for certain services offered through the Pepstep platform.',
    AsWithAnyOthermedical: 'As with any other medical consultation, no results can be guaranteed or assured: your Authorized Clinician may determine that Pepstep services are not appropriate for some or all of your treatment needs, and accordingly may elect not to provide support to you through the use of the Pepstep platform.',
    thePepstepDoctorMayNot: 'The Pepstep doctor may not be able to provide a definitive diagnosis or treatment plan solely based on a virtual visit. Further tests, referrals, or in-person consultations may be recommended by the Pepstep doctor as deemed appropriate. In using this platform you acknowledge that you have access to in person medical care should the need arise. You acknowledge that in cases of medically urgent or emergent situations, you will access the appropriate services such as urgent care facilities, or emergency departments.',
    prescriptionsForMedications: 'Prescriptions for medications may be provided by the Pepstep doctor if they determine it to be clinically appropriate and safe for my condition. It is my responsibility to inform the Pepstep doctor about any allergies, medical conditions, or medications I am currently taking or have taken in the past.',
    certainMedications: 'Certain medications, including controlled substances, narcotics, opioids, benzodiazepines, stimulants, and medical cannabis, may require an in-person consultation with a doctor and may not be suitable for online prescribing.',
    ihaveTheRightRefuse: 'I have the right to refuse any treatment or medication recommended by the Pepstep doctor. It is my responsibility to understand the potential benefits and risks associated with any treatment or medication before accepting it.',
    iUnderstandThatPepstep: 'I understand that the Pepstep doctor may share my personal health information with other healthcare providers involved in my care, such as my primary care provider, pharmacist, or specialist. I consent to this sharing of information to ensure the provision of quality care and continuity of care.',
    pepstepNothealthCare: 'Pepstep is not a healthcare provider and the Authorized Clinicians do not replace your relationship with your family medical doctor (FMD) and if you have a medical emergency, immediately call your FMD or dial 911. You are responsible for following up with your FMD or specialist as needed',
    weTakeYourPrivacy: 'We take your privacy and personal health information very seriously. We use secure technology to store and transmit your data, and we comply with all applicable laws and regulations regarding your privacy. We will only share your personal health information with the Authorized Clinicians who provide you with care through our service, or as required by law. You can read more about how we protect your privacy in our Privacy Policy.',
    weWorkWithlicensed: 'We work with licensed physicians who are qualified to provide care through telemedicine. We verify their identity, credentials, and location before they join our service. We also monitor their performance and feedback from patients to ensure they meet our standards of quality and safety.',
    whenYouuseOurService: 'When you use our service, you will be asked to provide some information about yourself, your health condition, and your medical history. This helps us match you with the most suitable physician for your needs. The physician will then contact you through a video call or a chat message to assess your condition, provide diagnosis and treatment advice, prescribe medication if needed, and refer you to other services if necessary.',
    pleaseNotThatTelemedicine: 'Please note that telemedicine has some limitations compared to in-person care. For example, the physician may not be able to examine you physically or perform certain tests or procedures. There may also be some extra risks associated with telemedicine, such as technical issues, communication errors, or security breaches. The physician will explain these limitations and risks to you before providing care, and will obtain your consent to proceed. You can withdraw your consent at any time by ending the call or chat.',
    youAreResponsibleProviding: 'You are responsible for providing accurate and complete information about yourself and your health condition, following the physician’s instructions and advice, and seeking emergency care if your condition worsens or requires urgent attention. You should also inform your regular doctor about the care you received through our service, especially if you received a prescription or a referral.',
    toUseOurServiceYouNeed: 'To use our service, you need to create an account with us and provide some personal information, such as your name, date of birth, email address, phone number, and payment details. We use this information to verify your identity, contact you, and process your payments. You are responsible for keeping your account information secure and up-to-date.',
    weMayAlsoAskYou: 'We may also ask you to show us a valid photo ID before providing care, such as a driver’s license or a passport. This helps us confirm that you are who you say you are, and that you are eligible to use our service. We will only use your photo ID for verification purposes and will not store it on our system.',
    thisServiceProvided: 'The Services are provided “as is” and “as available” without any warranties of any kind, either express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, non-infringement, or accuracy. We do not warrant that the Services will be uninterrupted, error-free, secure, or free of viruses or other harmful components. We do not warrant that the Services will meet your requirements or expectations, or that the results obtained from the Services will be accurate, reliable, or beneficial. You use the Services at your own risk and discretion.',
    toTheFullest: 'To the fullest extent permitted by law, we and our affiliates, directors, officers, employees, agents, and licensors are not liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses, arising out of or in connection with your use of or inability to use the Services, even if we have been advised of the possibility of such damages. In no event will our total liability to you for all claims arising from or relating to the Services exceed the amount of fees you have paid us for the Services in the past six months.',
    youAreAgreeIndemnify: 'You agree to indemnify, defend, and hold harmless us and our affiliates, directors, officers, employees, agents, and licensors from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys’ fees) arising from or relating to your use of or access to the Services, your violation of these Terms, your violation of any rights of a third party, or your content. We reserve the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which case you agree to cooperate with us in asserting any available defenses.',
    theseTermsAndDispute: 'These Terms and any dispute arising from or relating to your use of the Services are governed by the laws of Canada, without regard to its conflict of laws principles. You agree that any legal action or proceeding between you and us will be brought exclusively in a court of competent jurisdiction located in the province where your services are being provided. You waive any objection to venue or jurisdiction in such courts.',
    weMayReviseUpdate: 'We may revise and update these Terms from time to time to reflect changes in our Services, legal requirements, or best practices. We will notify you of any changes by posting the new Terms on our website sending you an email. The changes will take effect as soon as they are posted on our site. Your continued use of our Services after the effective date of the updated Terms will constitute your acceptance of the changes. If you do not agree to the new Terms, you must stop using the Services and cancel any services with us.',
    faqWhatIsEstimated: 'What is the estimated timeline for receiving my  prescribed medication after completing the assessment with the assigned doctor?',
    faqTheEstimatedTimelineForContent: 'The estimated timeline for receiving your prescription will vary depending on when you complete your assessment with your medical practitioner.Typically, once you have completed your assessment with the doctor, the medication will be sent from Pepsteps pharmacy partner(if chosen) where you can expect to receive your prescription within a few business days.If an alternate pharmacy is chosen, delivery times will vary based off their schedule.',
    faq2QustnHowCanI: 'How can I cancel my subscription?',
    faq2AnsToCancelYourSubscription: 'To cancel your subscription, please log in to your account on the Pepstep website and navigate to "Account ", then "Subscription" section. Follow the on screen checks. to cancel your subscription. If you encounter any difficulties, you can also reach out to our customer support team for assistance at https://pepstep.ca/contact',
    faq3QstnIfIDecide: 'If I decide to cancel my subscription, will I still be able to receive my prescribed medication from Pepstep?',
    faq3AnsNoOnceYouCancel: 'No. Once you cancel your subscription to Pepstep you will no longer have access the medical practitioners and future scripts. You must be subscribed to the platform to receive medication prescriptions from Pepstep.',
    faq4QstnWhatShouldI: 'What should I do if there is a delay in receiving my medication?',
    faq4AnsIfYouExperince: 'If you experience a delay in receiving your medication, we apologize for any inconvenience caused. Please reach out to our customer support team, and they will investigate the issue promptly. They will work to resolve the delay and keep you informed throughout the process.',
    faq5QstnAreThereAnyAdditional: 'Are there any additional costs or fees associated with cancelling my subscription?',
    faq5AnsPepstepDoesNotCharge: 'Pepstep does not charge any additional costs or fees for canceling your subscription and it is recommended to review the terms and conditions associated with your subscription plan.',
    faq6QstnCanIModify: 'Can I modify my prescription or request a refill through the online platform?',
    faq6AnsYesYouCanRequest: 'Yes, you can request a prescription refill through Pepstep when your current prescription is near completion by scheduling an appointment with your medical practitioner. Additionally, if you have any concerns or need to modify your prescription, please communicate with your medical practitioner during your scheduled appointments.',
    faq7QstnIsThereSpecific: 'Is there a specific schedule for taking my medication and are there any important guidelines I should follow?',
    faq7AnsYourMedicalPractitioner: 'Your medical practitioner will provide you with detailed instructions on how to take the prescribed medication. It is crucial to follow their guidelines precisely to ensure safe and effective use of the medication.',
    faq8QstnWhatShouldIDo: 'What should I do if I experience any side effects or have concerns about the medication?',
    faq8AnsYouMayExperience: 'You may experience some side affects as outlined by your medical practitioner. If you experience server side effects or have concerns about the medication, immediately contact us to schedule time with you medical practitioner for guidance. Your safety and well-being are our top priority, and we are here to address any concerns you may have.',
    faq9QstnHowCanIReach: 'How can I reach out to the medical team or customer support if I have any questions or need assistance?',
    faq9AnsYouCanReachOut: 'You can reach out to our medical team or customer support through the "Contact Us" section on the Pepstep website. We also provide contact information and support channels for quick and efficient assistance.',
    faq10QstnAreThereAnyDietary: 'Are there any dietary or lifestyle recommendations that complement the weight loss program with the prescribed medication?',
    faq10AnsYesYourMedical: 'Yes, your medical practitioner may provide you with dietary and lifestyle recommendations to complement the weight loss program with the prescribed medication. These recommendations aim to optimise your results and overall well-being.',
    faq11QstnWhatMeasureAreIn: 'What measures are in place to ensure the security and confidentiality of my personal and medical information on the website?',
    faq11AnsPepstepTakesData: 'Pepstep takes data security and confidentiality seriously. We employ industry-standard encryption and data protection measures to safeguard your personal and medical information. Rest assured that your data is handled with the utmost care and complies with all relevant privacy laws.',
    faq12QstnIsThereOption: 'Is there an option for rescheduling appointments with the assigned doctor if necessary?',
    faq12AnsYesYoucanReschedule: 'Yes, you can reschedule your appointments from the Pepstep portal if needed. Please log in to your account and navigate to the "Appointments" section to find options for rescheduling or contact our support team for assistance.',
    faq13QstnAreThereAnyResource: 'Are there any resources or educational materials available on the website to help me better understand the weight loss program and medication?',
    faq13AnsAbsolutely: 'Absolutely! Pepstep provides educational resources and materials on the website and during your appointments help you better understand the weight loss program. These resources are designed to empower you with knowledge and support your weight loss journey.',



}