import '../utils/page-css/pagelayout.css'
import CommonProgressBar from '../common/progress-bar';
import React, { useEffect, useState } from 'react';
import { GlobalThemeColor } from '../theme/color';
import { AppImage } from '../theme/app.images';
import CommonRadioButton from '../common/radiobutton';
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react';
import Modal from 'react-modal';
import configJson from "../auth_config.json";
import { useFormik } from 'formik';
import { questionSchema } from '../utils/schemas';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

const Questions = () => {
    const navigate = useNavigate();
    const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
    const [productData, setProductData] = useState(null);
    const[questionComplete,setquestionComplete]=useState("")
    const [userData, setUserData] = useState(null);
    const [errorForBreasting, setErrorForBreasting] = useState(false);
    const [errorForFamilyDoctor, setErrorForFamilyDoctor] = useState(false);
    const [errorForHyper, setErrorForHyper] = useState(false);

    async function fetchUserData() {
        const token = configJson.AUTH_MANAGEMENT_TOKEN;
        try {
            const response = await axios.get(configJson.AUTH_USER_DETAILS + localStorage.getItem("AuthUserSub"), {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            if(response.data.user_metadata.questionComplete == "true"){
                navigate('/dashboard')
            }
            

            setUserData(response.data.app_metadata.stripe_customer_id); // Set the response data in the state
            localStorage.setItem("stripe_customer_id", response.data.app_metadata.stripe_customer_id)
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }

    async function fetchProductData() {
        try {
            // Replace 'YOUR_STRIPE_SECRET_KEY' with your actual Stripe Secret Key
            const token = configJson.STRIPE_TOKEN;
            const response = await axios.get('https://api.stripe.com/v1/products', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            // Assuming the response contains an array of products, you can set the first product in the state
            if (response.data && response.data.data && response.data.data.length > 0) {
                setProductData(response.data.data[0]);
                localStorage.setItem("priceId", response.data.data[0])

            }
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    }

    useEffect(() => {
        if (localStorage.getItem("userEmail") && localStorage.getItem("userPassword")) {
            userAuthId()
        }


        if (isAuthenticated === true) {
            console.log("user:", user.sub)
        } else {
            console.log("else")
        }

        async function userAuthId() {
            const token = localStorage.getItem("access_token")
            // console.log("tyuio" + UserToken.id_token)
            const response = await axios.post(configJson.AUTH_USER, {
                "id_token": localStorage.getItem("id_token")
            }, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });

            localStorage.setItem("AuthUserSub", response.data.sub)
            // console.log("AuthUserId: " + JSON.stringify())

        }
        fetchUserData()
        fetchProductData();

    }, [])





    const [progress, setProgress] = useState(50);
    const [hide, setHide] = useState(false);

    const [weight, setWeight] = useState('');

    const [question_number, setQuestion_number] = useState(1);
    const [selectYesNo, setSelectYesNo] = useState('')
    const [selectYesNoOne, setSelectYesNoOne] = useState('')
    const [selectHyper, setSelectHyper] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEligible, setIsEligible] = useState(false);
    const [check, setCheck] = useState(false);
    const [checkOne, setCheckOne] = useState(false);


    const selectedValue = [
        {
            key: '1',
            value: 'Yes',
            text: 'Yes'
        }, {
            key: '2',
            value: 'No',
            text: 'No'
        }
    ]
    const handleOnChange = (event) => {
        console.log(event)
        setSelectYesNo(event)

    }
    const handleOnChangeOne = (event) => {
        console.log(event)
        setSelectYesNoOne(event)


    }
    const openModal = (eligible) => {
        setIsEligible(eligible);
        setIsModalOpen(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const selectedHyperValue = [
        {
            key: '1',
            value: 'Hypertension',
            text: 'Hypertension'
        },
        {
            key: '2',
            value: 'Dyslipidemia',
            text: 'Dyslipidemia'
        },
        {
            key: '3',
            value: ' Fatty liver disease',
            text: 'Fatty liver disease'
        },
        {
            key: '4',
            value: 'Pre-diabetes',
            text: 'Pre-diabetes'
        }, {
            key: '5',
            value: 'Obstructive sleep apnea',
            text: 'Obstructive sleep apnea'
        }, {
            key: '6',
            value: 'Osteoarthritis',
            text: 'Osteoarthritis'
        }, {
            key: '7',
            value: 'Coronary artery disease (stable)',
            text: 'Coronary artery disease (stable)'
        }, {
            key: '8',
            value: 'Depression/anxiety (related to weight)',
            text: 'Depression/anxiety (related to weight)'
        }, {
            key: '9',
            value: 'None Of the above',
            text: 'None Of the above'
        }
    ]
    const handleHyperOnChange = (event) => {
        console.log(event)
        setSelectHyper(event)
    }




    const questionFormData = {
        heightFeet: '',
        heightInches: '',
        weight: '',

    }

    const { values, errors, handleBlur, handleChange, handleSubmit, touched } = useFormik({
        initialValues: questionFormData,
        validationSchema: questionSchema,
        validateOnChange: true,
        validateOnBlur: false,
        onSubmit: (values, action) => {

            APICall(values);

        }
    })

    const APICall = (values) => {

        const user_metadata = localStorage.getItem('user_metadata');
        const data=JSON.parse(user_metadata)
        const re = /'/gi;
        const usData = data.userData.replace(re,"")
        const data2= JSON.parse(usData)

  // console.log("data2: " + JSON.stringify(data2))
  
  const { province}=data2

        if (question_number === 1) {
            const heightInFeet = parseInt(values.heightFeet) || 0;
            const heightInInches = parseInt(values.heightInches) || 0;
            const heightInMeters = (heightInFeet * 0.3048) + (heightInInches * 0.0254); // Convert to meters
            localStorage.setItem('height', heightInMeters);
            localStorage.setItem('weight', values.weight); // Save weight as it is in pounds
            if (selectYesNo === '') {
                console.log("check..." + selectYesNo);
                setCheck(true)
            } else if (selectYesNoOne === '') {
                console.log("checkOne..." + selectYesNoOne);
                setCheckOne(true)
            }
            else {
                setProgress(100);
                console.log("all values")
                setQuestion_number(2);
                setHide(true)
            }
            //setErrorForHyper(true)

        } else if (selectHyper === '') {
            swal('Validation Error', 'Please check any one', 'error');
            setErrorForHyper(true);

        } else if (question_number === 2) {
            const heightInMeters = parseFloat(localStorage.getItem('height'));
            const weightInKg = parseFloat(localStorage.getItem('weight')) * 0.453592; // Convert weight to kilograms
            const bmi = weightInKg / (heightInMeters * heightInMeters);
            const isPregnant = selectYesNo === 'Yes';
            console.log('BMI:', bmi); // Print the calculated BMI
            if (bmi >= 30 || (bmi >= 27 && bmi < 30 && selectHyper !== 'None Of the above' && !isPregnant)) {
                if (province !== 'AB' && province !=='ON') {
                    // User is not eligible due to province
                    
                    navigate('/non-eligible');
                    console.log('You are not eligible for the payment due to province.');
                    return;
            
                }
                
                //   debugger
                const vitalPayload = {
                    height: heightInMeters,
                    weight: weight,
                    bmi: bmi
                }
                localStorage.setItem("vitalPayload", JSON.stringify(vitalPayload))
                navigate('/eligible')
                // Moved the handlePayment call to show the modal first
            } else {
                    // User is not eligible due to BMI and province
                    
                    navigate('/non-eligible');
                    console.log('You are not eligible for the payment due to BMI and province.');
            }

        }

    }


    return (
        
        <div className='question-main-container'>

            <div className='questions-progress-bar'>
                <CommonProgressBar bgcolor={
                    GlobalThemeColor.appColor.primaryColor
                }
                    progress={progress}
                    height={'15px'} />

            </div>
            {/* {" Questions"} */}
            <div className='question-content-container-one'>



                <div>
                    {/* question-content-container */}
                    <div className='question-content-container'>

                        <div style={{ height: '56.0rem' }}>
                            <p style={
                                {
                                    color: GlobalThemeColor.appColor.primaryTextColor,
                                    paddingTop: '4.0rem',
                                    fontFamily: 'sans-serif',
                                    fontSize: '1.6rem',
                                    fontWeight: '300',
                                    paddingLeft: '4.0rem'
                                }
                            }>{"Page" + "  "}
                                {
                                    hide === false ? '1' : question_number
                                }
                                {"/2"}
                            </p>

                            {
                                question_number === 1 ? (
                                    <div>
                                        <form onSubmit={() => {
                                            console.log("yes")
                                            handleSubmit()
                                        }

                                        }>
                                            <div className='question-main-row-container'>

                                                <div className='question-main-column-container'>

                                                    <a className='question-address-text-style'>What is your height?</a>

                                                    <input
                                                        type="number" placeholder="Type in feet"
                                                        className="question-input-container"
                                                        maxLength={2}
                                                        value={values.heightFeet}
                                                        onBlur={handleBlur('heightFeet')}
                                                        onChange={handleChange('heightFeet')}
                                                        error={
                                                            !!errors.heightFeet && !!errors.heightFeet
                                                                ? true : false
                                                        } />
                                                    {
                                                        errors?.heightFeet && touched?.heightFeet &&
                                                        <p className='question-error-text-style'>{errors?.heightFeet}</p>
                                                    }


                                                </div>
                                                <div style={{ marginTop: '3.7rem' }}>
                                                    <input

                                                        type="number" placeholder="Type in inches"
                                                        className="question-input-container"
                                                        value={values.heightInches}
                                                        maxLength={2}
                                                        onBlur={handleBlur('heightInches')}
                                                        onChange={handleChange('heightInches')}
                                                        error={
                                                            !!errors.heightInches && !!errors.heightInches
                                                                ? true : false
                                                        } />
                                                    {
                                                        errors?.heightInches && touched?.heightInches &&
                                                        <p className='question-error-text-style'>{errors?.heightInches}</p>
                                                    }
                                                </div>



                                            </div>
                                            <div
                                                style={{ marginLeft: '3.7rem' }}
                                                className='question-main-column-container'>

                                                <a className='question-address-text-style '>What is your weight</a>


                                                <input
                                                    className='question-input-container'
                                                    type='text'
                                                    placeholder='Type your weight(Lb)'
                                                    value={values.weight}
                                                    maxLength={12}
                                                    onBlur={handleBlur('weight')}
                                                    onChange={handleChange('weight')}

                                                    error={
                                                        !!errors.weight && !!errors.weight
                                                            ? true : false
                                                    } />
                                                {
                                                    errors?.weight && touched?.weight &&
                                                    <p className='question-error-text-style'>{errors?.weight}</p>
                                                }

                                            </div>

                                            <a className='question-address-text-style'
                                                style={
                                                    {
                                                        marginLeft: '4.5rem',
                                                        marginTop: '2.0rem'
                                                    }
                                                }>
                                                Are you currently pregnant, breastfeeding or trying to concieve?</a>

                                            <div style={
                                                {
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                    display: 'flex',
                                                    marginLeft: '4.5rem',
                                                    marginRight: '4.0rem'
                                                }
                                            }>
                                                {
                                                    selectedValue.map((ele) => {
                                                        return (
                                                            <>
                                                                <CommonRadioButton id={
                                                                    ele.key
                                                                }
                                                                    value={
                                                                        ele.value
                                                                    }
                                                                    onChange={
                                                                        () => {
                                                                            handleOnChange(ele.value)
                                                                        }
                                                                    }
                                                                    selected={selectYesNo}

                                                                    text={
                                                                        ele.text
                                                                    } />
                                                            </>
                                                        )
                                                    })
                                                } </div>
                                            {
                                                errorForBreasting === true && selectYesNo.length === 0 ? (
                                                    <div style={{ marginLeft: '4.0rem', marginTop: '-0.5rem' }}>
                                                        <p className='question-error-text-style'>Please check breastfeeding or trying conceive</p>
                                                    </div>
                                                ) : (
                                                    <div></div>
                                                )

                                            }

                                            <a className='question-address-text-style'
                                                style={
                                                    {
                                                        marginLeft: '4.5rem',
                                                        marginTop: '2.0rem'
                                                    }
                                                }>
                                                Do you currently have a Family Medical Doctor (FMD)?</a>
                                            <div style={
                                                {
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                    display: 'flex',
                                                    marginLeft: '4.5rem',
                                                    marginRight: '4.0rem'
                                                }
                                            }>
                                                {
                                                    selectedValue.map((ele) => {
                                                        return (
                                                            <>
                                                                <CommonRadioButton id={
                                                                    ele.key
                                                                }
                                                                    value={
                                                                        ele.value
                                                                    }
                                                                    onChange={
                                                                        () => {
                                                                            handleOnChangeOne(ele.value)
                                                                        }
                                                                    }
                                                                    selected={selectYesNoOne}

                                                                    text={
                                                                        ele.text
                                                                    } />
                                                            </>
                                                        )
                                                    })
                                                } </div>
                                            {
                                                errorForFamilyDoctor === true && selectYesNoOne.length === 0 ? (
                                                    <div style={{ marginLeft: '4.0rem', marginTop: '-0.5rem' }}>
                                                        <p className='question-error-text-style'>Please check family medical doctor</p>
                                                    </div>
                                                ) : (
                                                    <div></div>
                                                )

                                            }
                                        </form>
                                    </div>
                                ) : (
                                    <div>
                                        <div className='question-main-row-container'
                                            style={
                                                {
                                                    marginLeft: '4.0rem',
                                                    marginTop: '2.0rem'
                                                }
                                            }></div>
                                        <a className='question-address-text-style'
                                            style={
                                                { marginLeft: '4.0rem' }
                                            }>
                                            Do you have any of the following?</a>

                                        <div style={
                                            {
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                display: 'flex',
                                                marginLeft: '4.0rem',
                                                marginRight: '4.0rem'
                                            }
                                        }>
                                            {
                                                selectedHyperValue.map((ele) => {
                                                    return (
                                                        <>
                                                            <CommonRadioButton id={
                                                                ele.key
                                                            }
                                                                value={
                                                                    ele.value
                                                                }
                                                                onChange={
                                                                    () => {
                                                                        handleHyperOnChange(ele.value)
                                                                    }
                                                                }
                                                                selected={selectHyper}

                                                                text={
                                                                    ele.text
                                                                } />
                                                        </>
                                                    )
                                                })
                                            } </div>



                                    </div>
                                )
                            } </div>





                    </div>
                    <div className='question-arrow-right'>
                        <a onClick={() => {
                            handleSubmit()

                            if (selectYesNo.length === 0) {
                                setErrorForBreasting(true)
                            } if (selectYesNoOne.length === 0) {
                                setErrorForFamilyDoctor(true)

                            } else {
                                console.log('questionVlue' + question_number);
                            }

                        }}><img src={
                            AppImage.arrowRightIcon
                        }
                            style={
                                {
                                    width: '4.0rem',
                                    height: '4.0rem'
                                }
                            } /></a>
                    </div>

                </div>


            </div>


            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Eligibility Modal"
            >
                <div className="modal-content">
                    {isEligible ? (
                        <p className="message">You are eligible for the subscription. We are redirecting you to the payment page.</p>
                    ) : (
                        <p className="message">You are not eligible for the payment.</p>
                    )}
                </div>
            </Modal>


        </div>


    )
}
export default Questions;
