
import PublicNavHeader from './utils/public-header/PublicNavHeader';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { GlobalThemeColor } from './theme/color';
import Footer from './utils/footer/Footer';
import { AllImportPages } from './utils/import-pages';
import { useState, useEffect } from 'react';
import DashboardNavBar from './utils/private-header/DashboardNavBar';
// import JwtTokenGenerator from './JwtTokenGenerator';
import { LineWave } from 'react-loader-spinner'
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client'
import configJson from "./auth_config.json";
import PrivateRoute from './PrivateRoute';
import Page404 from './Page404';
import { KJUR, KEYUTIL } from 'jsrsasign';


const privateKey = `-----BEGIN RSA PRIVATE KEY-----
MIIEpAIBAAKCAQEAu6hWJAJWrPai2cxHFBEOz/P3oRdv/Lf2FzEkBxCORLTJA4c4
7t7ykQSlbu4PI5XPQUguK/kXBWjdD5qBxG4gItOyZkt5Q2s3LZFm0Vfp7j8Crfn/
0X1+LJkJukJObLxyKDwHlyKaT2U4x1pu6uOL+ER7QA93eR+sb0tTKc2HiWm4i170
OMC8A0KPwln/NwDdCVmACxPBw5yMJN1Ye+YPQ+WIVPPu3XopJ2ETFlpdED9hLW6Y
rMRAw+E/I+QGxuRHzYNcQjw1RGtBC0+OdRVSm1EOoSmzpFE6l3acyAtAA4ibU8qX
TsUZljuKE7KePXRt4skCQ3f9H9X8t7jt3aZN8wIDAQABAoIBAQCbLOM2WZy04Cnf
8hTgmg5mlXYyhn/7OV/M47zH/r+2PsxuF3ZTyJZKps3qAf8QzQOaFwbfI9YOih0q
lvgxp2L5iZbfEhAPWOxCyRZ9i7YMZZxgovcQkzYvyMkLdWMGdXnsnV8lcd6qQqiF
6NkJcOpAfNhyK5f+19suGNqHGca3MAIFZVKaO469pFn3Ko8arN2gHnCvsMqMstWx
UWf+mhycNrF8giBQPQHhbuM3XpyPMx4ogm0KGpB0lGrPhr3mWC769Q9SqPEsFaOX
JYmWseL0TCIbaI//XvoHibsvrfRm9CnnvYIU8O9WqE2x4B9LuH1Cdwx3qLxCzUAP
sFJUDPhJAoGBAPAQfW7SVtOi07pOcKvPLiRzKNaL7wIJGeDvgH78FsRKKsITYLEU
GwNkkyQ+3KebJFQ01IHDmESqT4QeOHM1XHiE1/7PcrbdPmKV+pZfyT0pEht4KTpt
RtH7N3QL9nSeppzUpNYe4j5OEnRSqjS5ws3sNNPpN0QQX4iEteDqeb9vAoGBAMgd
Rq2DLmWNoWBO4O4jnZEYZe8XtmJjNjOs3JTLocI0QbodaUB30UZ8WpoGEHq6A5v0
Xw2IDk/DcJTW5/lCMC48yeh94dYq3d5kqSsZiXecpGDr0LagHkZ5KyGavVT3p7PQ
HMjtZdVpe/kL2tq+++cjRRDz4F20BA/Gawb+/xe9AoGBAJDxU9picvwK1o0pKQwC
5hgp06FeEauH9TEDSfDhoZlYMmLFMl8YBYtR4SlELxOfLxsMXrYOc1QaBDJ/0JN2
x2MYyhTWCavw/ualKQw7vxQHRSCCt1nIerbzV7LgOiiFVZFhLjj9opn9p1TGSG/X
LJAKKoc7yxt0S0nw3qNyzplPAoGAHFuBs37eaUersaEb4MMtRe1mLH54uvKCMMpz
K6a7knCjS8N1McyI3Gssz5zCYxHPBx+XBKzeElGIt57ImprVhwfYONj10sqaTDSJ
gHEK+Oc6p/1iwy5ne+cZxJZP9CVwH1wDaDmz740WI1GZvtvHiJaH6AIXLlAfLzkX
25LxBSUCgYACRCF/dEVmxs6MMGuWQ9vyK23FbDBRdsaXRKthEU33SvwBYoLdrpLm
gfk/GvogYknNClENS3XnuV7imEB4duQMS4JcesQn3plxZLASWLNO3PhZ10h4O2Vw
3RN2GVXnliUZsb3wePwUL9nKxddtVgTkLLeQvlimZV/A8kJSoR6ZMg==
-----END RSA PRIVATE KEY-----`;


function App() {

  const [JTtoken, setJTtoken] = useState("")
  const generateJwtToken = () => {
    const currentTime = Math.floor(Date.now() / 1000);
    const expirationTime = currentTime + 900; // 15 minutes

    const header = { alg: 'RS512', typ: 'JWT' };
    const payload = {
      iat: currentTime,
      exp: expirationTime,
      iss: 'Arinder',
    };

    const privateKeyObj = KEYUTIL.getKey(privateKey);
    const token = KJUR.jws.JWS.sign(
      'RS512',
      JSON.stringify(header),
      JSON.stringify(payload),
      privateKeyObj
    );

    localStorage.setItem("JWT_TOKEN", token);
    setJTtoken(token)
  };

  const refreshToken = () => {
    generateJwtToken();
    console.log("Token refreshed:", new Date());
  };
  setInterval(refreshToken, 840000);
  useEffect(() => {
    generateJwtToken()

  }, [])


  const client = new ApolloClient({
    uri: configJson.CHR_API_ENDPOINT, // Replace with your GraphQL endpoint
    cache: new InMemoryCache(),
    headers: {
      Authorization: 'Bearer ' + JTtoken
    }
  });
  // const [isLogin, setIsLogin] = useState(true);




  return (
    <ThemeProvider theme={GlobalThemeColor.media}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<ApolloProvider client={client}><AllImportPages.SINGUP_PAGE_ONE ></AllImportPages.SINGUP_PAGE_ONE></ApolloProvider>} />
          {/* <Route path='/signupTest' element={<ApolloProvider client={client}><AllImportPages.SINGUP_PAGE_ONE_TEST ></AllImportPages.SINGUP_PAGE_ONE_TEST></ApolloProvider>} /> */}
          <Route path='*' exact={true} element={<Page404 />}></Route>
          


          <Route element={<PrivateRoute />} exact>
            <Route path='/graphql' element={<ApolloProvider client={client}><AllImportPages.Graph></AllImportPages.Graph></ApolloProvider>} />
            <Route path='/weightloss-qnaire' element={<ApolloProvider client={client}><AllImportPages.QUESTIONS_PAGE></AllImportPages.QUESTIONS_PAGE></ApolloProvider>} />
            <Route path='/successPayment' element={<AllImportPages.SuccessPayment></AllImportPages.SuccessPayment>} />
            <Route path='/dashboard' element={<ApolloProvider client={client}><AllImportPages.Dashboard></AllImportPages.Dashboard></ApolloProvider>} />
            <Route path='/dashboard2' element={<ApolloProvider client={client}><AllImportPages.Dashboard2></AllImportPages.Dashboard2></ApolloProvider>} />
            <Route path='/account' element={<ApolloProvider client={client}><AllImportPages.Account></AllImportPages.Account></ApolloProvider>} />
            {/* <Route path='/privacy-policy' element={<ApolloProvider client={client}><AllImportPages.PRIVACYPOLICY></AllImportPages.PRIVACYPOLICY></ApolloProvider>} />
          <Route path='/terms-of-use' element={<ApolloProvider client={client}><AllImportPages.TERMSOFUSE></AllImportPages.TERMSOFUSE></ApolloProvider>} /> */}
            <Route path='/eligible' element={<ApolloProvider client={client}><AllImportPages.ELIGIBLESCREEN></AllImportPages.ELIGIBLESCREEN></ApolloProvider>} />
            <Route path='/non-eligible' element={<ApolloProvider client={client}><AllImportPages.NONELIGIBLESCREEN></AllImportPages.NONELIGIBLESCREEN></ApolloProvider>} />
            <Route path='/privacy-policy' element={<ApolloProvider client={client}><AllImportPages.PRIVACYPOLICYDash></AllImportPages.PRIVACYPOLICYDash></ApolloProvider>} />
            <Route path='/terms-of-use' element={<ApolloProvider client={client}><AllImportPages.TERMSOFUSEDASH></AllImportPages.TERMSOFUSEDASH></ApolloProvider>} />

          </Route>




        </Routes>
        {/* <Footer /> */}

      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
