
import { stringValue } from '../theme/string';
import '../utils/page-css/pagegupOne.css';
import { AppImage } from '../theme/app.images';
import { useState } from 'react';


const PrivacyPolicy = () => {
    const [isPolicyHideShow, setIsPolicyHideShow] = useState(false);

    const func = () => {
        console.log("terms")

        setIsPolicyHideShow(true);
        console.log(!isPolicyHideShow);
    }
    return (
        <>
            {
                isPolicyHideShow === false ? (
                    <div className='terms-zindex-container'>
                        <div className='terms-content-container'>
                            <div className='privacy-content'>
                                <div style={{
                                    flexDirection: 'row', display: 'flex',
                                    justifyContent: 'space-between', alignItems: 'center',
                                    width: '53vw', backgroundColor: '#8DB9DE'
                                }}>
                                    <a></a>
                                    <h1 className='privacy-h1-text-style'>Privacy Policy for Pepstep</h1>
                                    <img
                                        onClick={func}
                                        src={AppImage.closeWhiteImg} className='close-icon-img' />

                                </div>
                                <div className='terms-divider-line'></div>

                                <p className='privacy-small-text-style'>Last updated: June 24, 2023</p>
                                <p className='privacy-mid-text-style'>{stringValue.thisPrivacyPolicy}
                                    <a href='https://pepstep.ca/'>https://pepstep.ca/</a>
                                    {stringValue.andAnyproduct}
                                </p>
                                <p className='privacy-mid-text-style'>{stringValue.byAccessing}</p>
                                <h2 className='privacy-h2-text-style'>What Information We Collect</h2>
                                <p className='privacy-mid-text-style'>{stringValue.weCollect}<b>{stringValue.personalInformation}
                                    ”).
                                </b>

                                </p>
                                <p className='privacy-mid-text-style'>{stringValue.weCollectNonPersonal}</p>
                                <h2 className='privacy-h2-text-style'>How We Use Your Information</h2>
                                <p className='privacy-mid-text-style'>{stringValue.weCollectAndUse}</p>

                                <ul className='privacy-list'>
                                    <li className='privacy-list-marker'>To provide you with our Services and fulfill your requests</li>
                                    <li className='privacy-list-marker'>To communicate with you about your appointments, treatments, prescriptions, referrals, test results, or other health-related matters</li>
                                    <li className='privacy-list-marker'>To send you newsletters, promotions, surveys, or other information that may be of interest to you</li>

                                    <li className='privacy-list-marker'>To bill you for our Products, Services and process your payments</li>
                                    <li className='privacy-list-marker'>To comply with our legal obligations and professional standards</li>
                                    <li className='privacy-list-marker'>To protect our rights and interests and those of our Users and third parties</li>

                                    <li className='privacy-list-marker'>To prevent, detect, and investigate fraud, abuse, misuse, or unauthorized access to our Services</li>
                                    <li className='privacy-list-marker'>To improve our Services and develop new features or functionalities</li>
                                    <li className='privacy-list-marker'>To perform statistical analysis and research</li>

                                </ul>
                                <p className='privacy-mid-text-style'>{stringValue.weUseyourNonPersonal}</p>

                                <ul className='privacy-list'>
                                    <li className='privacy-list-marker'>To improve our Services and customize your user experience</li>
                                    <li className='privacy-list-marker'>To measure the effectiveness of our marketing campaigns and promotions</li>
                                    <li className='privacy-list-marker'>To monitor and analyze the usage and performance of our Services</li>

                                    <li className='privacy-list-marker'>To enhance the security and reliability of our Services</li>
                                    <li className='privacy-list-marker'>To troubleshoot and resolve technical issues</li>


                                </ul>

                                <h2 className='privacy-h2-text-style'>How We Share Your Information</h2>
                                <p className='privacy-mid-text-style'>{stringValue.weDontSell}</p>
                                <p className='privacy-mid-text-style'>We may share your personal information with the following parties:</p>

                                <ul className='privacy-list'>
                                    <li className='privacy-list-marker'>Our health care providers and staff who are involved in your care or treatment</li>
                                    <li className='privacy-list-marker'>Our service providers who help us operate our Services or perform functions on our behalf, such as hosting, data processing, payment processing, email delivery, or analytics</li>
                                    <li className='privacy-list-marker'>Our affiliates and partners who offer products or services that may be of interest to you or that complement our Services</li>

                                    <li className='privacy-list-marker'>Our professional advisors, such as lawyers, accountants, or auditors, who need access to your information to provide us with legal, financial, or other advice</li>
                                    <li className='privacy-list-marker'>Other health care providers or organizations that you have authorized us to share your information with, such as specialists, pharmacies, laboratories, or hospitals</li>
                                    <li className='privacy-list-marker'>Government authorities, regulators, courts, or law enforcement agencies, when we are required or permitted by law to do so or when we need to protect our rights and interests or those of our Users and third parties</li>


                                </ul>
                                <p className='privacy-mid-text-style'>We may share your non-personal information with the following parties:</p>

                                <ul className='privacy-list'>
                                    <li className='privacy-list-marker'>Our service providers who help us operate our Services or perform functions on our behalf, such as hosting, data processing, payment processing, email delivery, or analytics. We will not store or collect your payment card details and this information will be stored directly with our third-party payment providers.</li>
                                    <li className='privacy-list-marker'>Our affiliates and partners who offer products or services that may be of interest to you or that complement our Services</li>
                                    <li className='privacy-list-marker'>Other third parties for marketing, advertising, research, or statistical purposes We will anonymize and aggregate your personal information and share it with third parties for any lawful purpose.</li>
                                </ul>
                                <h2 className='privacy-h2-text-style'>How We Protect Your Information</h2>
                                <p className='privacy-mid-text-style'>We take reasonable measures to protect your personal information from unauthorized access, use, copying, disclosure, alteration, or destruction. These measures include:</p>
                                <ul className='privacy-list'>
                                    <li className='privacy-list-marker'>Using encryption and other security technologies to safeguard your information during transmission and storage</li>
                                    <li className='privacy-list-marker'>Implementing access controls and policies to limit who can access your information and for what purposes</li>
                                    <li className='privacy-list-marker'>Providing regular training and guidance to our staff on how to handle your information securely and confidentially</li>
                                    <li className='privacy-list-marker'>Reviewing and updating our security practices and procedures on a regular basis to meet industry standards</li>

                                </ul>
                                <p className='privacy-mid-text-style'>Access to information is granted to individuals who have a legitimate business need or whose responsibilities necessitate access to such information. Your electronic information is securely stored with restricted access and housed in facilities using physical security measures.</p>
                                <p className='privacy-mid-text-style'>{stringValue.however}<a href='compliance@pepstep.ca'>compliance@pepstep.ca</a></p>

                                <h2 className='privacy-h2-text-style'>When and how do we obtain consent?</h2>
                                <p className='privacy-mid-text-style'>{stringValue.consentForCollect}</p>
                                <h2 className='privacy-h2-text-style'>How Long We Keep Your Information</h2>
                                <p className='privacy-mid-text-style'>{stringValue.weRetainYour}</p>
                                <p className='privacy-mid-text-style'>{stringValue.whenWeNoLonger}</p>
                                <h2 className='privacy-h2-text-style'>How You Can Access and Control Your Information</h2>
                                <p className='privacy-mid-text-style'>You have the following rights and choices regarding your personal information:</p>

                                <ul className='privacy-list'>
                                    <li className='privacy-list-marker'>You can access and update your personal information by logging into your account on our website or contacting us</li>
                                    <li className='privacy-list-marker'>You can request a copy of your personal information that we hold by contacting us</li>
                                    <li className='privacy-list-marker'>You can request that we correct any inaccurate or incomplete personal information that we hold about you by contacting us</li>
                                    <li className='privacy-list-marker'>You can request that we delete your personal information that we no longer need or have a legal basis to keep by contacting us</li>


                                    <li className='privacy-list-marker'>You can withdraw your consent to our use of your personal information at any time by contacting us</li>
                                    <li className='privacy-list-marker'>You can opt out of receiving marketing communications from us by following the unsubscribe link in the email or contacting us</li>
                                    <li className='privacy-list-marker'>You can manage your cookie preferences by adjusting your browser settings</li>
                                    <li className='privacy-list-marker'>Requests to access your information can be made by contacting us by email at <a href='compliance@pepstep.ca'>compliance@pepstep.ca</a></li>

                                </ul>

                                <p className='privacy-mid-text-style'>{stringValue.pleaseNoteThat}</p>

                                <h2 className='privacy-h2-text-style'>How We Use Cookies and Other Tracking Technologies</h2>
                                <p className='privacy-mid-text-style'>{stringValue.weUseCookies}</p>

                                <ul className='privacy-list'>
                                    <li className='privacy-list-marker'>You can access and update your personal information by logging into your account on our website or contacting us</li>
                                    <li className='privacy-list-marker'>You can request a copy of your personal information that we hold by contacting us</li>
                                    <li className='privacy-list-marker'>You can request that we correct any inaccurate or incomplete personal information that we hold about you by contacting us</li>
                                    <li className='privacy-list-marker'>You can request that we delete your personal information that we no longer need or have a legal basis to keep by contacting us</li>


                                    <li className='privacy-list-marker'>Remember your login details and preferences</li>
                                    <li className='privacy-list-marker'>Enable certain features and functionalities of our Services</li>
                                    <li className='privacy-list-marker'>Measure the effectiveness of our marketing campaigns and promotions</li>
                                    <li className='privacy-list-marker'>Monitor and analyze the usage and performance of our Services</li>
                                    <li className='privacy-list-marker'>Enhance the security and reliability of our Services</li>

                                </ul>

                                <p className='privacy-mid-text-style'>{stringValue.youCanChhoseAccept}</p>
                                <p className='privacy-mid-text-style'>{stringValue.weMayAlsoUse}</p>
                                <p className='privacy-mid-text-style'>{stringValue.thisWebsiteUsing}<a href='https://www.tidio.com/privacy-policy/'>https://www.tidio.com/privacy-policy/</a>.</p>
                                <p className='privacy-mid-text-style'>{stringValue.youCanOptOut}</p>

                                <h2 className='privacy-h2-text-style'>How We Link to Other Websites</h2>
                                <p className='privacy-mid-text-style'>{stringValue.ourWebsiteMayContain}</p>
                                <h2 className='privacy-h2-text-style'>How We Update This Policy</h2>
                                <p className='privacy-mid-text-style'>{stringValue.weMayUpdateThispolicy}</p>
                                <h2 className='privacy-h2-text-style'>How You Can Contact Us</h2>
                                <p className='privacy-mid-text-style'>If you have any questions, comments, or complaints about this policy or our privacy practices, please contact us at:</p>
                                <p className='privacy-mid-text-style'>Email: <a href="compliance@pepstep.com"><u>compliance@pepstep.com</u></a></p>
                                <p className='privacy-mid-text-style'>Pepstep Unit #130 200 Rivercrest Dr SE, Calgary, AB T2C 2X5, Canada</p>
                                <p className='privacy-mid-text-style'>{stringValue.weWillAttempt}</p>

                                <div style={{ height: '2.0rem' }}></div>

                            </div>

                        </div>




                    </div>
                ) : (
                    <div></div>
                )
            }




        </>
    )
}

export default PrivacyPolicy;