import '../../utils/page-css/pagegupOne.css';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink } from 'react-router-dom';
import { AppImage } from '../../theme/app.images';
import DashboardNavPage from '../../components/DashboardNavPages';

export default function DashboardNavBar() {
    
    return (
        <header className='dash-header-container' >
            <NavLink to={'https://pepstep.ca/'} style={{ width: "22.0rem", }}>
                <img src={AppImage.headerLogoImage} alt='logo' className='header-img-size' />
            </NavLink>
            <DashboardNavPage/>

            
  
        </header>
    )
}