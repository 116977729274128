
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import { AppImage } from "../theme/app.images";
import React, { useState, useEffect ,useMemo} from "react";
import FlatList from 'flatlist-react';
import IconButton from "@mui/material/IconButton";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import '../utils/page-css/pagegupOne.css';
import dayjs from "dayjs";
import { format } from 'date-fns';
import cn from "../components/cn";
import configJson from "../auth_config.json";
import { generateDate, months, weekDays } from "../components/Calendar";
import ProviderDropdown from '../pages/ProviderDropdown'
import { ApolloClient, useQuery, InMemoryCache, gql, ApolloProvider, useMutation } from '@apollo/client'
import swal from 'sweetalert';






const UpdateDialogBox = (props) => {
    const currentDate = dayjs();
    const [today, setToday] = useState(currentDate);
    const [selectDate, setSelectDate] = useState(currentDate);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(true);
    const [availabilityData, setAvailabilityData] = useState([]);
    const [clickTimeValue, setClickTimeValue] = useState();
    const [FinalValue, setFinalValue] = useState([]);
    const [ setSelectDoctor] = useState('');
    const [providerInfo, setProviderInfo] = useState([]);

    const locationId = props.providerID.locationId



    

    const handleSelectProvider = (selectedProvider) => {

        // Handle the selected provider here
        localStorage.setItem('Selected Provider', selectedProvider.id);

        setSelectDoctor(selectedProvider.id);

        // Show the availability for the selected provider


        // You can set the selected provider to state or perform any other logic as needed.
        // For example, you could call a function to fetch availabilities based on the selected provider.
    };

    //   if (!localStorage.getItem("user_metadata")) {

    //   }
    const appointmentId = JSON.parse(localStorage.getItem("user_metadata"))
    


    const handleReSchedule = async () => {

        let myDates = [];
        let myAppointments = [];
        let i = 0
        props.providerID.rows.map((item) => {
            if (item.node.date != props.providerID.myDate) {
                myDates[i] = item.node.date
            }

            if (props.providerID.appointmentID != item.node.id) {
                myAppointments[i] = item.node.id
            }



            i++;

        })

        
        let check = FinalValue[0].split("T")
        

        if (!myDates.includes(check[0])) {
            // if (!myDates.includes(FinalValue[0]) && !myAppointments.includes(props.providerID.appointmentID)) {

 
            // Prepare the input data for the mutation
            const input = {
                id: props.providerID.appointmentID,
                note: "", // Replace with the desired note value
                status: "RESCHEDULED", // Replace with the desired status value
                startAt: new Date(FinalValue[0]).toISOString().replace('.000Z', "+0000"),
                untilAt: new Date(FinalValue[1]).toISOString().replace('.000Z', "+0000"),
                serviceId: props.providerID.serviceId,
                locationId:locationId

            };
            
            try {
                // Execute the mutation
                const response = await fetch(configJson.CHR_API_Next + 'api/updateAppointment', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ input: input }),
                });
            
                const data = await response.json();
            
                // Check if there is an error property in the response
                if (data.error) {
                    // Use SweetAlert for error message
                    swal("Error", data.error);
                    console.error('Error updating appointment:', data.error);
                } else if (data.updateAppointment && data.updateAppointment.appointment) {
                    // Use SweetAlert for success message
                    swal("Appointment Updated", "Your appointment has been successfully rescheduled.", "success").then(() => {
                        // Perform any necessary actions after successful rescheduling
                        window.location.reload()
                    });
                } else {
                    // Handle unexpected response format
                    swal("Error", "Unexpected response format.");
                    console.error('Unexpected response format:', data);
                }
            } catch (error) {
                // Use SweetAlert for error message
                swal("Error", error.message);
                console.error('Error updating appointment:', error.message);
            }
        } else {
            swal("Error", "Appointment Already Booked on Selected Date, Please change Date and try again.", "error");
        }
    }
            

    const [TimeDataModel, setTimeDataModel] = useState([
        {
            key: 1,
            time: 'Select Date to choose slot'
        }
    ])

    const selectDoctor = props.providerID.providerID;
    
    useEffect(() => {
        
        const userGroupId="5237"
        const fetchData = async (from, to) => {
    try {
      const requestBody = {
        id: userGroupId,
        from: from,
        to: to,
        serviceId:"36579",
        locations:locationId
      };
      console.log('Request Body:', requestBody);
      const response = await fetch(configJson.CHR_API_Next + 'api/getAvailability', {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      console.log("AvailabilityData",data)
      let Avail = [];

      if (data && data.availabilities) {
        data.availabilities.forEach((availability) => {
            let providerName = availability.provider.fullName
            let providerID = availability.provider.id
            let prevProviderName = ""
            let prevProviderID = ""
            if (prevProviderName == "") {
                prevProviderName = providerName
                prevProviderID = providerID
            }
            if (providerName == prevProviderName) {
                Avail.push({
                    providerName: providerName,
                    providerID: providerID,
                    start: availability.startAt,
                    until: availability.untilAt,
                  });
                
            }
            prevProviderName = providerName
            prevProviderID = providerID
          
        });
      }
      return Avail
    //   return Avail;
    } catch (error) {
      console.error('Error fetching availability data:', error);
      return [];
    }
          };
      
          // Function to fetch data for multiple date ranges
          const fetchAllData = async () => {
            let allAvailData = [];
            for (let i = 0; i < numberOfWeeks; i++) {
              const from = dateRanges[i].from;
              const to = dateRanges[i].to;
              const availData = await fetchData(from, to);
              allAvailData = allAvailData.concat(availData);
              console.log("ttttttttttttttttttttttt",allAvailData)
            }
            return allAvailData;

          };
          
        
    
          fetchAllData().then((allAvailData) => {
            const providers = allAvailData.map(({ providerName, providerID }) => ({
                providerName,
                providerID,
              }));
            const uniqueProviders = [...new Set(providers.map(JSON.stringify))].map(JSON.parse);
            setProviderInfo(uniqueProviders);
            
            const combinedData = [...allAvailData];
            console.log("akhil",combinedData)
            
            const filteredData = combinedData.filter(
                (item) => item.providerID === selectDoctor 
              );
          
              // Update state variables
              setAvailabilityData(filteredData);
          
              // Store in the state array
              
          
              // If selectedProvider is truthy, pass the filtered data to availDateGet
            
                availDateGet(filteredData);
              
          
              console.log("Filtered Data:", filteredData);
              console.log("Unique Providers:", uniqueProviders);
            });
        
        
      }, [selectDoctor]);



    // Use the selected provider's ID in the query
    // const { loading, error, data } = useQuery(MY_QUERY, {
    //     variables: {
    //         id: selectedProviderId,
    //         from: "2023-09-04T00:00:00+0000",
    //         to: "2023-09-09T23:59:00+0000"
    //     },
    // });
    const generateDateRanges = (startDate, numberOfWeeks) => {
        // console.log("Gaurav",startDate)
        const dateRanges = [];
        const startSunday = new Date(startDate);
      
        if (startSunday.getDay() !== 0) {
          // If startDate is not a Sunday, find the nearest Sunday
          startSunday.setDate(startSunday.getDate() - startSunday.getDay());
        }
    
    
        const isWinterPeriod = (date) => {
            const currentDate = new Date(date);
            const currentMonth = currentDate.getMonth() +1 ; // Month is zero-indexed
            const currentDay = currentDate.getDate();
            console.log("currentMonth+currentday",currentMonth,currentDay)
            
            // Check if the date is within the winter period (from November 5th to March 9th)
            if (
              (currentMonth >= 11 || currentMonth <= 3) ||
              (currentMonth === 11 && currentDay >= 5) ||
              (currentMonth === 3 && currentDay <= 9)
            ) {
              return true;
            }
            
            return false;
          };
          
          
      
        for (let i = 0; i < numberOfWeeks; i++) {
          const from = new Date(startSunday);
          from.setDate(from.getDate() + i * 7); // Start of each week
          const to = new Date(from);
          
          to.setDate(to.getDate() + 6); // End of each week
          const isWinterTimezone = isWinterPeriod(from) ? '-07:00' : '-06:00';
          dateRanges.push({
            from: from.toLocaleDateString('en-GB', {year:'numeric', month: '2-digit', day:'2-digit' }).split('/').reverse().join('-') + `T00:00:00${isWinterTimezone}`,
            to: to.toLocaleDateString('en-GB', {year:'numeric', month: '2-digit', day:'2-digit' }).split('/').reverse().join('-') + `T23:59:00${isWinterTimezone}`,
          });
        }
        return dateRanges;
      };
      
       
    
      const startDate = useMemo(() => {
        const currentDate = new Date();
      
        // Check if the current day is Sunday (day 0), if so, return the current date.
        if (currentDate.getDay() === 0) {
          return currentDate.toISOString();
        }
      
        // Calculate the number of days until the previous Sunday.
        const daysUntilPreviousSunday = currentDate.getDay();
        currentDate.setDate(currentDate.getDate() - daysUntilPreviousSunday);
      
        // Check if the calculated date is Saturday.
        if (currentDate.getDay() === 6) {
          // If it's Saturday, subtract 1 day to avoid crossing Saturday.
          currentDate.setDate(currentDate.getDate() - 1);
        }
      
        return currentDate.toISOString();
      }, []);
      
      
    
      const numberOfWeeks = 3;
    
      // Generate dateRanges dynamically
      const dateRanges = generateDateRanges(startDate, numberOfWeeks);
    const [dataString,setdataString]  = useState([])
     const [getAvailibility, setgetAvailibility] = useState([])
        
   


    const [myAvaildates, setmyAvaildates] = useState([])
    const [myAvaildatesWithTime, setmyAvaildatesWithTime] = useState([])
    function availDateGet(Avail) {
        let abc = []
        let abc2 = []
        Avail.map((item) => {
            
          
            const originalDate = new Date(item.start).toLocaleDateString('en-GB', {year:'numeric', month: '2-digit', day:'2-digit' }).split('/').reverse().join('-');

            if (!abc2.includes(originalDate)) {
                abc2.push(originalDate)
            }
            


            const options = {
                year:'numeric',
                month: '2-digit', 
                day:'2-digit',
                timeZone:"America/Sao_Paulo",
                hour12 : false,
                hour:  "2-digit",
                minute: "2-digit",
                second: "2-digit"
             };
             const originalDate2 = new Date(item.start).toLocaleTimeString('en-GB', options);
            
             if (!abc.includes(originalDate2)) {

                
                
                abc.push(originalDate2)    
                
             }
            
            // setmyAvaildates((prevData) => [...prevData, originalDate])
            
        })
        setmyAvaildates(abc2)
        setmyAvaildatesWithTime(abc)
        

        

    }



    
    

    function selectedDates(date) {
        setClickTimeValue()
        
        let key = 0
        let val = []

        availabilityData.map((item) => {
            
            let splitData = (item.start).split("T")
            // let splitData2 = (item.until).split("T")
            
            const modifiedDate = new Date(item.start).toLocaleDateString('en-GB', {year:'numeric', month: '2-digit', day:'2-digit' }).split('/').reverse().join('-');
            if (modifiedDate === date) {

                
                val[key] = {
                    key: key,
                    time: format(new Date(item.start), 'p') + " to " + format(new Date(item.until), 'p'),
                    startAt: item.start,
                    untilAt: item.until
                }
                key++;
                // setTimeDataModel({
                //     key: key++,
                //     time: JSON.stringify(item.start)
                // })

            }


        })
        setTimeDataModel(val)

    }

    function SetClassAvailDates(dates) {
        if (myAvaildates.includes(dates)) {
            return true
        }else{
            return false
        }
        // let ArrayData = []
        // availabilityData.map((item) => {

        //     let splitData2 = (item.start).split("T")
        //     const modifiedDate = new Date(item.start).toLocaleDateString('en-GB', {year:'numeric', month: '2-digit', day:'2-digit' }).split('/').reverse().join('-');
        //     if (modifiedDate == dates) {
        //         ArrayData = [dates]
        //         // return true
        //     }


        // })
        // if (ArrayData.includes(dates)) {
        //     return ArrayData.includes(dates)
        // }

    }

    const renderItem = (item) => {


        return (
            <a className='time-bg-container'
                style={
                    {
                        backgroundColor: item.time === clickTimeValue ? '#486cee' : '',
                        color: item.time === clickTimeValue ? 'white' : '',

                    }
                }
                onClick={
                    () => {
                        handleTimeClick(item)
                    }
                }>
                {
                    item.time
                }</a>

        );
    };

    const handleTimeClick = (e) => {
        setClickTimeValue(e.time);
        setFinalValue([e.startAt, e.untilAt]);

        

    }
    const [windowWidth, setwindowWidth] = useState(window.innerWidth)

    window.addEventListener('windowWidth', (event) => {
        setwindowWidth(event);
    });



    return (

        <div>
            <Dialog
                open={openUpdateDialog}
                maxWidth='xl'
                PaperProps={{ sx: { width: "79.7rem", height: '70.6rem', borderRadius: '3.2rem', overflowY: windowWidth > 800 ? 'scroll' : 'scroll', overflowX: 'hidden' } }}
            >
                <div style={{

                    flexDirection: 'column', display: 'flex',
                    marginTop: '3.7rem',
                }}>
                    <a className='update-appointment-text-with-font-24'>Update Appointment!</a>
                    <Box position="absolute" top={25} right={0}>
                        <IconButton>
                            <img
                                className='close-icon-img' style={{ marginRight: '1.5rem' }}
                                src={AppImage.closeIcon}
                                onClick={() => { setOpenUpdateDialog(!openUpdateDialog) }}
                            />
                        </IconButton>
                    </Box>

                    <div>
                        {/* Existing code... */}
                        {/* <ProviderDropdown onSelectProvider={handleSelectProvider} /> */}
                        {/* Existing code... */}
                    </div>

                    <div className='appointmentFieldRow'>
                        <div className='appointmentLeftContainer'>
                            <div className='initial-date-row'>

                                <a className='initial-date-text'>{today.date()}/{today.month() + 1}/{today.year()}</a>
                                <CalendarMonth style={{ width: '20px', height: '20px' }} />

                            </div>
                            <hr
                                style={{
                                    background: "#e4e7e9",
                                    height: "1.5px",
                                    border: "none",
                                    width: '100%',
                                    marginTop: '5px'
                                }}
                            />
                            <div className='month-and-year-row'>
                                <div className='year-row'>
                                    <a className='initial-date-text'>{months[today.month()]}</a>
                                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                                        <KeyboardArrowUpIcon
                                            onClick={() => {
                                                setToday(today.month(today.month() - 1));
                                            }}
                                            style={{ width: '1.5rem', height: '1.5rem', marginTop: '0.4rem' }} />
                                        <KeyboardArrowDownIcon
                                            onClick={() => {
                                                setToday(today.month(today.month() + 1));
                                            }}
                                            style={{ width: '1.5rem', height: '1.5rem' }} />
                                    </div>

                                </div>
                                <div style={{ height: '3.0rem', width: '1px', backgroundColor: '#E4E7E9' }}></div>
                                <div className='year-row'>
                                    <a className='initial-date-text' style={{ paddingLeft: '1.2rem' }}>{today.year()}</a>
                                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                                        <KeyboardArrowUpIcon
                                            onClick={() => {
                                                setToday(today.year(today.year() - 1));
                                            }}
                                            style={{ width: '1.5rem', height: '1.5rem', marginTop: '0.4rem' }} />
                                        <KeyboardArrowDownIcon
                                            onClick={() => {
                                                setToday(today.year(today.year() + 1));
                                            }}
                                            style={{ width: '1.5rem', height: '1.5rem' }} />
                                    </div>

                                </div>
                            </div>

                            <hr
                                style={{
                                    background: "#e4e7e9",
                                    height: "1.5px",
                                    border: "none",
                                    width: '100%',
                                    marginTop: '5px'
                                }}
                            />
                            <div className='sun-mon-days-row'>
                                <a className='days-text-style '>SUN</a>
                                <a className='days-text-style '>MON</a>
                                <a className='days-text-style '>TUE</a>
                                <a className='days-text-style '>WED</a>
                                <a className='days-text-style '>THU</a>
                                <a className='days-text-style '>FRI</a>
                                <a className='days-text-style '>SAT</a>
                            </div>
                            <div style={{ flexDirection: 'row', flexWrap: 'wrap', display: 'flex', justifyContent: "space-between" }} id="calendarDates">
                                {generateDate(today.month(), today.year()).map(
                                    ({ date, currentMonth, today }, index) => {
                                        let dayWith0 = date.date() < 10 ? '0' + date.date() : date.date()
                                        let monthWith0 = (date.month() + 1) < 10 ? '0' + (date.month() + 1) : (date.month() + 1)
                                        let SetDate = date.year() + '-' + monthWith0 + '-' + dayWith0

                                        return (
                                            <div
                                                key={index} className='fiients'


                                            >
                                                
                                                <p
                                                    className={

                                                        cn(
                                                            selectDate
                                                                .toDate()
                                                                .toDateString() ===
                                                                date.toDate().toDateString()
                                                                ? "visited"
                                                                : today
                                                                    ? "visited-one"
                                                                    : "",
                                                            "",

                                                            SetClassAvailDates(SetDate) ? "" : "unselected-date-text"

                                                        )



                                                    }

                                                    onClick={
                                                        SetClassAvailDates(SetDate) ?
                                                            (e) => {
                                                                // let monthWith0 = (date.month() + 1) < 10 ? '0'+(date.month() + 1) : (date.month() + 1)
                                                                //let SetDate = date.year()+'-'+ monthWith0 + '-' + date.date() 

                                                                
                                                                // this.classList.remove("visited");
                                                                setSelectDate(date)
                                                                selectedDates(SetDate)
                                                                setToday(date)

                                                            } : (e) => { e.preventDefault() }

                                                    }
                                                >
                                                    {date.date()}
                                                </p>
                                            </div>
                                        );
                                    }
                                )}
                            </div>

                        </div>


                        <div className='appointmentRightContainer'>
                            <div className='initial-date-row'>
                                <a className='initial-date-text'>{weekDays[today.day()] + ", " + months[today.month()] + " " + today.year()}</a>
                                <div style={{ flexDirection: 'row', display: 'flex', marginRight: '1.5rem' }}>
                                    <KeyboardArrowLeftIcon
                                        onClick={() => {
                                            setToday(today.year(today.year() - 1));

                                        }}
                                        style={{
                                            backgroundColor: "#DBDEE9", color: '#8794AC',
                                            borderRadius: '50%', width: '2.5rem', height: '2.5rem'
                                        }} />

                                    <KeyboardArrowRightIcon
                                        onClick={() => {
                                            setToday(today.year(today.year() + 1));

                                        }}
                                        style={{
                                            backgroundColor: "#DBDEE9", marginLeft: '1.0rem',
                                            color: '#8794AC',
                                            borderRadius: '50%', width: '2.5rem', height: '2.5rem'
                                        }} />


                                </div>

                            </div>
                            <div
                                className='mydivConatiner'
                                style={{
                                    marginTop: '2.0rem',
                                    marginLeft: '1.2rem',


                                }}>
                                <FlatList
                                    list={TimeDataModel}
                                    renderItem={renderItem}
                                    values="lll"
                                />


                            </div>

                        </div>

                    </div>
                    {
                        clickTimeValue ? (<div className="mt-3" style={{ alignSelf: 'center', justifyContent: 'center', marginBottom: '2.0rem' }}>
                        <button className="btn btn-lg btn-block btn-success p-4 fs-32" style={{ width: '250px' }}
                            onClick={handleReSchedule}
                        >
                            Re-schedule
                        </button>
                    </div>) : (<></>)
                    }
                    

                </div>



            </Dialog>


        </div>

    )
}
export default UpdateDialogBox;
