import '../../src/utils/page-css/pagegupOne.css';
import PublicNavHeader from '../utils/public-header/PublicNavHeader';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import React, { useState,useEffect } from 'react';
import configJson from "../auth_config.json";
import axios from 'axios';



const NonEligibleScreen = () => {


    

    const {
        user,
        isAuthenticated,
        loginWithRedirect,
        getIdTokenClaims,
        logout
      } = useAuth0();



    
    

    const navigate = useNavigate();

    const logoutWithRedirect = () => {
        localStorage.clear();         
        window.location.href = "https://pepstep.ca/"
        // logout()
       
       
    }

    async function questioncompletetrue() {
        const weightData = {
          date: new Date(),
          weight: localStorage.getItem("weight")
        }
        localStorage.setItem("weightData","[" + JSON.stringify(weightData) + "]")
        try {
            // Replace 'YOUR_STRIPE_SECRET_KEY' with your actual Stripe Secret Key
            const token = configJson.AUTH_MANAGEMENT_TOKEN
            axios.patch(configJson.AUTH_USER_DETAILS + localStorage.getItem("AuthUserSub"), {
                "user_metadata": {
                    
                    "weightData": "'" + localStorage.getItem("weightData") + "'"
                    
                }

            }, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    }

    useEffect(() => {
        questioncompletetrue()     
      }, [])
    
      
    return (
        <>

            <div className='non-elibile-main-container'>
                <div style={{
                    flexDirection: 'column', display: 'flex', marginTop: '10.0rem',
                    width: '48.00rem',

                }}>
                    <p className='elibile-heading-text'>SORRY!</p>
                    <p className='elibile-sub-text'>Pepstep is not the right fit for you right now.But don't worry, there are other ways to reach your weight loss goals! We recommend that you talk to your doctor or a dietitian for more advice</p>
                    <p className='elibile-sub-text' style={{ marginTop: '1.8rem' }}>If you would like to say up to date with Pepstep products and services, register to our mailing list today! </p>

                    <div
                       onClick={()=>{logoutWithRedirect()}}
                        style={{
                            flexDirection: 'row', display: 'flex', justifyContent: 'space-between',
                            backgroundColor: '#8DB9DE', borderRadius: '2.0rem',
                            height: '5.0rem', width: '250px', paddingLeft: '4.5rem',
                            alignSelf: 'center', alignItems: 'center', marginTop: '3.0rem',
                            position: 'relative', cursor: 'pointer'

                        }}
                    >
                        <a className='register-now-text'>GO BACK</a>
                        <div style={{
                            backgroundColor: '#0E7CCF', width: '5.0rem', alignSelf: 'center', alignItems: 'center',
                            height: '5.0rem', borderTopRightRadius: '2.0rem', borderBottomRightRadius: '2.0rem',
                        }}>
                            <KeyboardArrowRightIcon
                                style={{
                                    alignSelf: 'center', marginTop: '0.8rem', marginLeft: '1.0rem',
                                    width: '3.0rem', height: '3.0rem', color: 'white'
                                }}
                            />

                        </div>
                    </div>
                </div>

            </div>
        </>

    )
}

export default NonEligibleScreen;