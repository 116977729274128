

import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import '../utils/page-css/pagegupOne.css';
import Menu from '@mui/material/Menu/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useAuth0 } from "@auth0/auth0-react";

export default function DashboardNavPage() {
    const [anchorEl, setAnchorEl] = useState(null);

    

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const {
        user,
        logout
    } = useAuth0();
    const logoutWithRedirect = () => {
        localStorage.clear()
        localStorage.removeItem("id_token"); 
        logout();
        

    }


    return (
        <nav className='navbar-links-open'>
            <div className="menuIcon">
                <ul className="dash-navbar-list">
                    <li>
                        <NavLink className={"dash-nav-links"} to={"/dashboard"}>Dashboard</NavLink>
                    </li>
                    <li>
                        <NavLink className={"dash-nav-links"} to={"/account"}>Account</NavLink>
                    </li>
                    <div style={{ width: "10.0rem", }}>
                        <NavLink className={"logout-btns"} onClick={() => logoutWithRedirect()}>Logout</NavLink>
                    </div>

                </ul>

            </div>

        </nav>
    )
}