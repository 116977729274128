import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "./config";
import { createBrowserHistory } from 'history';
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from 'react-toastify';

import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';

const root = ReactDOM.createRoot(document.getElementById('root'));
const config = getConfig();



// const onRedirectCallback = (appState) => {
//   history.replace(
//     appState && appState.returnTo
//       ? appState.returnTo
//       : window.location.href
//   );
// };
const history = createBrowserHistory();
const isProduction = process.env.NODE_ENV === 'production';
const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin + "/dashboard2",
  // onRedirectCallback
};
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>

  <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction} //If false, the library is disabled.
     
    >
  <Auth0Provider {...providerConfig}>

    <App />
    <ToastContainer style={{ zIndex: 999 }} />

  </Auth0Provider>
  </CacheBuster>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
