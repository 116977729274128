import React from 'react';
import { useQuery, gql } from '@apollo/client';
import '../../src/utils/page-css/pagegupOne.css';
import configJson from "../auth_config.json";
const ProviderDropdown = ({ onSelectProvider, hasSchedule }) => {
  const [providers, setProviders] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(configJson.CHR_API_Next + `api/getProviders?hasSchedule=${String(hasSchedule)}`);
        const responseData = await response.json();
    
        if (responseData.providers && responseData.providers.edges) {
          setProviders(responseData.providers.edges.map(edge => edge.node));
        } else {
          throw new Error('Data received from the server is not in the expected format.');
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    

    fetchData();
  }, [hasSchedule]);

  const handleSelect = (event) => {
    const selectedProviderId = event.target.value;
    const selectedProvider = providers.find((provider) => provider.id === selectedProviderId);
    onSelectProvider(selectedProvider);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className='drop-column-container'>
      <div className='div-dropdown-input-container'>
        <select
          className="form-select shadow-none"
          style={{
            height: 40, fontSize: '1.4rem', fontFamily: 'Nunito Sans',
            width: '35.0rem', fontWeight: 500
          }}
          onChange={handleSelect}
        >
          <option>Select a provider</option>
          {providers.map((provider) => (
            provider.fullName !== null && (
              <option
                key={provider.id}
                value={provider.id}
                selected={localStorage.getItem("Selected Provider") === provider.id}
              >
                {provider.fullName}
              </option>
            )
          ))}
        </select>
      </div>
    </div>
  );
};

export default ProviderDropdown;



