// import SignUp from "../auth/SignUp"
import Questions from "../auth/Questions"
import SingUpOne from "../auth/SignUpOne"
import SuccessPayment from "../pages/SuccessPayment"
import Dashboard from "../pages/Dashboard"
import Dashboard2 from "../pages/Dashboard2"

import Account from "../components/Account"
import PrivacyPolicy from "../pages/PrivacyPolicy"
import TermsOfUse from "../pages/TermsOfUse"
import EligibleScreen from "../pages/EligibleScreen"
import NonEligibleScreen from "../pages/NonEligibleScreen"
import PrivacyPolicyDash from "../pages/PrivacyPolicyDash"
import TermsOfUseDash from "../pages/TermsOfUseDash"





export const AllImportPages = {


    // SINGUP_PAGE: SignUp,
    QUESTIONS_PAGE: Questions,
    SuccessPayment: SuccessPayment,
    SINGUP_PAGE_ONE: SingUpOne,
    Dashboard: Dashboard,
    Dashboard2: Dashboard2,
    Account: Account,
    ELIGIBLESCREEN: EligibleScreen,
    NONELIGIBLESCREEN: NonEligibleScreen,
    PRIVACYPOLICYDash: PrivacyPolicyDash,
    TERMSOFUSEDASH: TermsOfUseDash,


    
    





}


