import '../utils/page-css/pagelayout.css';

// interface CommonRadioButtonProps {

//     id: any;
//     value: string
//     onChange: Function;
//     selected: any,
//     text: string
// }



const CommonRadioButton = (props) => {
    return (
        <div className="question-radio-container">
            <div className="question-modern-radio-container"
                onClick={() => {
                    props.onChange(props.value);
                }}>
                <div
                    className={`question-radio-outer-circle ${props.value !== props.selected && "question-unselected"}`}
                >
                    <div
                        className={`question-radio-inner-circle ${props.value !== props.selected &&
                            "question-unselected-circle"}`}
                    />
                </div>
                <div className="question-helper-text">{props.text}</div>

            </div>
        </div>


    )
}

export default CommonRadioButton;