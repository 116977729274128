import React, {useEffect, useMemo, useState} from 'react'
import configJson from "../auth_config.json";
import axios from 'axios'
import {AppImage} from "../theme/app.images";

const ReSubscribe = () => {
    const [productData, setProductData] = useState(null);
    async function fetchProductData() {
        try {
          // Replace 'YOUR_STRIPE_SECRET_KEY' with your actual Stripe Secret Key
          const token = configJson.STRIPE_TOKEN;
          const response = await axios.get('https://api.stripe.com/v1/products', {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
    
           
          });
          
          
    
          // Assuming the response contains an array of products, you can set the first product in the state
          if (response.data && response.data.data && response.data.data.length > 0) {
            console.log("details all",response.data.data)
            setProductData(response.data.data[0]);
        
          }
        } catch (error) {
          console.error('Error fetching product data:', error);
        }
      }
      const buySubscription = async () => {
        let customer = localStorage.getItem('stripe_customer_id');
      const formData = {
        cancel_url: window.location.protocol + '//' + window.location.host + '/account?response=failure',
        success_url: window.location.protocol + '//' + window.location.host + '/account?response=Success' ,
        line_items: [
          {
            price: productData.default_price,
            quantity: 1,
          },
        ],
        mode: "subscription",
        automatic_tax: {
          enabled: true,
        },
        allow_promotion_codes: true,
        customer: customer,
        subscription_data: {
          trial_period_days: 60,
        },
      
        customer_update: {
          address: "auto",
        },
        billing_address_collection: "auto",
      };
    
      try {
        const token = configJson.STRIPE_TOKEN;
        const response = await axios.post(configJson.STRIPE_SESSION, formData, {
          headers: {
            "Authorization": `Bearer ${token}`,
            'content-type': 'application/x-www-form-urlencoded'
          }
        });
    
        if (response.status === 200) {
          // Redirect to the payment page
          window.location.href = response.data.url;
        
        }
      } catch (error) {
        console.error('Payment Error:', error.response.data);
        // Handle payment error here (show an error message to the user, etc.)
      }
    };
    useEffect(() => {
        fetchProductData();
        
      }, []);
  return (
    <div>
        <p className='health-availability-text-style1'>Please use the calendar below to setup an appointment with our medical practitioners. If you do not see the calendar, please ensure you have an active subscription with Pepstep.</p>
        <center>
        <div className='zIndex-sub-container-bg'>
              <div className='alert-dialog-row-space-between' style={{width:"unset"}}>
                <a></a>
                <span className='reset-password-text-style'>Subscription Details</span>
              </div>
              <div style={{ width: '100%', flexDirection: 'column', paddingLeft: '3.0rem', paddingRight: '3.0rem' }}>
                
                  <center><h3> No Subscription Found!! </h3></center>
                  <div className='alert-dailog-green-border-container' style={{
                    border: "none",
                    backgroundColor:  '#4F7942	' ,
                    marginTop: '3.0rem'
                  }}>
                    <img src={AppImage.cancelSubscriptionImage}

                      className='icon-size-note' />
                    <p onClick={() => {buySubscription()}}

                      className='update-pass-text-style' style={{ marginTop: '1.4rem', color: 'white' }} >Buy Subscription</p>
                  </div>


              </div>

        </div>
        <div style={{ height: "5em" }} ></div>
        </center>
    </div>
  )
}

export default ReSubscribe
