import '../utils/page-css/pagegupOne.css'
import { AppImage } from "../theme/app.images";
import React, { useEffect, useMemo, useState } from "react";
import DashboardNavBar from "../utils/private-header/DashboardNavBar";
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import "react-datepicker/dist/react-datepicker.css";
import configJson from "../auth_config.json";
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { generateDate, months, weekDays } from "../components/Calendar";
import cn from "../components/cn";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import dayjs from "dayjs";


import FlatList from 'flatlist-react';
import swal from 'sweetalert';
// import { gql, useQuery } from '@apollo/client';

import { format } from 'date-fns';
import {
    ApolloClient,
    useQuery,
    InMemoryCache,
    gql,
    ApolloProvider,
    useMutation
} from '@apollo/client'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';
import RestoreSharpIcon from '@mui/icons-material/RestoreSharp';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import UpdateDialogBox from '../components/UpdateDialog';
import { af } from 'date-fns/locale';



const ScheduleDesign = () => {
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const now = new Date()
    let date = parseInt(now.getMonth() + 1) + "/" + now.getDate() + "/" + now.getFullYear();
    const currentDate = dayjs();
    const [today, setToday] = useState(currentDate);
    const [selectDate, setSelectDate] = useState(currentDate);
    const [clickTimeValue, setClickTimeValue] = useState();
    const [FinalValue, setFinalValue] = useState([]);
    const [CHECKAPPOINTMENT, setCHECKAPPOINTMENT] = useState();
    const [MetaData, setMetaData] = useState({ appointmentID: "" });
    let navigate = useNavigate();
    const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
    const[selectedProvider,setSelectedProvider]=useState(null)
    const [selectDoctor, setSelectDoctor] = useState(null);
    const [doctorHideShow, setDoctorHideShow] = useState(false);
    const [AlreadyHaveProvider, setAlreadyHaveProvider] = useState(false);
    const [practitionerData, setPractitionerData] = useState(null);
    const [CHECK_APPOINTMENT_data, setCHECK_APPOINTMENT_data] = useState(null);
    const [providerInfo, setProviderInfo] = useState([]);
    const [serviceId, setServiceId] = useState('36578');
    const[locationId,setLocationId]=useState("")
    const [rows, setRow] = useState([]);
    const [combData, setcombData] = useState()





    const handleSelect = (e) => {
        if (e.target.value && e.target.value !== "Select a Provider") {
          
          localStorage.setItem('Selected Provider', e.target.value);
          setSelectedProvider(e.target.value);
          setDoctorHideShow(true);


          
          const filteredData = combData.filter(
            (item) => item.providerID === localStorage.getItem('Selected Provider')
          );
        console.log("Data with correspondent",filteredData)
          // Update state variables
          setAvailabilityData(filteredData);
          availDateGet(filteredData);

        } else {


          // If "Select a Provider" option is chosen
          localStorage.removeItem('Selected Provider');
          setDoctorHideShow(false);
          setSelectedProvider(e.target.value);

          
        }
      
       
      };
      
    


    async function CheckRedirect() {
        if (isAuthenticated == true) { 
            localStorage.setItem("AuthUserSub", user.sub)
            if (localStorage.getItem("paymentSuccess")) {
                if (localStorage.getItem("paymentSuccess") == "true") {
                    
                    const token = configJson.AUTH_MANAGEMENT_TOKEN
                    axios.patch(configJson.AUTH_USER_DETAILS + user.sub, {
                        "user_metadata": {
                            "questionComplete": "true",
                            "weightData": "'" + localStorage.getItem("weightData") + "'"
                        }

                    }, {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    })
                    localStorage.removeItem("paymentSuccess")
                }
            }
            const token = configJson.AUTH_MANAGEMENT_TOKEN

            let sub = user.sub
            async function abc() {
                const res = await axios.get(configJson.AUTH_USER_DETAILS + sub, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                if (res.data.user_metadata) {

                    const UMD = JSON.stringify(res.data.user_metadata)
                    const re = /'/gi;
                    
                    localStorage.setItem("user_metadata", UMD)
                    localStorage.setItem("weightData", JSON.parse(UMD).weightData.replace(re, ""))
                    setMetaData(res.data.user_metadata.appointmentID)
                }

                if (res.data.app_metadata) {
                    localStorage.setItem("stripe_customer_id", res.data.app_metadata.stripe_customer_id)
                }


                if (res.data.user_metadata.Patientid) {
                    localStorage.setItem("Patientid", res.data.user_metadata.Patientid)
                }

                
                if (res.data.user_metadata.appointmentID != '') {
                    setCHECKAPPOINTMENT("CONFIRMED")
                }


                if (res.data.user_metadata.questionComplete === "false") {
                    swal({
                        title: "Registration Incomplete",
                        text: "Please complete the registration process",
                        icon: "warning",
                        buttons: "Go to Questions",
                    }).then(() => {
                        navigate('/questions');
                    });
                }


            }
            abc()
        } else {
            if (localStorage.getItem("paymentSuccess")) {
                if (localStorage.getItem("paymentSuccess") == "true") { 
                    const token = configJson.AUTH_MANAGEMENT_TOKEN
                    axios.patch(configJson.AUTH_USER_DETAILS + localStorage.getItem("AuthUserSub"), {
                        "user_metadata": {
                            "questionComplete": "true",
                            "weightData": "'" + localStorage.getItem("weightData") + "'"
                        }
                    }, {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    })
                    localStorage.removeItem("paymentSuccess")
                }
            }

            if (localStorage.getItem("user_metadata")) {

                setMetaData(JSON.parse(localStorage.getItem("user_metadata")))
                const metaData2 = JSON.parse(localStorage.getItem("user_metadata"))

                if (metaData2.appointmentID != "") {
                    setCHECKAPPOINTMENT("CONFIRMED")
                }
            }

        }


    }

   

    const renderItem = (item) => {


        return (
            <a className='time-bg-container'
                style={
                    {
                        backgroundColor: item.time === clickTimeValue ? '#486cee' : '',
                        color: item.time === clickTimeValue ? 'white' : ''

                    }
                }
                onClick={
                    () => {
                        handleTimeClick(item)
                    }
                }>
                {
                    item.time
                }</a>

        );
    };

    const handleTimeClick = (e) => {

        setClickTimeValue(e.time);
        setFinalValue([e.startAt, e.untilAt]);

        

    }


    const [TimeDataModel, setTimeDataModel] = useState([{
        key: 1,
        time: 'Select Date to choose slot'
    }])


    
   
    
    const [availabilityData, setAvailabilityData] = useState([]);
    
 
    

   // Function to generate dateRanges dynamically
   const generateDateRanges = (startDate, numberOfWeeks) => {
    const dateRanges = [];
    const startSunday = new Date(startDate);
  
    if (startSunday.getDay() !== 0) {
      // If startDate is not a Sunday, find the nearest Sunday
      startSunday.setDate(startSunday.getDate() - startSunday.getDay());
    }


    const isWinterPeriod = (date) => {
        const currentDate = new Date(date);
        const currentMonth = currentDate.getMonth() +1 ; // Month is zero-indexed
        const currentDay = currentDate.getDate();
        
        if (
          (currentMonth >= 11 || currentMonth <= 3) ||
          (currentMonth === 11 && currentDay >= 5) ||
          (currentMonth === 3 && currentDay <= 9)
        ) {
          return true;
        }
        
        return false;
      };
      
      
  
    for (let i = 0; i < numberOfWeeks; i++) {
      const from = new Date(startSunday);
      from.setDate(from.getDate() + i * 7); // Start of each week
      const to = new Date(from);
      
      to.setDate(to.getDate() + 6); // End of each week
      const isWinterTimezone = isWinterPeriod(from) ? '-07:00' : '-06:00';
      dateRanges.push({
        from: from.toLocaleDateString('en-GB', {year:'numeric', month: '2-digit', day:'2-digit' }).split('/').reverse().join('-') + `T00:00:00${isWinterTimezone}`,
        to: to.toLocaleDateString('en-GB', {year:'numeric', month: '2-digit', day:'2-digit' }).split('/').reverse().join('-') + `T23:59:00${isWinterTimezone}`,
      });
    }
    return dateRanges;
  };
   

  const startDate = useMemo(() => {
    const currentDate = new Date();
  
    // Check if the current day is Sunday (day 0), if so, return the current date.
    if (currentDate.getDay() === 0) {
      return currentDate.toISOString();
    }
  
    // Calculate the number of days until the previous Sunday.
    const daysUntilPreviousSunday = currentDate.getDay();
    currentDate.setDate(currentDate.getDate() - daysUntilPreviousSunday);
  
    // Check if the calculated date is Saturday.
    if (currentDate.getDay() === 6) {
      // If it's Saturday, subtract 1 day to avoid crossing Saturday.
      currentDate.setDate(currentDate.getDate() - 1);
    }
  
    return currentDate.toISOString();
  }, []);
  
  

  const numberOfWeeks = 3;

  // Generate dateRanges dynamically
  const dateRanges = generateDateRanges(startDate, numberOfWeeks);
  const [myAvaildates, setmyAvaildates] = useState([])
  const [myAvaildatesWithTime, setmyAvaildatesWithTime] = useState([])
    function availDateGet(Avail) {
        let abc = []
        let abc2 = []
        Avail.map((item) => {
            const originalDate = new Date(item.start).toLocaleDateString('en-GB', {year:'numeric', month: '2-digit', day:'2-digit' }).split('/').reverse().join('-');
            if (!abc2.includes(originalDate)) {
                abc2.push(originalDate)
            }
            const options = {
                year:'numeric',
                month: '2-digit', 
                day:'2-digit',
                timeZone:"America/Sao_Paulo",
                hour12 : false,
                hour:  "2-digit",
                minute: "2-digit",
                second: "2-digit"
             };
             const originalDate2 = new Date(item.start).toLocaleTimeString('en-GB', options);
             
             if (!abc.includes(originalDate2)) { 
                abc.push(originalDate2)    
            }
        })
        setmyAvaildates(abc2)
        setmyAvaildatesWithTime(abc)
    }


    const fetchPractitionerData = async () => {
        try {
          const response = await axios.get(configJson.CHR_API_Next + `api/fetchPractitioner?userId=${localStorage.getItem("AuthUserSub")}`);
          setPractitionerData(response.data);
          localStorage.setItem("locationId", response.data.patient.primaryLocation.id)
          setLocationId(response.data.patient.primaryLocation.id);
        } catch (error) {
          console.error('Error fetching practitioner data:', error.message);
        }
      };
    useEffect(() => {
        if (localStorage.getItem("Selected Provider")) {
        //   setSelectDoctor(localStorage.getItem("Selected Provider"));
          setSelectedProvider(localStorage.getItem("Selected Provider"));
        }
        CheckRedirect();
      }, []);
      useEffect(() => {
        if (practitionerData) {
          if (practitionerData.patient) {
            if (practitionerData.patient.primaryPractitioner != null || practitionerData.patient.primaryPractitioner != undefined) {
              localStorage.setItem('Selected Provider', practitionerData.patient.primaryPractitioner.id);
            //   setSelectDoctor(practitionerData.patient.primaryPractitioner.id);
              setSelectedProvider(practitionerData.patient.primaryPractitioner.id); 
            }
          }
        }
    
        if (localStorage.getItem('Selected Provider')) {
          setDoctorHideShow(true);
        }
      }, [practitionerData]);


    useEffect(() => {
        if (selectedProvider) {
            
        } else {
            // alert(localStorage.getItem('Selected Provider'))
        const userGroupId="5237"
        const fetchData = async (from, to) => {
    try {
      const requestBody = {
        id: userGroupId,
        from: from,
        to: to,
        serviceId:serviceId,
        locations:localStorage.getItem("locationId")

        
      };
      console.log(requestBody)
      const response = await fetch(configJson.CHR_API_Next + 'api/getAvailability', {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      console.log("combData" + JSON.stringify(data))
      let Avail = [];
     
      if (data && data.availabilities) {
        data.availabilities.forEach((availability) => {
            let prevProviderName = ""
            let prevProviderID = ""
            let providerName = availability.provider.fullName
            let providerID = availability.provider.id
            if (prevProviderName == "") {
                prevProviderName = providerName
                prevProviderID = providerID
            }
            if (providerName == prevProviderName) {
                Avail.push({
                    providerName: providerName,
                    providerID: providerID,
                    start: availability.startAt,
                    until: availability.untilAt,
                  });
                
            }
            prevProviderName = providerName
            prevProviderID = providerID
          
        });
      }
      
      return Avail
    //   return Avail;
    } catch (error) {
      console.error('Error fetching availability data:', error);
      return [];
    }
          };
      
          // Function to fetch data for multiple date ranges
          const fetchAllData = async () => {
            let allAvailData = [];
            for (let i = 0; i < numberOfWeeks; i++) {
              const from = dateRanges[i].from;
              const to = dateRanges[i].to;
              const availData = await fetchData(from, to);
              allAvailData = allAvailData.concat(availData);
            }
            
            return allAvailData;
          };
          
          const fetchDataAndAvailability=async()=>{
            await fetchPractitionerData();
          fetchAllData().then((allAvailData) => {
            const providers = allAvailData.map(({ providerName, providerID }) => ({
                providerName,
                providerID,
              }));
            const uniqueProviders = [...new Set(providers.map(JSON.stringify))].map(JSON.parse);
            setProviderInfo(uniqueProviders);
            
            const combinedData = [...allAvailData];
            const filteredData = combinedData.filter(
                (item) => item.providerID === localStorage.getItem('Selected Provider')
              );
            console.log("Data with correspondent",filteredData)
              // Update state variables
              setAvailabilityData(filteredData);
              availDateGet(filteredData);
            setcombData([...allAvailData])
            
            
            });
        }
        fetchDataAndAvailability();
        }
        

      }, []);


    

     
      
    

   

    
    function selectedDates(date) { 
        setClickTimeValue()
        let key = 0
        let val = []

        availabilityData.map((item) => { 
            const modifiedDate = new Date(item.start).toLocaleDateString('en-GB', {year:'numeric', month: '2-digit', day:'2-digit' }).split('/').reverse().join('-');

            if (modifiedDate === date) {

                val[key] = {
                    key: key,
                    time: format(new Date(item.start), 'p') + " to " + format(new Date(item.until), 'p'),
                    startAt: item.start,
                    untilAt: item.until
                }
                key++;
                
            }

            
        })
        setTimeDataModel(val)

    }


function SetClassAvailDates(dates) { 
    if (myAvaildates.includes(dates)) {
        return true
    }else{
        return false
    }
} 

    Array.prototype.inArray = function (needle) {

        return Array(this).join(",").indexOf(needle) > -1;

    }


    async function CreateNewAppointment() {
        const userId = localStorage.getItem("AuthUserSub");
        const PractitionerId = localStorage.getItem("Selected Provider");
        const locationId=localStorage.getItem("locationId")

        try {
            const response = await axios.post(configJson.CHR_API_Next + 'api/updatePractitioner', {
            userId: userId,
            primaryPractitionerId: parseInt(PractitionerId),
            primaryLocationId:locationId
        });
    } catch (error) {
        console.error('Error Updating patient:', error.message);
    }


        try {
            const splitDate = FinalValue[0].split("T")
            let dateArr = []
            rows.map((rowDates) => {
                dateArr.push(rowDates.node.date)
            })
            
            try {
                if (!dateArr.inArray(splitDate[0])) {
                    const response = await axios.post(configJson.CHR_API_Next + 'api/bookAppointement', {
                        userId:localStorage.getItem("AuthUserSub"),
                        locationId: localStorage.getItem("locationId"),
                        providerId: localStorage.getItem('Selected Provider'),
                        serviceId: serviceId,
                        startAt: new Date(FinalValue[0]).toISOString().replace('.000Z', "+0000"),
                        untilAt: new Date(FinalValue[1]).toISOString().replace('.000Z', "+0000"),
                        visitType: "VIRTUAL_VISIT",
                    });
                    if (response.data.createAppointment.status) {
                        swal("Appointment Booked", "Your appointment has been successfully booked. If you need to cancel your appointment, please call or email us 24 hours prior to your appointment time.", "success").then(() => {
                            setCHECKAPPOINTMENT(response.data.createAppointment.status);
            
                            const token = configJson.AUTH_MANAGEMENT_TOKEN;
                            axios.patch(configJson.AUTH_USER_DETAILS + localStorage.getItem("AuthUserSub"), {
                                "user_metadata": {
                                    "appointmentID": response.data.createAppointment.id
                                }
                            }, {
                                headers: {
                                    "Authorization": `Bearer ${token}`
                                }
                            });
            
                            let user_metadata_old = JSON.parse(localStorage.getItem("user_metadata"));
            
                            const re = /'/gi;
            
                            let user_metadata = JSON.parse(user_metadata_old.userData.replace(re, ""));
                            let up_fname = "";
                            let up_lname = "";
                            if ("fname" in user_metadata) {
                                up_fname = user_metadata.fname;
                            }
                            if ("lname" in user_metadata) {
                                up_lname = user_metadata.lname;
                            }
            
                            let update_user_metadata = {
                                "fullName": user_metadata.fullName,
                                "fname": up_fname,
                                "lname": up_lname,
                                "email": user_metadata.email,
                                "phoneNumber": user_metadata.phoneNumber,
                                "dateOfBirth": user_metadata.dateOfBirth,
                                "gender": user_metadata.gender,
                                "password": user_metadata.password,
                                "appointmentID": response.data.createAppointment.id,
                                "questionComplete": user_metadata.questionComplete,
                                "Patientid": user_metadata.Patientid,
                                "medicalNumber": user_metadata.medicalNumber,
                                "address": user_metadata.address,
                                "county": user_metadata.county,
                                "city": user_metadata.city,
                                "country": user_metadata.country,
                                "province": user_metadata.province,
                                "medicalIdentification": user_metadata.medicalIdentification
                            };
            
                            const userData = {
                                userData: "'" + JSON.stringify(update_user_metadata) + "'",
                                Vitals: user_metadata_old.Vitals,
                                appointmentID: response.data.createAppointment.id,
                                questionComplete: user_metadata_old.questionComplete,
                                Patientid: user_metadata_old.Patientid,
                                StripeData: user_metadata_old.StripeData,
                                weightData: user_metadata_old.weightData,
                            };
            
                            localStorage.setItem("user_metadata", JSON.stringify(userData));
                            
                            // Reload the window after booking
                            window.location.reload();
                        });
                    }
                } else {
                    swal("Error", "Already Booked on " + splitDate[0] + " this Date!", "error");
                }
            } catch (error) {
                if (error.response) {
                    if (error.response.data && error.response.data.error && error.response.data.error.graphQLErrors) {
                        const graphQLError = error.response.data.error.graphQLErrors[0];
                        const errorMessage = graphQLError.message;
            
                        console.error('GraphQL Error:', errorMessage);
                        swal("Error", errorMessage, "error");
                    } else {
                        // Handle other non-GraphQL errors
                        console.error('Server responded with non-success status:', error.response.data);
                        swal("Error", error.response.data.error || "Something went wrong on the server.", "error");
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    console.error('No response received from the server:', error.request);
                    swal("Error", "No response received from the server.", "error");
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.error('Error setting up the request:', error.message);
                    swal("Error", "Error setting up the request.", "error");
                }
            }
        }catch (error) {
            console.error('Outer try block error:', error.message);
        }



    }
    

    useEffect(() => {
        const CHECK_APPOINTMENT = async () => {
          try {
            const userId= localStorage.getItem('AuthUserSub')
            const response = await fetch(configJson.CHR_API_Next + 'api/checkAppointment', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                
              },
              body: JSON.stringify({ userId: userId }),
            });
    
            if (!response.ok) {
              throw new Error('Failed to fetch appointment data');
            }
    
            const data = await response.json();
            if (data && data.appointments) {
              const appointments = data.appointments.edges;
    
              if (appointments && appointments.length > 0) {
                if (!rows || rows.length === 0) {
                  setRow(appointments);
                  setAlreadyHaveProvider(true);
                  setServiceId('36579');
                }
              }
            }
    
            setCHECK_APPOINTMENT_data(data);
          } catch (error) {
            console.error('Error fetching appointment data:', error.message);
          }
        };
    
        CHECK_APPOINTMENT();
      }, []);

      


      

    

    
    const [fetchedAppID, setfetchedAppID] = useState({})
    function openProviderWindow(providerId, appointmentID, serviceId, myDate,locationId) {
        
        setfetchedAppID({
            providerID: providerId,
            appointmentID: appointmentID,
            serviceId: serviceId,
            rows: rows,
            myDate: myDate,
            locationId:locationId

        })
        setOpenUpdateDialog(!openUpdateDialog)
    }
    return (
        <>
            {
                openUpdateDialog === true ? (
                    <UpdateDialogBox providerID={fetchedAppID} />
                ) : (
                    <div></div>
                )
            }
            <div>

                
                <p className='health-availability-text-style1'>Please use the calender below to setup an appointment with our medical practitioners. If you do not see the calendar, please ensure you have an active subscription with Pepstep.</p>
                <span className='health-availability-text-style'>Schedule your appointment</span>
                {
                    AlreadyHaveProvider ?
                        ("")
                        :
                        (<div className='select-provider-row-column'>
                            <span className='select-provider-text-style'>Select provider:</span>
                            <div className='drop-column-container'>
      <div className='div-dropdown-input-container'>
      <select
          className="form-select shadow-none"
          style={{
            height: 40, fontSize: '1.4rem', fontFamily: 'Nunito Sans',
            width: '35.0rem', fontWeight: 500
          }}
          onChange={handleSelect}
          value={selectedProvider}
        >
          <option value="">Select a Provider</option>
          {providerInfo.map((provider) => (
            <option key={provider.providerID} value={provider.providerID}>
              {provider.providerName}
            </option>
          ))}
        </select>
      </div>
    </div>
                        </div>)
                }







                {
                    doctorHideShow === true ? (
                        <div className='appointmentFieldRow'>

                            <div className='appointmentLeftContainer'>

                                <div className='initial-date-row'>

                                    <a className='initial-date-text'>
                                        {
                                            today.date()
                                        }/{
                                            today.month() + 1
                                        }/{
                                            today.year()
                                        }</a>
                                    <CalendarMonth style={
                                        {
                                            width: '20px',
                                            height: '20px'
                                        }
                                    } />

                                </div>
                                <hr style={
                                    {
                                        background: "#e4e7e9",
                                        height: "1.5px",
                                        border: "none",
                                        width: '100%',
                                        marginTop: '5px'
                                    }
                                } />
                                <div className='month-and-year-row'>
                                    <div className='year-row'>
                                        <a className='initial-date-text'>
                                            {
                                                months[today.month()]
                                            }</a>
                                        <div style={
                                            {
                                                flexDirection: 'column',
                                                display: 'flex'
                                            }
                                        }>
                                            <KeyboardArrowUpIcon onClick={
                                                () => {
                                                    
                                                    setToday(today.month(today.month() - 1));
                                                    
                                                }
                                            }
                                                style={
                                                    {
                                                        width: '1.5rem',
                                                        height: '1.5rem',
                                                        marginTop: '0.4rem'
                                                    }
                                                } />
                                            <KeyboardArrowDownIcon onClick={
                                                () => {
                                                    
                                                    setToday(today.month(today.month() + 1));
                                                    
                                                }
                                            }
                                                style={
                                                    {
                                                        width: '1.5rem',
                                                        height: '1.5rem'
                                                    }
                                                } />
                                        </div>

                                    </div>
                                    <div style={
                                        {
                                            height: '3.0rem',
                                            width: '1px',
                                            backgroundColor: '#E4E7E9'
                                        }
                                    }></div>
                                    <div className='year-row'>
                                        <a className='initial-date-text'
                                            style={
                                                { paddingLeft: '1.2rem' }
                                            }>
                                            {
                                                today.year()
                                            }</a>
                                        <div style={
                                            {
                                                flexDirection: 'column',
                                                display: 'flex'
                                            }
                                        }>
                                            <KeyboardArrowUpIcon onClick={
                                                () => {
                                                    setToday(today.year(today.year() - 1));
                                                }
                                            }
                                                style={
                                                    {
                                                        width: '1.5rem',
                                                        height: '1.5rem',
                                                        marginTop: '0.4rem'
                                                    }
                                                } />
                                            <KeyboardArrowDownIcon onClick={
                                                () => {
                                                    setToday(today.year(today.year() + 1));
                                                }
                                            }
                                                style={
                                                    {
                                                        width: '1.5rem',
                                                        height: '1.5rem'
                                                    }
                                                } />
                                        </div>

                                    </div>
                                </div>
                                <hr style={
                                    {
                                        background: "#e4e7e9",
                                        height: "1.5px",
                                        border: "none",
                                        width: '100%',
                                        marginTop: '5px'
                                    }
                                } />
                                <div className='sun-mon-days-row'>
                                    <a className='days-text-style '>SUN</a>
                                    <a className='days-text-style '>MON</a>
                                    <a className='days-text-style '>TUE</a>
                                    <a className='days-text-style '>WED</a>
                                    <a className='days-text-style '>THU</a>
                                    <a className='days-text-style '>FRI</a>
                                    <a className='days-text-style '>SAT</a>
                                </div>
                                <div style={
                                    {
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        display: 'flex',
                                        justifyContent: "space-between"
                                    }
                                }
                                    id="calendarDates">
                                    {
                                        generateDate(today.month(), today.year()).map(({
                                            date,
                                            currentMonth,
                                            today
                                        }, index) => {
                                            let dayWith0 = date.date() < 10 ? '0' + date.date() : date.date()
                                            let monthWith0 = (date.month() + 1) < 10 ? '0' + (
                                                date.month() + 1
                                            ) : (date.month() + 1)
                                            let SetDate = date.year() + '-' + monthWith0 + '-' + dayWith0

                                            return (
                                                <div key={index}
                                                    className='fiients'>
                                                    
                                                    <p className={

                                                        cn(selectDate.toDate().toDateString() === date.toDate().toDateString() ? "visited" : today ? "visited-one" : "", "", SetClassAvailDates(SetDate) ? "" : "unselected-date-text")
                                                    }

                                                        onClick={
                                                            SetClassAvailDates(SetDate) ? (e) => {
                                                                
                                                                setSelectDate(date)
                                                                selectedDates(SetDate)
                                                                setToday(date)

                                                            } : (e) => {
                                                                e.preventDefault()
                                                            }
                                                        }>
                                                        {
                                                            date.date()
                                                        } </p>
                                                </div>
                                            );
                                        })
                                    } </div>


                            </div>

                            <div className='appointmentRightContainer'>

                                <div className='initial-date-row'>
                                    <a className='initial-date-text'>
                                        {
                                            weekDays[today.day()] + ", " + months[today.month()] + " " + today.year()
                                        }</a>
                                    <div style={
                                        {
                                            flexDirection: 'row',
                                            display: 'flex'
                                        }
                                    }>
                                        <KeyboardArrowLeftIcon onClick={
                                            () => {
                                                setToday(today.year(today.year() - 1));
                                                
                                            }
                                        }
                                            style={
                                                {
                                                    backgroundColor: "#DBDEE9",
                                                    color: '#8794AC',
                                                    borderRadius: '50%',
                                                    width: '2.5rem',
                                                    height: '2.5rem'
                                                }
                                            } />

                                        <KeyboardArrowRightIcon onClick={
                                            () => {
                                                setToday(today.year(today.year() + 1));

                                            }
                                        }
                                            style={
                                                {
                                                    backgroundColor: "#DBDEE9",
                                                    marginLeft: '1.0rem',
                                                    color: '#8794AC',
                                                    borderRadius: '50%',
                                                    width: '2.5rem',
                                                    height: '2.5rem'
                                                }
                                            } />


                                    </div>

                                </div>
                                <div className='mydivConatiner'
                                    style={
                                        { marginTop: '2.0rem' }
                                    }>
                                    <FlatList list={TimeDataModel}
                                        renderItem={renderItem}
                                        values="lll" />


                                </div>


                            </div>

                            {
                                clickTimeValue && clickTimeValue != "Select Date to choose slot" ? (<div className="col-md-2 mt-3 ml-5" style={{ marginLeft: "0em", justifyContent: "center", display: "flex", height: "fit-content" }}>
                                    <button className="btn btn-lg btn-block btn-success p-4 fs-32"
                                        onClick={
                                            () => CreateNewAppointment()
                                        }>
                                        Book Appointment
                                    </button>
                                </div>) : (<><div className="col-md-2 mt-3 ml-5" style={{ marginLeft: "0em", justifyContent: "center", display: "flex", height: "fit-content" }}></div></>)
                            }




                        </div>
                    ) : (
                        <div></div>
                    )
                } </div>



            <div style={
                {
                    marginTop: '3.0rem',
                }
            }>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead >
                            <TableRow className='table-header-text'>
                                <TableCell align='center'
                                    style={
                                        {
                                            color: 'black',
                                            fontSize: '1.4rem',
                                            fontFamily: 'Nunito Sans',
                                            fontWeight: '400'
                                        }
                                    }>Service</TableCell>
                                <TableCell align="center"
                                    style={
                                        {
                                            color: 'black',
                                            fontSize: '1.4rem',
                                            fontFamily: 'Nunito Sans',
                                            fontWeight: '400'
                                        }
                                    }>Date</TableCell>
                                <TableCell align="center"
                                    style={
                                        {
                                            color: 'black',
                                            fontSize: '1.4rem',
                                            fontFamily: 'Nunito Sans',
                                            fontWeight: '400'
                                        }
                                    }>Time</TableCell>
                                <TableCell align="center"
                                    style={
                                        {
                                            color: 'black',
                                            fontSize: '1.4rem',
                                            fontFamily: 'Nunito Sans',
                                            fontWeight: '400'
                                        }
                                    }>Practitioners</TableCell>
                                <TableCell align="center"
                                    style={
                                        {
                                            color: 'black',
                                            fontSize: '1.4rem',
                                            fontFamily: 'Nunito Sans',
                                            fontWeight: '400'
                                        }
                                    }>Status</TableCell>
                                <TableCell align="center"
                                    style={
                                        {
                                            color: 'black',
                                            fontSize: '1.4rem',
                                            fontFamily: 'Nunito Sans',
                                            fontWeight: '400'
                                        }
                                    }>Action</TableCell>


                            </TableRow>
                        </TableHead>
                        <TableBody> {
                            rows.map((rowData) => (
                                <TableRow

                                    className='table-row-container'>
                                    <TableCell align='center'
                                        style={
                                            {
                                                color: '#191F4D',
                                                fontSize: '1.1rem',
                                                opacity: '0.7',
                                                fontFamily: 'Nunito Sans',
                                                fontWeight: '400'
                                            }
                                        }>
                                        {
                                            rowData.node.service.name
                                        }</TableCell>
                                    <TableCell align="center"
                                        style={
                                            {
                                                color: '#191F4D',
                                                fontSize: '1.1rem',
                                                opacity: '0.7',
                                                fontFamily: 'Nunito Sans',
                                                fontWeight: '400'
                                            }
                                        }>
                                        {
                                            rowData.node.date
                                        }</TableCell>
                                    <TableCell align="center"
                                        style={
                                            {
                                                color: '#191F4D',
                                                fontSize: '1.1rem',
                                                opacity: '0.7',
                                                fontFamily: 'Nunito Sans',
                                                fontWeight: '400'
                                            }
                                        }>
                                        {
                                            format(new Date(rowData.node.startAt), 'p')
                                        }</TableCell>
                                    <TableCell align="center"
                                        style={
                                            {
                                                color: '#191F4D',
                                                fontSize: '1.1rem',
                                                opacity: '0.7',
                                                fontFamily: 'Nunito Sans',
                                                fontWeight: '400'
                                            }
                                        }>
                                        {
                                            rowData.node.provider.fullName
                                        }</TableCell>
                                    <TableCell align="center"
                                        style={
                                            {
                                                color: '#191F4D',
                                                fontSize: '1.1rem',
                                                opacity: '0.7',
                                                fontFamily: 'Nunito Sans',
                                                fontWeight: '400'
                                            }
                                        }>
                                        {
                                            rowData.node.status
                                        }</TableCell>
                                    <TableCell align="center">
                                        {
                                           
                                           new Date(rowData.node.startAt) < new Date() ? null : (
                                                <CalendarTodayIcon
                                                    onClick={() => {
                                                        openProviderWindow(
                                                            rowData.node.provider.id,
                                                            rowData.node.id,
                                                            rowData.node.service.id,
                                                            rowData.node.date,
                                                            rowData.node.location.id
                                                            

                                                        );
                                                    }}
                                                    style={{
                                                        backgroundColor: "#F2F3F4",
                                                        borderRadius: "5px",
                                                        border: "1px solid",
                                                        borderColor: "gray",
                                                        width: "2.5rem",
                                                        height: "2.5rem",
                                                        padding: "3px",
                                                        cursor: "pointer",
                                                    }}
                                                    fontSize="large"
                                                />
                                            )
                                        }
                                    </TableCell>
                                    <TableCell align="center"
                                        style={
                                            {
                                                color: '#191F4D',
                                                opacity: '0.7',
                                                fontSize: '1.2rem',
                                                fontFamily: 'Nunito Sans',
                                                fontWeight: '400'
                                            }
                                        }></TableCell>

                                </TableRow>
                            ))
                        } </TableBody>
                    </Table>
                </TableContainer>
            </div>


            <div style={
                { height: '5.0rem' }
            }></div>

        </>
    )
}

export default ScheduleDesign
