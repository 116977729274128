import axios from 'axios'
import {React, useEffect, useState} from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, json } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import configJson from "../auth_config.json";



// console.log(data)
const SuccessPayment = () => {
  const {
    isAuthenticated,
    loginWithRedirect,
    user
  } = useAuth0();
const navigate = useNavigate();

  function success(){
    try {
      const token = configJson.STRIPE_TOKEN
      axios.get(
        configJson.STRIPE_PAYMENT_INTENT,
        { headers: { "Authorization": `Bearer ${token}`, 'content-type': 'application/x-www-form-urlencoded' } }
      ).then((res) => {
        if (res.status === 200) {
		localStorage.setItem("paymentSuccess","true")
                  const weightData = {
                    date: new Date(),
                    weight: localStorage.getItem("weight")
                  }
                  localStorage.setItem("weightData","[" + JSON.stringify(weightData) + "]")
                  // alert(user.sub)
                  // setTimeout(xyz(), 5000)
                   

                  navigate("/dashboard")
      }
    });
    } catch (error) {
      console.error('Response:', error);
    }
  }


  

  useEffect(() => {
    success()
  },[]);
  



  return (
    <div>
      <div className='successLoader'>

      </div>
    </div>
    
  )
}

export default SuccessPayment
