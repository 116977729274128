import '../utils/page-css/pagegupOne.css'
import React, { useState, useEffect } from 'react';
import { stringValue } from '../theme/string';
import { AppImage } from '../theme/app.images';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { signupSchema } from '../utils/schemas';
import { NavLink, useNavigate, Navigate } from 'react-router-dom';
import { useFormik } from 'formik';
import {Card, Checkbox } from '@mui/material';
import PublicNavHeader from '../utils/public-header/PublicNavHeader';
import { gql, useMutation } from '@apollo/client'
import configJson from "../auth_config.json";
import AppLoader from '../common/AppLoader';
import TermsOfUse from '../pages/TermsOfUse';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import swal from "sweetalert"
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Tooltip from '@mui/material/Tooltip';
import PasswordChecklist from "react-password-checklist"
// import validator from 'validator'


const SingUpOne = (setIsLogin, props) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [changePassword, setChangePassword] = useState(true);
    const changeIcon = changePassword === true ? false : true;
    const [changeConfPassword, setChangeConfPassword] = useState(true);
    const changeConfIcon = changeConfPassword === true ? false : true;
    // const [newGender, setNewGender] = useState('');
    const [genderValue, setGender] = useState('');
    const [provinceValue, setProvinceValue] = useState('');
    const [medicalidentificationValue, setMedicalidentificationValue] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isTermsHideShow, setIsTermsHideShow] = useState(false);
    const [isPolicyHideShow, setIsPolicyHideShow] = useState(false);
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
    const [isProvinceCheck, setIsProvinceCheck] = useState(false);
    const [isMedicalIdentificationCheck, setIsMedicalIdentificationCheck] = useState(false);
    const [isGenderCheck, setIsGenderCheck] = useState(false);
    const navigate = useNavigate();

    const handleTooltipOpen = () => {
        // if (password === '') { 
        setTooltipOpen(true);
        // }
    };

    const checkLogout = () => {
        let url_string = window.location.href; 
        let url = new URL(url_string);
        let logout = url.searchParams.get("logout");
        
        if (logout == "true") {
            // alert(logout)
            localStorage.clear()
            navigate('/')
        }
    }
 
    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    // const validate = (value) => {
    //     if (validator.isStrongPassword(value, {
    //         minLength: 8, minLowercase: 1,
    //         minUppercase: 1, minNumbers: 1, minSymbols: 1
    //     })) {
    //         setErrorMessage(null);
    //     } else {
    //         setErrorMessage('Password must contain at least 8 characters, including UPPER/lowercase letters, numbers, and symbols.');
    //     }
    // };



    
    if (isAuthenticated == true) {
        navigate('/dashboard')
    }

    useEffect(() => {
        checkLogout()
    }, []);

    // create patient with graphQL
//     const CREATE_PATIENT_MUTATION = gql`
//     mutation CreatePatient($firstName: String!, $lastName: String!, $dateOfBirth: ISO8601Date!,$email: Email,$notifyBy: NotificationMethodEnum! , $homePhone:Phone! ,$city:String! $country: String!,$streetAddress:String!,$region:String!, $gender: GenderEnum!, $medicalNumber: String!,$name: String!,$publicAccessAllowed:Boolean!) {
//       createPatient(input: {
//         firstName: $firstName,
//         lastName: $lastName,
//         dateOfBirth: $dateOfBirth,
//         gender: $gender,
//         city: $city,
//         country: $country,
//         email: $email,
//         homePhone: $homePhone,
//         streetAddress:$streetAddress
//         region:$region
//         notifyBy: $notifyBy
//         publicAccessAllowed:$publicAccessAllowed
//         identifications: [
//           {
//             name:$name
//             value: $medicalNumber
            
//           }
//         ]
//       }) {
//         patient {
//           id
//           firstName
//           lastName
//           dateOfBirth
//           gender
//           email
//           city,
//           country,
//           streetAddress,
//           homePhone
//           region
//           notifyBy
//           publicAccessAllowed
//           identifications {
//             value
//           }
//         }
//       }
//     }
//   `;
//     const [createPatient, { loading, error }] = useMutation(CREATE_PATIENT_MUTATION);
const handleCreatePatient = async () => {
    let response;
    try {
        if (localStorage.getItem("isCreated") === "false"){
         response = await axios.post(configJson.CHR_API_Next + 'api/CreatePatient', {
            firstName: values.firstName,
            lastName: values.lastName,
            dateOfBirth: values.dateOfBirth,
            gender: values.gender,
            email: values.email,
            city: values.city,
            country: values.country,
            streetAddress: values.address,
            homePhone:values.phoneNumber,
            medicalNumber: values.medicalNumber,
            name: values.medicalIdentification,
            region: values.province,
            primaryLocationId : (values.province === "AB") ? 6055 : (values.province === "BC") ? 8931 :(values.province === "ON") ? 6120: "",
            notifyBy: 'BOTH',
            publicAccessAllowed:true
          }, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
  
    //   console.log('Patient created successfully:', response.data);
      localStorage.setItem("Patientid", response.data.createPatient.patient.id);
      // Handle success as needed
    }} catch (error) {
      console.error('Error creating patient:', error.message);
      // Handle error as needed
    }

     try {
                    const AUTH_MANAGEMENT_TOKEN = configJson.AUTH_MANAGEMENT_TOKEN;
                    axios.patch(configJson.AUTH_USER_DETAILS + localStorage.getItem("AuthUserSub"), {
                        "user_metadata": {
                            "Patientid": response.data.createPatient.patient.id
                        }
                    }, {
                        headers: {
                            "Authorization": `Bearer ${AUTH_MANAGEMENT_TOKEN}`
                        }
                    });

                    localStorage.setItem("isCreated", 'true');
                } catch (Patcherror) {
                    swal({
                        title: 'Registration Error',
                        text: Patcherror.response.data,
                        icon: 'error',
                    });
                }
  };
  
  
  // Call the function somewhere in your code
  // handleCreatePatient();
  
    // create patient with graphQL
    const [checked, setChecked] = useState(false);
    const formData = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        dateOfBirth: '',
        gender: '',
        password: '',
        confirmPassword: '',
        address: '',
        city: '',
        state: '',
        country: '',
        province: '',
        medicalIdentification: '',
        medicalNumber: '',
        connection: 'Username-Password-Authentication',
        client_id: configJson.clientId

    };

    function handleChecked(e) {
        setChecked(e.target.checked);
    }
    const APICall = async (values) => {
        try {
            setIsSubmitting(true);
            const patientData = {
                fullName: values.firstName + " " + values.lastName,
                fname: values.firstName,
                lname: values.lastName,
                email: values.email,
                dateOfBirth: values.dateOfBirth,
                phoneNumber: values.phoneNumber,
                address: values.address,
                city: values.city,
                country: values.country,
                province: values.province,
                medicalIdentification: values.medicalIdentification,
                medicalNumber: values.medicalNumber,
                gender: values.gender
            }
            const userData = {
                userData: "'" + JSON.stringify(patientData) + "'",
                Vitals: '{}',
                appointmentID: "",
                questionComplete: "false",
                Patientid: "",
                StripeData: "",
                weightData: ""

            };

            const payloadWithMetaData = {
                connection: values.connection,
                client_id: values.client_id,
                name: values.firstName + " " + values.lastName,
                email: values.email,
                password: values.password,
                user_metadata: userData
            }

            const response = await axios.post(configJson.AUTH_SIGN_UP, payloadWithMetaData);
            if (response.status === 200) {
                localStorage.setItem('user_metadata', JSON.stringify(userData));
                localStorage.setItem('auth0_response', JSON.stringify(response.data));
                // const LocalformData = JSON.parse(localStorage.getItem("user_metadata"));
                localStorage.setItem("isCreated", false)

                await delay(5000);
                Login()
                await delay(5000);
                handleCreatePatient();




                navigate('/weightloss-qnaire');
                // resetForm()
            }

        } catch (e) {
            swal({
                title: 'Registration Error',
                text: 'You have already registered with this email. Kindly use Login',
                icon: 'error',
            });

        } finally {
            setIsSubmitting(false);
        }
    }
    let checkPass = ""
    const {
        values,
        handleBlur,
        handleChange,
        handleSubmit,
        errors,
        touched
    } = useFormik({
        initialValues: formData,
        validationSchema: signupSchema,
        validateOnChange: true,
        validateOnBlur: false,
        // // By disabling validation onChange and onBlur formik will validate on submit.
        onSubmit: (values, action) => {
            localStorage.setItem("userEmail", values.email)
            localStorage.setItem("userPassword", values.password)
            
            APICall(values)
            // // to get rid of all the values after submitting the form
            // action.resetForm();
        }
    });
    if (errors != "undefined" && errors.confirmPassword == "undefined") {
        // setIsPasswordValid(true)
        checkPass = "ok"
    }else if(errors != "undefined" && errors.confirmPassword == "Confirm Password is required" || errors.confirmPassword == "Passwords must match") {
        // setIsPasswordValid(false)
        checkPass = "error"
    }
   


    const Login = async () => {
        try {
            const loginData = {
                grant_type: "password",
                client_id: values.client_id,
                username: values.email,
                password: values.password

            }

            const response = await axios.post(configJson.AUTH_TOKEN_URL, loginData);

            if (response.status === 200) {

                const token = response.data.access_token
                const response2 = await axios.post(configJson.AUTH_USER, {
                    "id_token": response.data.id_token
                }, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });


                localStorage.setItem("AuthUserSub", response2.data.sub)


                localStorage.setItem("access_token", response.data.access_token)
                localStorage.setItem("id_token", response.data.id_token)
                
            }

        } catch (e) { // setErrors({ showError: e.message });
            if (e.response.data.error_description.includes("Existing Customer")) {

                const loginData = {
                    grant_type: "password",
                    client_id: values.client_id,
                    username: values.email,
                    password: values.password

                }
                const response = await axios.post(configJson.AUTH_TOKEN_URL, loginData);

                if (response.status === 200) {

                    const token = response.data.access_token
                    const response2 = await axios.post(configJson.AUTH_USER, {
                        "id_token": response.data.id_token
                    }, {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    });


                    localStorage.setItem("AuthUserSub", response2.data.sub)


                    localStorage.setItem("access_token", response.data.access_token)
                    localStorage.setItem("id_token", response.data.id_token)

                    const STRIPE_TOKEN = configJson.STRIPE_TOKEN

                    axios.get("https://api.stripe.com/v1/customers/search?query=email:" + "'" + values.email + "'", {
                        headers: {
                            "Authorization": `Bearer ${STRIPE_TOKEN}`
                        }
                    }).then(
                        (res) => {
                            localStorage.setItem("stripe_customer_id", res.data.data[0].id)

                            axios.patch(configJson.AUTH_USER_DETAILS + response2.data.sub, {
                                "user_metadata": {
                                    "questionComplete": "true"
                                },
                                "app_metadata": {
                                    stripe_customer_id: res.data.data[0].id
                                }
                            }, {
                                headers: {
                                    "Authorization": `Bearer ${configJson.AUTH_MANAGEMENT_TOKEN}`
                                }
                            })

                        }
                    )


                }
            } else {
                swal({
                    title: 'Registration Error',
                    text: e.response.data,
                    icon: 'error',
                });
            }
        }
    }

    const currentDate = new Date().toISOString().split("T")[0]



    return (
        <>
            <PublicNavHeader></PublicNavHeader>

            <div className={`signup-main-container`}>
                {
                    isTermsHideShow === true && (
                        <TermsOfUse
                            onClick={props.func}

                        />
                    )
                }
                {
                    isPolicyHideShow === true && (
                        <PrivacyPolicy
                            onClick={props.func}
                        />
                    )
                }


                {isSubmitting && <AppLoader />}



                <div className='signup-content-container'>

                    <div className='signup-flex-one-container'>

                        <div>
                            <p className="singup-join-thousand-text-style">{stringValue.RegisterTodayTextValue}</p>
                        </div>

                    </div>
                    <div className='signup-flex-two-container'>


                        <form onSubmit={handleSubmit} autocomplete="off">


                            <div className='signup-form-main-row'>

                                <div className='singup-column-container'>
                                    <p className='singup-form-text-style' >{stringValue.firstName}</p>
                                    <div className='div-input-container'>
                                        <input
                                            className='singup-input-text-style'
                                            type='text' placeholder="First Name"
                                            onChange={handleChange('firstName')}
                                            onBlur={handleBlur('firstName')}
                                            value={values.firstName}

                                            maxLength={35}
                                            error={
                                                !!errors.firstName && !!touched.firstName
                                                    ? true : false
                                            }
                                        />

                                    </div>
                                    {
                                        errors?.firstName && touched?.firstName &&
                                        <p className='signup-error-text-style'>{errors?.firstName}</p>
                                    }

                                </div>
                                <div className='singup-column-container'>
                                    <p className='singup-form-text-style' >{stringValue.lastName}</p>
                                    <div className={'div-input-container'}>
                                        <input
                                            className='singup-input-text-style'
                                            type='text' placeholder="Last Name"
                                            maxLength={35}
                                            onChange={handleChange('lastName')}
                                            onBlur={handleBlur('lastName')}
                                            value={values.lastName}
                                            error={
                                                !!errors.lastName && !!errors.lastName
                                                    ? true : false
                                            } />

                                    </div>
                                    {
                                        errors?.lastName && touched?.lastName &&
                                        <p className='signup-error-text-style'>{errors?.lastName}</p>
                                    }

                                </div>


                            </div>


                            <div className='signup-form-main-row'>

                                <div className='singup-column-container'>
                                    <p className='singup-form-text-style' style={{ marginTop: '25px' }} >{stringValue.emailAddress}</p>
                                    <div className='div-input-container'>
                                        <input
                                            className='singup-input-text-style'
                                            type='email' placeholder="Email address"
                                            maxLength={35}
                                            onChange={handleChange('email')}
                                            onBlur={handleBlur('email')}
                                            value={values.email}
                                            error={
                                                !!errors.email && !!touched.email
                                                    ? true : false
                                            }
                                        />
                                    </div>
                                    {
                                        errors?.email && touched?.email &&
                                        <p className='signup-error-text-style'>{errors?.email}</p>
                                    }

                                </div>
                                <div className='singup-column-container'>
                                    <p className='singup-form-text-style' style={{ marginTop: '25px' }} >{stringValue.phoneNumber}</p>
                                    <div className='div-input-container'>
                                        <input
                                            className='singup-input-text-style'
                                            type='number' placeholder="Phone number"
                                            minLength={10}
                                            onChange={handleChange('phoneNumber')}
                                            onBlur={handleBlur('phoneNumber')}
                                            value={values.phoneNumber}
                                            error={
                                                !!errors.phoneNumber && !!touched.phoneNumber
                                                    ? true : false
                                            }
                                        />

                                    </div>
                                    {
                                        errors?.phoneNumber && touched?.phoneNumber &&
                                        <p className='signup-error-text-style'>{errors?.phoneNumber}</p>
                                    }

                                </div>


                            </div>



                            <div className='signup-form-main-row'>
                                <div className='singup-column-container'>
                                    <p className='singup-form-text-style' style={{ marginTop: '2.5rem' }} >{stringValue.dob}</p>
                                    <div className='div-input-container' style={{ paddingRight: '15px' }}>


                                        <input

                                            
                                            className='singup-input-text-style'
                                            type='date' placeholder="MM/DD/YYYY"
                                            maxLength={4}

                                            onChange={handleChange('dateOfBirth')}
                                            onBlur={handleBlur('dateOfBirth')}
                                            value={values.dateOfBirth}
                                            error={
                                                !!errors.dateOfBirth && !!touched.dateOfBirth
                                                    ? true : false
                                            } />
                                    </div>
                                    {
                                        errors?.dateOfBirth && touched?.dateOfBirth &&
                                        <p className='signup-error-text-style'>{errors?.dateOfBirth}</p>
                                    }

                                </div>
                                <div className='singup-column-container'>
                                    <p className='singup-form-text-style' style={{ marginTop: '2.5rem' }}>{stringValue.gender}</p>
                                    <div className='div-input-container'>
                                        <select
                                            className="form-select shadow-none"
                                            onChange={handleChange('gender')}
                                            onBlur={handleBlur('gender')}
                                            value={values.gender}
                                            style={{
                                                height: "40px",
                                                border: "none", backgroundColor: "transparent",
                                                fontFamily: 'Nunito Sans', fontSize: '1.5rem',
                                            }}>

                                            <option value="" disabled selected>Select gender</option>
                                            <option value='MALE' selected>MALE</option>
                                            <option value='FEMALE' selected>FEMALE</option>
                                            <option value='OTHER' selected>OTHER</option>
                                            {/* Add more options as needed */}
                                        </select>
                                    </div>
                                    {errors?.gender && touched?.gender && <p className='signup-error-text-style'>{errors?.gender}</p>}
                                </div>


                            </div>





                            <div className='signup-form-main-row'>

                                <div className='singup-column-container'>
                                    <p className='singup-form-text-style' style={{ marginTop: '2.5rem' }} >{stringValue.address}</p>
                                    <div className='div-input-container'>
                                        <input
                                            className='singup-input-text-style'
                                            type='text' placeholder="Type your address"
                                            onChange={handleChange('address')}
                                            onBlur={handleBlur('address')}
                                            value={values.address}
                                            maxLength={35}
                                            error={
                                                !!errors.address && !!touched.address
                                                    ? true : false
                                            } />
                                    </div>
                                    {
                                        errors?.address && touched?.address &&
                                        <p className='signup-error-text-style'>{errors?.address}</p>
                                    }

                                </div>
                                <div className='singup-column-container'>
                                    <p className='singup-form-text-style' style={{ marginTop: '2.5rem' }} >{stringValue.city}</p>
                                    <div className='div-input-container'>
                                        <input
                                            className='singup-input-text-style'
                                            type='text' placeholder="Type your city"
                                            onChange={handleChange('city')}
                                            onBlur={handleBlur('city')}
                                            value={values.city}
                                            maxLength={35}
                                        />
                                    </div>
                                    {errors?.city && touched?.city && <p className='signup-error-text-style'>{errors?.city}</p>}
                                </div>


                            </div>


                            <div className='signup-form-main-row'>

                                <div className='singup-column-container'>
                                    <p className='singup-form-text-style' style={{ marginTop: '2.5rem' }} >{stringValue.province}</p>
                                    <div className='div-input-container'>
                                        <select
                                            className="form-select shadow-none"
                                            onChange={handleChange('province')}
                                            onBlur={handleBlur('province')}
                                            value={values.province}
                                            style={{
                                                height: "40px",
                                                border: "none", backgroundColor: "transparent",
                                                fontFamily: 'Nunito Sans', fontSize: '1.5rem',
                                            }}>
                                            <option value="" disabled selected >Select Province</option>
                                            <option value="AB" label="AB" selected>AB</option>
                                            <option value="BC" label="BC" selected >BC</option>
                                            <option value="MB" label="MB" selected >MB</option>
                                            <option value="NB" label="NB" selected >NB</option>
                                            <option value="NS" label="NS" selected >NS</option>
                                            <option value="NL" label="NL" selected >NL</option>
                                            <option value="NT" label="NT" selected >NT</option>
                                            <option value="NU" label="NU" selected >NU</option>
                                            <option value="ON" label="ON" selected >ON</option>
                                            <option value="PE" label="PE" selected >PE</option>
                                            <option value="QC" label="QC" selected >QC</option>
                                            <option value="SK" label="SK" selected >SK</option>
                                            <option value='YT' label='YT' selected >YT</option>
                                            {/* Add more options as needed */}
                                        </select>
                                    </div>
                                    {errors?.province && touched?.province && <p className='signup-error-text-style'>{errors?.province}</p>}


                                </div>
                                <div className='singup-column-container'>
                                    <p className='singup-form-text-style' style={{ marginTop: '2.5rem' }}  >{stringValue.countryText}</p>
                                    <div className='div-input-container'>
                                        <input
                                            className='singup-input-text-style'
                                            type='text' placeholder="Type your country"
                                            onChange={handleChange('country')}
                                            onBlur={handleBlur('country')}
                                            value={values.country}
                                            maxLength={35}
                                            error={
                                                !!errors.country && !!touched.country
                                                    ? true : false
                                            } />
                                    </div>
                                    {
                                        errors?.country && touched?.country &&
                                        <p className='signup-error-text-style'>{errors?.country}</p>
                                    }

                                </div>


                            </div>


                            <div className='signup-form-main-row'>
                                <div className='singup-column-container'>
                                    <p className='singup-form-text-style' style={{ marginTop: '2.5rem' }} >{stringValue.medicalNumber}</p>
                                    <div className='div-input-container' style={{ paddingRight: '15px' }}>
                                        <input
                                            className='singup-input-text-style'
                                            type='text' placeholder="Type medical identification number"
                                            maxLength={10}
                                            onChange={handleChange('medicalNumber')}
                                            onBlur={handleBlur('medicalNumber')}
                                            value={values.medicalNumber}
                                        />
                                    </div>
                                    {errors?.medicalNumber && touched?.medicalNumber && <p className='signup-error-text-style'>{errors?.medicalNumber}</p>}

                                </div>

                                <div className='singup-column-container'>
                                    <p className='singup-form-text-style' style={{ marginTop: '2.5rem' }}  >{stringValue.medicalNumberProvince}</p>
                                    <div className='div-input-container'>
                                        <select
                                            className="form-select shadow-none"
                                            onChange={handleChange('medicalIdentification')}
                                            onBlur={handleBlur('medicalIdentification')}
                                            value={values.medicalIdentification}
                                            style={{
                                                height: "40px",
                                                border: "none", backgroundColor: "transparent",
                                                fontFamily: 'Nunito Sans', fontSize: '1.5rem',
                                            }}>
                                            <option value="" disabled selected >Select Province</option>
                                            <option value="AB" label="AB" selected>AB</option>
                                            <option value="BC" label="BC" selected >BC</option>
                                            <option value="MB" label="MB" selected >MB</option>
                                            <option value="NB" label="NB" selected >NB</option>
                                            <option value="NS" label="NS" selected >NS</option>
                                            <option value="NL" label="NL" selected >NL</option>
                                            <option value="NT" label="NT" selected >NT</option>
                                            <option value="NU" label="NU" selected >NU</option>
                                            <option value="ON" label="ON" selected >ON</option>
                                            <option value="PE" label="PE" selected >PE</option>
                                            <option value="QC" label="QC" selected >QC</option>
                                            <option value="SK" label="SK" selected >SK</option>
                                            <option value='YT' label='YT' selected >YT</option>
                                            {/* Add more options as needed */}
                                        </select>
                                    </div>
                                    {errors?.medicalIdentification && touched?.medicalIdentification && <p className='signup-error-text-style'>{errors?.medicalIdentification}</p>}
                                </div>



                            </div>



                            {/* <div className='eye-open-close'
                                onClick={() => {
                                    setChangePassword(changeIcon);
                                }}
                            >
                                {changeIcon ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
                            </div> */}
                            <div className='signup-form-main-row'>

                                <div className='singup-column-container'>
                                    <p className='singup-form-text-style' style={{ marginTop: '2.5rem' }}>{stringValue.password}</p>
                                    <div className='div-input-passowrd-container' >
                                        <Tooltip title={errorMessage} open={tooltipOpen} arrow><input
                                            className='singup-input-password-text-style'
                                            type={changePassword ? "password" : "text"}
                                            placeholder="Type your password"
                                            onChange={handleChange('password')}
                                            onBlur={handleBlur('password')}
                                            value={values.password}
                                            maxLength={16}
                                            error={
                                                !!errors.password && !!errors.password
                                                    ? true : false
                                            }
                                        /></Tooltip>
                                        <span className='eye-open-close'
                                            onClick={() => {
                                                setChangePassword(changeIcon);
                                            }}
                                        >
                                            {changeIcon ? <RemoveRedEyeIcon
                                                color="disabled"
                                                style={{ marginRight: '1.0rem', width: '2.0rem', height: '2.0rem' }}

                                            /> : <VisibilityOffIcon
                                                color="disabled"
                                                style={{ marginRight: '1.0rem', width: '2.0rem', height: '2.0rem' }}
                                            />}
                                        </span>





                                    </div>
                                    {
                                        errors?.password && touched?.password &&
                                        <p className='signup-error-text-style'>{errors?.password}</p>
                                    }

                                </div>
                                <div className='singup-column-container'>
                                    <p className='singup-form-text-style' style={{ marginTop: '2.5rem' }}>{stringValue.confirmpassword}</p>
                                    <div className='div-input-passowrd-container'>
                                        <span>
                                            <input
                                                className='singup-input-password-text-style'
                                                type={changeConfPassword ? "password" : "text"}
                                                placeholder="Re-type password"
                                                onChange={handleChange('confirmPassword')}
                                                onBlur={handleBlur('confirmPassword')}
                                                value={values.confirmPassword}
                                                maxLength={16}
                                                error={
                                                    !!errors.confirmPassword && !!touched.confirmPassword
                                                        ? true : false
                                                }
                                            />
                                        </span>
                                        <span className='eye-open-close'
                                            onClick={() => {
                                                setChangeConfPassword(changeConfIcon);
                                            }}
                                        >
                                            {changeConfIcon ? <RemoveRedEyeIcon
                                                color="disabled"
                                                style={{ marginRight: '1.0rem', width: '2.0rem', height: '2.0rem', }}

                                            /> : <VisibilityOffIcon
                                                color="disabled"
                                                style={{ marginRight: '1.0rem', width: '2.0rem', height: '2.0rem' }}
                                            />}
                                        </span>


                                    </div>
                                    {
                                        errors?.confirmPassword && touched?.confirmPassword &&
                                        <p className='signup-error-text-style'>{errors?.confirmPassword}</p>
                                    }

                                </div>



                            </div>


                            <div className='password-checklist-container'>
                                {checkPass == "ok" || checkPass == ""  ? ("") : (
          <Card style={{ padding: '2.0rem', marginLeft: '1.5rem', marginRight: '1.5rem' }}>
            <PasswordChecklist
              style={{ alignItems: 'center', alignSelf: 'center', fontSize: '1.5rem' }}
              iconSize={18}
              rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
              minLength={8}
              value={values.password}
              valueAgain={values.confirmPassword}
              messages={{
                minLength: <h4>{"Password Should contains atleast eight characters."}</h4>,
                specialChar: <h4>{"Password should contains atleast a special characters (e.g.()!$#,%)."}</h4>,
                number: <h4>{"Password Should Contains atleast a number."}</h4>,
                capital: <h4>{"Password Should Contains atleast an uppercase letter."}</h4>,
                match: <h4>{"Passwords must match."}</h4>,
              }}
              onChange = {(isValid) => { if (isValid) {
                setIsPasswordValid(true) 
              }else{
                setIsPasswordValid(false) 
              }
            } }
            />
           
          </Card>
        )}

    
                                            </div>
                            {/* <div>
                                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                    <div className='bullets'></div>
                                    <a className='password-instrcution-text-style'>Password Should Contains at least 8 characters, Lower case letters (a-z), Upper case letters (A-Z)</a>

                                </div>
                                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                    <div className='bullets'></div>
                                    <a className='password-instrcution-text-style' >Password Should Contains Numbers (0-9), Non-alphanumeric characters (e.g., !$#,%)</a>

                                </div>
                            </div> */}



                            <div>
                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                    <Checkbox
                                        style={{ marginTop: '1.0rem' }}
                                        checked={checked}
                                        onChange={handleChecked}
                                        size="large"
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />

                                    <div>
                                        <a className='singup-all-text'>All your information is encrypted and stored. By continuing you are confirming that you are, or over the age of 18 and agreeing to the
                                        </a>
                                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                                            <NavLink
                                                onClick={() => { setIsTermsHideShow(!isTermsHideShow) }}
                                                className='singup-term-text-style'>Terms of Use</NavLink>
                                            <span className='singup-and-text-style'>and</span>
                                            <NavLink
                                                onClick={() => { setIsPolicyHideShow(!isPolicyHideShow) }}
                                                className='singup-term-text-style'>Privacy Policy.</NavLink>

                                        </div>
                                    </div>



                                </div>
                            </div>
                            {isSubmitting ? (
                                // Show the Loader component above the overlay when the form is submitting
                                <div></div>
                            ) : (
                                // Show the Signup button when the form is not submitting handleSubmit()
                                <div
                                    onClick={() => { checked === true ? handleSubmit() : console.log("fill filed") }}
                                    className='signup-arrow-button'
                                    style={{ backgroundColor: checked === true ? "#191F4D" : "#8DB9DE" }}
                                >
                                    <a className='signup-btn-text' >Sign Up</a>
                                </div>
                            )}

                            <div style={{ padding: '10px' }}></div>

                        </form>



                    </div>


                </div>


            </div>
        </>
    )
}
export default SingUpOne;
