import React, { useEffect, useMemo, useState } from "react";
import { Outlet, Navigate } from 'react-router-dom'
import {useAuth0} from '@auth0/auth0-react';
import AppLoader from '../common/AppLoader';
const Dashboard = () => {

    const {isAuthenticated, getAccessTokenSilently, user,loginWithRedirect,
        getIdTokenClaims} = useAuth0();
    // alert(isAuthenticated)
    useEffect(() => {
        if (isAuthenticated) {
          getIdTokenClaims().then((claims) => {
            console.log('Claims:', claims.__raw);
            localStorage.setItem("id_token", claims.__raw);
          });
        }
      }, []);
    return (
        <div className="health-main-container">
            {/* <PublicNavHeader></PublicNavHeader> */}
            {
                isAuthenticated ? (<Navigate to="/dashboard" />) : (<AppLoader />)
            }
            
        </div>
    )
}
export default Dashboard;
