
import { title } from 'process';
import React, { useState } from 'react';
import { useCollapse } from 'react-collapsed';
import '../../src/utils/page-css/pagegupOne.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { GlobalThemeColor } from '../theme/color';

const SidebarItems = (props) => {
    const { isExpanded, onToggle } = props;
  
    return (
      <div className="faq-collapsible">
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <div
            className="faq-collapsible-header"
            onClick={onToggle}
          >
            {props.title}
          </div>
          {isExpanded ? (
            <KeyboardArrowUpIcon
              onClick={onToggle}
              style={{ marginLeft: '1.4rem' }}
              sx={{ fontSize: 30 }}
            />
          ) : (
            <KeyboardArrowDownIcon
              onClick={onToggle}
              style={{ marginLeft: '1.4rem' }}
              sx={{ fontSize: 30 }}
            />
          )}
        </div>
        {isExpanded && (
          <div className="faq-collapsible-content">
            {props.children}
          </div>
        )}
        <div
          style={{
            height: '1px',
            marginTop: '1.2rem',
            backgroundColor: GlobalThemeColor.appColor.bgColor,
            width: '98%',
          }}
        ></div>
      </div>
    );
  };
  
  export default SidebarItems;