import * as Yup from 'yup';
const validateEmail = (val) => {
    return val.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
const validatePassword = (val) => {
    return val.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.{8,})/
    );
};

export const signupSchema = Yup.object({
    firstName: Yup.string().required('Please enter your first name'),
    lastName: Yup.string().required('Please enter your last name'),
    email: Yup.string().required('Please enter your email').test({
        message: 'Please enter valid email address',
        test: (value) => {
            if (value === undefined || value === null) {
                return false;
            }
            if (validateEmail(value)) {
                return true;
            }
            else {
                return false;
            }
        }
    }),
    phoneNumber: Yup.string().required('Phone Number is required').test({
        message: 'Please enter valid phone number with minimum 10 character',
        test: (value) => {
            if (value === undefined || value === null) {
                return false;
            }
            if (value && value?.length >= 10) {

                return true;
            }
            return false;


        }
    }),
    dateOfBirth: Yup.string()
    .required('Date of Birth is required')
    .test('valid-age', 'You must be at least 18 years old to register ', function (value) {
      // Assuming the date format is 'YYYY-MM-DD'
      const birthDate = new Date(value);
      const currentDate = new Date();
      const age = currentDate.getFullYear() - birthDate.getFullYear();

      // Adjust the age calculation based on the birthdate month and day
      if (
        currentDate.getMonth() < birthDate.getMonth() ||
        (currentDate.getMonth() === birthDate.getMonth() &&
          currentDate.getDate() < birthDate.getDate())
      ) {
        // Subtract 1 year if the birthday hasn't occurred yet
        return age - 1 > 18;
      }

      return age >= 18;
    }),
    // gender: Yup.string().required('Gender is required'),
    password: Yup.string().required('Password is required').test({
        message: 'Please enter valid password',
        test: (value) => {
            if (value === undefined || value === null) {
                return false;

            }
            if (validatePassword(value)) {
                return true;
            }

            else {
                return false;
            }
        }
    }),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required'),

    address: Yup.string().required('Address is required'),
    city: Yup.string().required('City is required'),
    gender: Yup.string().required('Gender is required'),
    country: Yup.string().required('Country is required'),
    city: Yup.string().required('City is required'),
    province: Yup.string().required('Province is required'),
    medicalIdentification: Yup.string().required('Health number province is required'),
    medicalNumber: Yup.string().required('Medical Number is required'),
})

export const questionSchema = Yup.object(
    {
        heightFeet: Yup.string().required('Height is required in form of feet'),
        heightInches: Yup.string().required('Height is required in form of inches'),
        weight: Yup.string().required('Weight is required in form of lbs'),
        // breastfeeding: Yup.string().required('Please check breastfeeding or typing to concieve'),
        // familyDoctor: Yup.string().required('Please check family medical doctor ')
    }
)
