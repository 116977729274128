import React, { useEffect } from 'react';
import { AppImage } from '../../theme/app.images';
import '../page-css/pagelayout.css';
import { NavLink } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

const PublicNavHeader = () => {

  
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    getIdTokenClaims,
    logout
  } = useAuth0();

  useEffect(() => {
    if (isAuthenticated && loginWithRedirect()) {
      getIdTokenClaims().then((claims) => {
        console.log('Claims:', claims.__raw);
        localStorage.setItem("id_token", claims.__raw);
      });
    }
  }, []);


  return (
    <header className='public-header-container'>
      <NavLink to={'https://pepstep.ca/'} style={{ width: "224px" }}>
        <img src={AppImage.headerLogoImage} alt='logo' className='header-img-size' />
      </NavLink>

      {!user && <a className={"login-btns"} onClick={() => loginWithRedirect({appState:{
        returnTo: window.location.origin + "/dashboard2"
      }})}>Login</a>}
    
    </header>
  );
};

export default PublicNavHeader;
