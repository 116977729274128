import React, { useState, useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import '../../src/utils/page-css/pagegupOne.css';
import { stringValue } from '../theme/string';
import DashboardNavBar from "../utils/private-header/DashboardNavBar";
import { AppImage } from '../theme/app.images';
import axios from 'axios'
import configJson from "../auth_config.json";
import { Checkbox } from '@mui/material';
// import {format} from 'date-fns';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import swal from "sweetalert"


const Account = () => {




  const user_metadata = localStorage.getItem('user_metadata');
  const data = JSON.parse(user_metadata)
  const re = /'/gi;
  const usData = data.userData.replace(re, "")
  const data2 = JSON.parse(usData)
  const Patientid = localStorage.getItem("Patientid")
  // console.log("data2: " + JSON.stringify(data2))

  const { fname,lname, email, phoneNumber, dateOfBirth, address, gender, country, city, province } = data2

  
  

  // console.log(firstName)
  // console.log(lastName)


  const [showHidePass, setShowHidePass] = useState(false);
  const [firstNames, setFirstName] = useState(fname);
  const [lastNames, setLastName] = useState(lname);
  const [emails, setEmail] = useState(email);
  const [phoneNumbers, setPhoneNumber] = useState(phoneNumber);
  const [dateOfBirths, setDateOfBirth] = useState(dateOfBirth);
  const [genders, setGender] = useState(gender);
  const [addresss, setAddress] = useState(address);
  const [citys, setCity] = useState(city);
  const [countrys, setCountry] = useState(country);
  const [passowrd, setPassword] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [showHidePassOne, setShowHidePassOne] = useState(false);
  const [responseModalOpen, setResponseModalOpen] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [subscriptionDetail, setSubscriptionDetail] = useState(null);
  const [showSubscriptions, setShowSubscriptions] = useState(false);
  const [showCancelSubscriptions, setShowCancelSubscriptions] = useState(false);
  const [showHideSubscription, setShowHideSubscription] = useState(false);
  const [showHidePayment, setShowHidePayment] = useState(false);
  const [checked, setChecked] = useState(false);
  const [customer, setCustomer] = useState("")
  const [productData, setProductData] = useState(null);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [provinces, setProvince] = useState(province);








  function handleChecked(e) {
    setChecked(e.target.checked);
  }

  const fetchSubscriptionId = () => {


    let customer = localStorage.getItem("stripe_customer_id");
    const subscriptionItems = localStorage.getItem("PriceId");
    // console.log("subscriptionItems : " + subscriptionItems)
    const token = configJson.STRIPE_TOKEN;
    // console.log(`https://api.stripe.com/v1/subscriptions?customer=${customer}`)
    axios.get(`https://api.stripe.com/v1/subscriptions?customer=${customer}`, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (response.data.data[0] != null) {
          setSubscriptionDetail(response.data.data[0]); // Save the response data in state
          // openModal(); // Open the response modal
          setSubscriptionId(response.data.data[0].id)
        } else {
          setSubscriptionDetail({ status: "Unsubscribed", current_period_start: "", current_period_end: "", id: "" });
        }
        // console.log("Price", response.data.data)

        // console.log('Subscription id:', response.data.data[0].id);
        // Handle successful subscription creation here, e.g., show a success message.
      })
      .catch((error) => {
        console.error('Subscription creation error:', error.response.data);
        alert("giv error from fetchSubscriptionId", error.response.data)
        // Handle subscription creation error here, e.g., show an error message.
      });


  };

  // console.log("543224567654356", customer)


  const handlepaymentUpdate = async () => {
    let customer = localStorage.getItem("stripe_customer_id");
    const formData = {
      cancel_url: window.location.protocol + '//' + window.location.host + '/account?response=failure',
      success_url: window.location.protocol + '//' + window.location.host + '/account?response=Success',
      payment_method_types: [
        "card"
      ],
      mode: "setup",

      customer: customer

    };

    try {
      const token = configJson.STRIPE_TOKEN;
      const response = await axios.post(configJson.STRIPE_SESSION, formData, {
        headers: {
          "Authorization": `Bearer ${token}`,
          'content-type': 'application/x-www-form-urlencoded'
        }
      }).then((response) => {
        window.location.href = response.data.url;
       

      })


    } catch (error) {
      

    }
  };


  const fetchPaymentMethods = async () => {
    let customer = localStorage.getItem('stripe_customer_id');
    const token = configJson.STRIPE_TOKEN;

    try {
      const response = await axios.get(
        `https://api.stripe.com/v1/customers/${customer}/payment_methods?type=card`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPaymentMethods(response.data.data[0].id)
      // console.log("PaymentMethod", response.data.data[0])
      // console.log("MethodId", response.data.data[0].id)
      // Do whatever you want to do with the payment methods data here
    } catch (error) {
      // Handle error, e.g., show an error message
      console.error('Error fetching payment methods:', error);
    }
  };

  async function fetchProductData() {
    try {
      // Replace 'YOUR_STRIPE_SECRET_KEY' with your actual Stripe Secret Key
      const token = configJson.STRIPE_TOKEN;
      const response = await axios.get('https://api.stripe.com/v1/products', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },


      });


      // Assuming the response contains an array of products, you can set the first product in the state
      if (response.data && response.data.data && response.data.data.length > 0) {
        // console.log("details all", response.data.data)
        setProductData(response.data.data[0]);

      }
    } catch (error) {
      console.error('Error fetching product data:', error);
    }
  }




  useEffect(() => {
    fetchProductData();
    fetchPaymentMethods();
    fetchSubscriptionId();
  }, []);

  const buySubscription = async () => {
    let customer = localStorage.getItem('stripe_customer_id');
    const formData = {
      cancel_url: window.location.protocol + '//' + window.location.host + '/account?response=failure',
      success_url: window.location.protocol + '//' + window.location.host + '/account?response=Success',
      line_items: [
        {
          price: productData.default_price,
          quantity: 1,
        },
      ],
      mode: "subscription",
      automatic_tax: {
        enabled: true,
      },
      allow_promotion_codes: true,
      customer: customer,
      subscription_data: {
        trial_period_days: 60,
      },
    
      customer_update: {
        address: "auto",
      },
      billing_address_collection: "auto",
    };

    try {
      const token = configJson.STRIPE_TOKEN;
      const response = await axios.post(configJson.STRIPE_SESSION, formData, {
        headers: {
          "Authorization": `Bearer ${token}`,
          'content-type': 'application/x-www-form-urlencoded'
        }
      });

      if (response.status === 200) {
        // Redirect to the payment page
        window.location.href = response.data.url;

      }
    } catch (error) {
      console.error('Payment Error:', error.response.data);
      // Handle payment error here (show an error message to the user, etc.)
    }
  };








  const handleCancelSubscription = () => {

    const token = configJson.STRIPE_TOKEN;
    // console.log( "yyyyyy" +  ``)
    axios.delete(`https://api.stripe.com/v1/subscriptions/${subscriptionId}`, {
      headers: {
        "Authorization": `Bearer ${token}`,

      },
    })
      .then((response) => {
        // console.log('Subscription canceled:', response.data);
        setSubscriptionDetail({ status: "Cancelled" }); // Save the response data in state
        // Handle successful subscription cancellation here, e.g., show a success message.
      })
      .catch((error) => {
        console.error('Subscription cancellation error:', error.response.data);

        // Handle subscription cancellation error here, e.g., show an error message.
      });
  };










  const handleGender = (event) => {
    setGender(event.target.value)
    // console.log('gender selecert' + event.target.value);
  }


  const genderOptions = [
    { value: '', label: 'Select Gender' },
    {
      value: 'MALE',
      label: 'MALE'
    }, {
      value: 'FEMALE',
      label: 'FEMALE'
    }, {
      value: 'OTHER',
      label: 'OTHER'
    },
  ];
  const provinceOptions = [
    { value: '', label: 'Select Province' },
    {
      value: 'AB',
      label: 'AB'
    },
    {
      value: 'BC',
      label: 'BC'
    },
    {
      value: 'MB',
      label: 'MB'
    },
    {
      value: 'NB',
      label: 'NB'
    }, {
      value: 'NL',
      label: 'NL'
    }, {
      value: 'NS',
      label: 'NS'
    }, {
      value: 'NT',
      label: 'NT'
    }, {
      value: 'NU',
      label: 'NU'
    }, {
      value: 'ON',
      label: 'ON'
    }, {
      value: 'PE',
      label: 'PE'
    }, {
      value: 'QC',
      label: 'QC'
    }, {
      value: 'SK',
      label: 'SK'
    }, {
      value: 'YT',
      label: 'YT'
    },
  ];



  







  // Function to handle the update when "Save" button is clicked.
  const handleSave = async () => {
    const userId=localStorage.getItem("AuthUserSub")
    const locationId=localStorage.getItem("locationId")
   
    try {
      const response =  await axios.post(configJson.CHR_API_Next +'api/updateAccount', {
        userId:userId,
        firstName: firstNames,
        lastName: lastNames,
        dateOfBirth: dateOfBirths,
        gender: genders,
        city: citys,
        country: countrys,
        email: emails,
        homePhone: phoneNumbers,
        streetAddress: addresss,
        region: provinces,
        notifyBy: 'BOTH',
        publicAccessAllowed:true,
        primaryLocationId:locationId,

      });
      if (response.status >= 200 && response.status < 300) {
        swal('Success', 'Changes have been Saved!', 'success');
    } else {
        swal('Error', 'Failed to save changes', 'error');
    }
    } catch (error) {
      console.error('Error Updating patient:', error.message);
      swal('Error', 'Failed to save changes', 'error');
  }


    //updating Auth Meta Data


    const patientData = {
      fullName: firstNames + " " + lastNames,
      fname: firstNames,
      lname: lastNames,
      email: emails,
      dateOfBirth: dateOfBirths,
      phoneNumber: phoneNumbers,
      address: addresss,
      gender: genders,
      city: citys,
      county: data2.county,
      state: data2.state,
      country: countrys,
      province: provinces,
      medicalIdentification: data2.medicalIdentification,
      medicalNumber: data2.medicalNumber,
      appointmentID: data2.appointmentID,
      questionComplete: data2.questionComplete,
      Patientid: data2.Patientid,
    }

    // console.log("Gaurav666", JSON.stringify(patientData))
    const token = configJson.AUTH_MANAGEMENT_TOKEN
    axios.patch(configJson.AUTH_USER_DETAILS + localStorage.getItem("AuthUserSub"), {
      "name": firstNames + " " + lastNames,
      "email": emails,
      "user_metadata": {
        "userData": "'" + JSON.stringify(patientData) + "'"
      }
    }, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
    // debugger
    let user_metadata_old = JSON.parse(localStorage.getItem("user_metadata"))
    const re = /'/gi;
    const usData = user_metadata_old.userData.replace(re, "")
    let user_metadata = JSON.parse(usData)

    // alert(user_metadata.fullName)

    let update_user_metadata = {
      "fullName": firstNames + " " + lastNames,
      "fname" : firstNames,
      "lname" : lastNames,
      "email": emails,
      "dateOfBirth": dateOfBirths,
      "phoneNumber": phoneNumbers,
      "address": addresss,
      "gender": genders,
      "city": citys,
      "country": countrys,
      "province": provinces,



    }

    const userData = {
      userData: "'" + JSON.stringify(update_user_metadata) + "'",
      Vitals: user_metadata_old.Vitals,
      appointmentID: user_metadata_old.appointmentID,
      questionComplete: user_metadata_old.questionComplete,
      Patientid: user_metadata_old.Patientid,
      StripeData: user_metadata_old.StripeData,
      weightData: user_metadata_old.weightData

    };
    // setFirstName()
    // setLastName()
    // setEmail()
    // setPhoneNumber()
    // setDateOfBirth()
    // setGender()
    // setAddress()
    // setCity()
    // setCountry()



    localStorage.setItem("user_metadata", JSON.stringify(userData))







  };
  const [ResetPassword, setResetPassword] = useState()
  const handleResetPassword = () => {

    const payload = {
      "client_id": configJson.clientId,
      "email": emails,
      "connection": "Username-Password-Authentication"
    }
    axios.post("https://" + configJson.domain + "/dbconnections/change_password", payload).then(
      function (res) {
        // console.log(res)
        setResetPassword(res.data)
        setShowHidePassOne(!showHidePassOne)
      }
    )
  }




  return (
    <>
      <DashboardNavBar></DashboardNavBar>




      <div>

        {
          showHidePassOne === true && (
            <div className='zIndex-alert-container'>
              <div className='zIndex-alert-container-bg'>
                <div className='alert-dialog-row-space-between'>

                  <a></a>
                  <a className='reset-password-text-style'>Password Status</a>
                  <img
                    className='close-icon-img' style={{ marginRight: '-1.5rem' }}
                    onClick={() => { setShowHidePassOne(false) }}
                    src={AppImage.closeIcon} />


                </div>
                <div style={{ width: '100%', flexDirection: 'column', paddingLeft: '3.0rem', paddingRight: '3.0rem' }}>
                  <center><p className='reset-password-sub-text-style'> {ResetPassword} </p></center>
                </div>
              </div>
            </div>
          )
        } </div>
      <div>
        {
          showHideSubscription === true ? (
            subscriptionDetail.status == "Unsubscribed" || subscriptionDetail.status == "Cancelled" ? (<div className='zIndex-subscription-container'>
              <div className='zIndex-sub-container-bg'>
                <div className='alert-dialog-row-space-between'>
                  <a></a>
                  <a className='reset-password-text-style'>Subscription Details</a>
                  <img
                    className='close-icon-img' style={{ marginRight: '-1.5rem' }}
                    onClick={() => { setShowHideSubscription(false) }}
                    src={AppImage.closeIcon} />
                </div>
                <div style={{ width: '100%', flexDirection: 'column', paddingLeft: '3.0rem', paddingRight: '3.0rem' }}>

                  <center><h3> No Subscription Found!! </h3></center>
                  <div className='alert-dailog-green-border-container' style={{
                    border: "none",
                    backgroundColor: '#4F7942',
                    marginTop: '3.0rem'
                  }}>
                    <img src={AppImage.cancelSubscriptionImage}

                      className='icon-size-note' />
                    <p onClick={() => { buySubscription() }}

                      className='update-pass-text-style' style={{ marginTop: '1.4rem', color: 'white' }} >Buy Subscription</p>
                  </div>


                </div>

              </div>

            </div>) : (<div className='zIndex-subscription-container'>
              <div className='zIndex-sub-container-bg'>
                <div className='alert-dialog-row-space-between'>
                  <a></a>
                  <a className='reset-password-text-style'>Subscription Details</a>
                  <img
                    className='close-icon-img' style={{ marginRight: '-1.5rem' }}
                    onClick={() => { setShowHideSubscription(false) }}
                    src={AppImage.closeIcon} />
                </div>
                <div style={{ width: '100%', flexDirection: 'column', paddingLeft: '2.0rem', paddingRight: '2.0rem' }}>

                  <div class="table-responsive">
                    <table class="table bdrremove">
                      <tbody style={{ border: 'none' }}>
                        <tr>
                          <td className="update-form-text-style" style={{ color: 'lightgray' }}>Id:</td>
                          <td className="subscription-text-style">{subscriptionDetail.id}</td>
                          <td className="update-form-text-style" style={{ color: 'lightgray' }}>Start Date:</td>
                          <td className="subscription-text-style">{new Date(subscriptionDetail.current_period_start * 1000).toISOString().split('T')[0]}</td>


                        </tr>
                        <tr>
                          <td className="update-form-text-style" style={{ color: 'lightgray' }}>End Date:</td>
                          <td class="subscription-text-style">{new Date(subscriptionDetail.current_period_end * 1000).toISOString().split('T')[0]}</td>
                          <td className="update-form-text-style" style={{ color: 'lightgray' }}>Status:</td>
                          <td className="subscription-text-style">{subscriptionDetail.status}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {/* <div className='subscription-sub-row-container'>
                    <div style={{flexDirection:'row', display:'flex'}}>
                    <p className='update-form-text-style' style={{ color: 'lightgray', }}>Start Date:</p>
                    <p className='subscription-text-style'>{new Date(subscriptionDetail.current_period_start * 1000).toISOString().split('T')[0]}</p>
                    </div>
                    <div style={{flexDirection:'row', display:'flex'}}>
                    <p className='update-form-text-style' style={{ color: 'lightgray',}}>End Date:</p>
                    <p className='subscription-text-style'>{new Date(subscriptionDetail.current_period_end * 1000).toISOString().split('T')[0]}</p>
                    </div>
                    
                  </div>
                  <div className='subscription-sub-row-container'>
                  <div style={{flexDirection:'row', display:'flex'}}>
                  <p className='update-form-text-style' style={{ color: 'lightgray',}}>Status:</p>
                    <p className='subscription-text-style' style={{paddingLeft:'1.7rem'}}>{subscriptionDetail.status}</p>
                  </div>
                  <div style={{flexDirection:'row', display:'flex'}}>
                  <p className='update-form-text-style' style={{ color: 'lightgray',marginLeft:'1.7rem'}}>Id:</p>
                    <p className='subscription-text-style'>{subscriptionDetail.id}</p>
                  </div>
                   
                  </div> */}

                  <div>
                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        style={{ marginTop: '0.0rem' }}
                        checked={checked}
                        onChange={handleChecked}
                        size="large"
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      <a className='check-cancel-sub-text' style={{ marginTop: '-0.2rem' }}>If you want to cancel your subscription! Please check here.</a>

                    </div>
                  </div>
                  <div className='alert-dailog-cancel-sub-container' style={{

                    border: "none",
                    backgroundColor: checked === true ? '#EE374D' : '#ec6a79dc',

                    marginTop: '3.0rem'
                  }}>
                    <img src={AppImage.cancelSubscriptionImage}

                      className='icon-size-note' />
                    <p onClick={checked === true ? handleCancelSubscription : null}

                      className='update-pass-text-style' style={{ marginTop: '1.4rem', color: 'white' }} >Cancel Subscriptions</p>
                  </div>



                </div>

              </div>

            </div>)

          ) : (
            <div></div>
          )
        }



      </div>
      <div>
      </div>



      <div className='profile-card-column'>


        <div className="profile-card">
          <div>
            <div className='profile-img-name-icon-column'>
              {
                gender === 'MALE' ? (
                  <img src={AppImage.avatarImage} style={{ width: '128px', height: '128px' }} />
                ) : gender === 'OTHER' ? (
                  <img src={AppImage.otherGenderImage} style={{ width: '128px', height: '128px' }} />
                ) : (
                  <img src={AppImage.avatarWomenImage} style={{ width: '128px', height: '128px' }} />
                )
              }
              <p className='update-text-style-one'>{firstNames + " " + lastNames}</p>
              <div className='profile-row-with-email-icon'>
                <p className='update-text-style-two'>{email}</p>
                <div className='update-icon-row-column'>
                  <div className='alert-dailog-green-border-container' style={{ border: "none", backgroundColor: '#8DB9DE' }}>
                    <img src={AppImage.closeEyeImage}

                      className='icon-size-note' />
                    <p
                      onClick={() => { handleResetPassword() }}
                      className='update-pass-text-style' style={{ color: 'white' }} >Update password</p>
                  </div>
                  <div className='alert-dailog-green-border-container' style={{ border: "none", backgroundColor: '#191f4d' }}>
                    <img
                      className='icon-size-note'
                      src={AppImage.subscriptionImage}

                    />
                    <p
                      onClick={() => { setShowHideSubscription(!showHideSubscription) }}
                      className='update-pass-text-style' style={{ marginTop: '0px', color: 'white' }} >Subscriptions</p>
                  </div>
                  <div className='alert-dailog-green-border-container' style={{ border: "none", backgroundColor: '#EE374D' }}>
                    <img src={AppImage.paymentImage}

                      className='icon-size-note' />
                    <p
                      onClick={() => { handlepaymentUpdate() }}
                      className='update-pass-text-style' style={{ marginTop: '0px', color: 'white' }} >Update Payment Method</p>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ height: '4.0rem' }}></div>
            <p className="update-text-style">General Information</p>



            <div className='update-form-main-row '>

              <div style={{ flexDirection: 'column', width: '48%', display: 'flex', marginTop: '2.0rem' }}>
                <p className='singup-form-text-style' >{stringValue.firstName}</p>
                <input

                  className='div-update-input-2'
                  type='text' placeholder="First Name"
                  value={firstNames}
                  onChange={(event) => setFirstName(event.target.value)}
                  maxLength={35}

                />
              </div>
              <div style={{ flexDirection: 'column', width: '48%', display: 'flex', marginTop: '2.0rem', marginRight: '2.0rem' }}>
                <p className='singup-form-text-style'>Last Name</p>
                <input

                  name='lastName'
                  className='div-update-input-2'
                  type='text' placeholder="Last Name"
                  value={lastNames}
                  onChange={(event) => setLastName(event.target.value)}
                  maxLength={35}

                />
              </div>





            </div>

            <div className='update-form-main-row '>
              <div style={{ flexDirection: 'column', width: '48%', display: 'flex', marginTop: '0.5rem' }}>
                <p className='update-form-text-style'>{stringValue.emailAddress}</p>
                <input

                  className='div-update-input-2'
                  type='email' placeholder="Email address"
                  value={emails}
                  onChange={(event) => setEmail(event.target.value)}
                  maxLength={35}

                />
              </div>
              <div style={{ flexDirection: 'column', width: '48%', display: 'flex', marginTop: '0.5rem', marginRight: '2.0rem' }}>
                <p className='update-form-text-style'>Phone Number</p>
                <input

                  className='div-update-input-2'
                  type='phone' placeholder="Phone number"
                  value={phoneNumbers}
                  minLength={10}
                  onChange={(event) => setPhoneNumber(event.target.value)}
                  

                />
              </div>





            </div>

            <div className='update-form-main-row '>
              <div style={{ flexDirection: 'column', width: '48%', display: 'flex', marginTop: '0.5rem' }}>
                <p className='update-form-text-style'>{stringValue.dob}</p>
                <input
                  disabled
                  name="myDate"
                  max="2023-12-31"
                  className='div-update-input-container'
                  type='date' placeholder="dd/MM/YYYY"
                  maxLength={4}
                  value={dateOfBirths}
                  onChange={(event) => setDateOfBirth(event.target.value)}
                  style={{ paddingRight: '1.0rem' }}

                />

              </div>
              <div style={{ flexDirection: 'column', width: '48%', display: 'flex', marginTop: '0.5rem', marginRight: '2.0rem' }}>
                <p className='update-form-text-style'>Gender</p>
                <div className='div-update-input-container'>
                  <select className="form-select  shadow-none "

                    onChange={handleGender}
                    value={gender}
                    disabled
                    style={{
                      height: "40px",
                      border: "none", backgroundColor: "transparent",
                      fontFamily: 'Nunito Sans', fontSize: '1.5rem',

                    }}>
                    {
                      genderOptions.map((e) => (
                        <option value={e.value}>{e.label}</option>
                      ))
                    }
                  </select>
                </div>

              </div>





            </div>


            <div className='update-form-main-row '>
              <div style={{ flexDirection: 'column', width: '48%', display: 'flex', marginTop: '0.5rem', }}>
                <p className='update-form-text-style'>{stringValue.address}</p>
                <input
                  className='div-update-input-2'
                  type='text' placeholder="Type your address"
                  maxLength={35}
                  value={addresss}
                  onChange={(event) => setAddress(event.target.value)}

                />

              </div>
              <div style={{ flexDirection: 'column', width: '48%', display: 'flex', marginTop: '0.5rem', marginRight: '2.0rem' }}>
                <p className='update-form-text-style'>City</p>
                <input
                  className='div-update-input-2'
                  type='text' placeholder="Type your city"
                  maxLength={35}
                  value={citys}
                  onChange={(event) => setCity(event.target.value)}

                />
              </div>
            </div>


            <div className='update-form-main-row '>
              <div style={{ flexDirection: 'column', width: '48%', display: 'flex', marginTop: '0.5rem', }}>
                <p className='update-form-text-style'>Province</p>
                <div className='div-update-input-2'>
                  <select className="form-select  shadow-none"

                    onChange={(event) => setProvince(event.target.value)}
                    value={provinces}
                    style={{
                      backgroundColor: 'transparent', border: 'none',
                      fontFamily: 'Nunito Sans', fontSize: '1.5rem',
                    }}

                  >
                    {
                      provinceOptions.map((e) => (
                        <option value={e.value} selected={e.value == '' ? 'selected' : ''} disabled={e.value == '' ? 'disabled' : ''} >{e.label}</option>
                      ))
                    }
                  </select>
                </div>

              </div>
              <div style={{ flexDirection: 'column', width: '48%', display: 'flex', marginTop: '0.5rem', marginRight: '2.0rem' }}>
                <p className='update-form-text-style'>{stringValue.countryText}</p>
                <input
                  className='div-update-input-2'
                  type='text' placeholder="Type your country"
                  maxLength={35}
                  value={countrys}
                  onChange={(event) => setCountry(event.target.value)}

                />

              </div>






            </div>




            <a className='singup-term-policy-text'>To protect your identity, if you need to update your date of birth or gender please contact<br />

            </a>
            <a
              href='https://pepstep.acedev.info/contact'
              style={{
                color: '#8DB9DE', marginLeft: '1.0rem', fontSize: '1.5rem',
                fontWeight: 400, fontFamily: 'Nunito Sans', textDecoration: 'underline',
                cursor: 'pointer'
              }}>customer support.</a>

            {
              showHidePass === true && (
                <div>
                  <p className='update-form-text-style'>{stringValue.password}</p>
                  <div className='update-form-main-row '>

                    <input
                      className='div-update-input-container'
                      type='password' placeholder="Type your new password"
                      maxLength={16}

                    />
                    <input
                      className='div-update-input-container'
                      type='password' placeholder="Re-type new confirm passowrd"
                      maxLength={16}

                    />


                  </div>
                </div>

              )
            }
            <div className='update-row-button-container'>
              <div className='update-button-bg' style={{ justifyContent: "center" }} onClick={handleSave} >
                Save

              </div>

            </div>
          </div>

        </div>
      </div>




    </>
  );
};

export default Account;
