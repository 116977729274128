import React, { useEffect, useMemo, useState } from "react";
import DashboardNavBar from "../utils/private-header/DashboardNavBar";


import '../../src/utils/page-css/pagegupOne.css';
import { AppImage } from '../theme/app.images';

import ScheduleDesign from "./ScheduleDesign";

import Track from "./Track"
import Footer from "../utils/footer/Footer";
import { useNavigate } from 'react-router-dom';

import ReSubscribe from "./ReSubscribe";
import configJson from "../auth_config.json";
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react';
import swal from 'sweetalert';
import FAQ from "./FAQ";
import AppLoader from '../common/AppLoader';


const Dashboard = () => {


    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('schedule');
    
    const [shedules, setShedules] = useState("shedule check")
    const [messages, setMessages] = useState("message check")
    const [tracks, setTracks] = useState("")
    const [faqs, setFaqs] = useState("")
    const [stripe_customer_id, setstripe_customer_id] = useState("")
    const [CHECKAPPOINTMENT, setCHECKAPPOINTMENT] = useState();
    const [MetaData, setMetaData] = useState({appointmentID: ""});
    const {isAuthenticated, getAccessTokenSilently, user, getIdTokenClaims} = useAuth0();
    const [SubscriptionDetail, setSubscriptionDetail] = useState(
        {status: "",current_period_start: "" , current_period_end: "", id:""}
    )
    async function CheckRedirect() {

        // console.log("isAuthenticated:" + isAuthenticated)
        // console.log("user:" + JSON.stringify(user))
        


        if (isAuthenticated == true) { // console.log("user Details:" + user)
            localStorage.setItem("AuthUserSub", user.sub)
            if (localStorage.getItem("paymentSuccess")) {
                if (localStorage.getItem("paymentSuccess") == "true") {
                    // alert("isAuthenticated:", isAuthenticated)
                    const token = configJson.AUTH_MANAGEMENT_TOKEN
                    axios.patch(configJson.AUTH_USER_DETAILS + user.sub, {
                        "user_metadata": {
                            "questionComplete": "true",
                            "weightData": "'" + localStorage.getItem("weightData") + "'"
                        }

                    }, {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    })
                    localStorage.removeItem("paymentSuccess")
                }
            }
            const token = configJson.AUTH_MANAGEMENT_TOKEN

            let sub = user.sub
            async function abc() {

                getIdTokenClaims().then((claims) => {
                    // console.log('Claims:', claims.__raw);
                    localStorage.setItem("id_token", claims.__raw);
                  });


                const res = await axios.get(configJson.AUTH_USER_DETAILS + sub, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                if (res.data.user_metadata) {

                    const UMD = JSON.stringify(res.data.user_metadata)
                    const re = /'/gi;
                    // console.log("UMD" + JSON.parse(UMD).weightData.replace(re,"") )
                    localStorage.setItem("user_metadata", UMD)
                    localStorage.setItem("weightData", JSON.parse(UMD).weightData.replace(re,"") )
                    setMetaData(res.data.user_metadata.appointmentID)
                }

                if (res.data.app_metadata) {
                    localStorage.setItem("stripe_customer_id",res.data.app_metadata.stripe_customer_id)
                    setstripe_customer_id(res.data.app_metadata.stripe_customer_id)    
                }else{
                    try {
                        const token = configJson.STRIPE_TOKEN
                        const UMD = localStorage.getItem("user_metadata")
                        // console.log("UMD: " + UMD)
                        const re = /'/gi;
                        const usr_d =  JSON.parse( JSON.parse(UMD).userData.replace(re,""))
                        // alert(usr_d.email)

                        axios.get("https://api.stripe.com/v1/customers/search?query=email:" + "'" +usr_d.email+ "'", {
                            headers: {
                                "Authorization": `Bearer ${token}`
                            }
                        }).then(
                            (res) => {
                                localStorage.setItem("stripe_customer_id", res.data.data[0].id)
                                setstripe_customer_id(res.data.data[0].id)
                                // alert()
                                let customer = res.data.data[0].id;
                                axios.get(`https://api.stripe.com/v1/subscriptions?customer=${customer}`, {
                                headers: {
                                    "Authorization": `Bearer ${token}`,
                                    "Content-Type": "application/x-www-form-urlencoded",
                                },
                                }).then((subsRes) => {
                                    if (subsRes.data.data[0] != null) {
                                        setSubscriptionDetail(subsRes.data.data[0]); // Save the subsRes data in state
                                        // openModal(); // Open the subsRes modal
                                        // setSubscriptionId(subsRes.data.data[0].id)
                                      } else {
                                        setSubscriptionDetail({ status: "Unsubscribed", current_period_start: "", current_period_end: "", id: "" });
                                      }
                                })


                            }
                        )
                    } catch (error) {
                        
                    }
                    
                }
                

                if (res.data.user_metadata.Patientid) {
                    localStorage.setItem("Patientid", res.data.user_metadata.Patientid)
                }
                
                // alert(res.data.user_metadata.appointmentID)
                if (res.data.user_metadata.appointmentID != '') {
                    setCHECKAPPOINTMENT("CONFIRMED")
                }


                if (res.data.user_metadata.questionComplete === "false") {
                    swal({
                        title: "Registration Incomplete",
                        text: "Please complete the registration process",
                        icon: "warning",
                        buttons: "Go to Questions",
                    }).then(() => {
                        navigate('/weightloss-qnaire');
                    });
                }
                
                
            }
            abc()
        } else {
            if (localStorage.getItem("paymentSuccess")) {
                if (localStorage.getItem("paymentSuccess") == "true") { // alert("local")
                    const token = configJson.AUTH_MANAGEMENT_TOKEN
                    axios.patch(configJson.AUTH_USER_DETAILS + localStorage.getItem("AuthUserSub"), {
                        "user_metadata": {
                            "questionComplete": "true",
                            "weightData": "'" + localStorage.getItem("weightData") + "'"
                        }
                    }, {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    })
                    localStorage.removeItem("paymentSuccess")
                }
            }

            if (localStorage.getItem("user_metadata")) {

                setMetaData(JSON.parse(localStorage.getItem("user_metadata")))
                const metaData2 = JSON.parse(localStorage.getItem("user_metadata"))

                if (metaData2.appointmentID != "") {
                    setCHECKAPPOINTMENT("CONFIRMED")
                }




            }
            if (localStorage.getItem("stripe_customer_id")) {
                

                setstripe_customer_id(localStorage.getItem("stripe_customer_id"))
            }else{

                const token = configJson.STRIPE_TOKEN
                const UMD = localStorage.getItem("user_metadata")
                // console.log("UMD: " + UMD)
                const re = /'/gi;
                const usr_d =  JSON.parse( JSON.parse(UMD).userData.replace(re,""))
                // alert(usr_d.email)

                axios.get("https://api.stripe.com/v1/customers/search?query=email:" + "'" +usr_d.email+ "'", {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }).then(
                    (res) => {
                        localStorage.setItem("stripe_customer_id", res.data.data[0].id)
                        setstripe_customer_id(res.data.data[0].id)
                        // alert()
                    }
                )

            }
            

            
            

            // const metaDataLocal = JSON.parse()


        }


    }

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        //   alert(tab)
    };

   

    
    useEffect(() => {
        CheckRedirect()
        if (stripe_customer_id) {
            // check appointment status
            
            const token = configJson.STRIPE_TOKEN;
            axios.get(`https://api.stripe.com/v1/subscriptions?customer=${stripe_customer_id}`, {
              headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/x-www-form-urlencoded",
              },
            })
              .then((response) => {
                // alert(response.data.data[0])
                if (response.data.data[0] != null) {
                    
                  setSubscriptionDetail(response.data.data[0]); // Save the response data in state
                  // openModal(); // Open the response modal
                //   setSubscriptionId(response.data.data[0].id)  
                }else{
                  setSubscriptionDetail({status: "Unsubscribed",current_period_start: "" , current_period_end: "", id:""});
                }
                // console.log("Price",response.data.data)
                
                // console.log('Subscription id:', response.data.data[0].id);
                // Handle successful subscription creation here, e.g., show a success message.
              })
        }else{
            // setSubscriptionDetail({status: "Unsubscribed",current_period_start: "" , current_period_end: "", id:""});
        }




      }, [stripe_customer_id]);

// console.log("SubscriptionDetail: "  + JSON.stringify(SubscriptionDetail))
    return (
        <div className="health-main-container">
            <DashboardNavBar></DashboardNavBar>
            <div>
                <div>
                    <div className="dashboard-header">
                        {/* <div className="logo-container">
                            <img src={
                                AppImage.pepstepVerticalLogoImage
                            }
                                style={
                                    {
                                        width: '30.0rem',
                                        height: '34.0rem',
                                        marginTop: '1.0rem'
                                    }
                                } />
                        </div> */}
                        <div className="module-container-one">
                            <ul className="module-container-one" style={{ marginTop: '0.0rem' }}>
                                <li
                                    className={`module-container ${activeTab === 'schedule' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('schedule')}
                                >
                                    <div >
                                        <img
                                            src={AppImage.scheduleImage}
                                            alt="Schedule"
                                            className="dashboard-img-one"
                                        />
                                    </div>
                                    <span className="btns">Schedule</span>
                                </li>
                                {/* <li
                                    className={`module-container ${activeTab === 'message' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('message')}
                                >
                                    <div style={{ width: '12.8rem', height: '12.8rem', alignItems: 'center' }}>
                                        <img
                                            src={AppImage.messageImage}

                                            alt="Message"
                                        />
                                    </div>

                                    <span className="btns">Message</span>
                                </li> */}
                                <li
                                    className={`module-container ${activeTab === 'tracker' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('tracker')}
                                >
                                    <div >
                                        <img
                                            src={AppImage.TrackIcons}
                                            className="dashboard-img-one"
                                            alt="tracker"
                                        />
                                    </div>

                                    <span className="btns">Track your success</span>
                                </li>
                                <li
                                    className={`module-container ${activeTab === 'faq' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('faq')}
                                >
                                    <div>
                                        <img
                                            src={AppImage.faqImage}
                                            className="dashboard-img-one"
                                            alt="FAQ"
                                        />
                                    </div>

                                    <span className="btns">FAQ</span>
                                </li>
                            </ul>


                        </div>

                    </div>
                </div>
                {activeTab === 'schedule' && (
                    <div className="tab-content">
                        {/* { alert(activeTab) } */}
                        {
                            SubscriptionDetail.status == "active" || SubscriptionDetail.status=="trialing" ? 
                            <ScheduleDesign /> : SubscriptionDetail.status == "Unsubscribed" ? <ReSubscribe /> : ""
                        }
                    </div>
                )}
               
                {activeTab === 'tracker' && (
                    <div className="tab-content">
                        {/* Insert Tracker content here */}
                        {< Track messages={messages} />}
                    </div>
                )}
                {activeTab === 'faq' && (
                    <div className="tab-content">
                        {/* Insert FAQ content here */}
                        <FAQ />
                    </div>
                )}

                

                <Footer></Footer>
            </div>






        </div>
    )
}
export default Dashboard;
