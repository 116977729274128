import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    CButton,
    CCol,
    CContainer,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CRow,
  } from '@coreui/react'
//   import CIcon from '@coreui/icons-react'
//   import { cilMagnifyingGlass } from '@coreui/icons'
  

const Page404 = () => {

    const navigate = useNavigate()


    useEffect(() => {
        setTimeout(() => {
            navigate('/')
        }, 2000);
    }, [])


    return (
      
        


    <div className="bg-light min-vh-100 d-flex flex-row align-items-center" >
      <CContainer >
        <CRow className="justify-content-center">
          <CCol md={6}>
            <div className="clearfix" style={{ fontSize:"30px" }}>
              <h1 className="float-start display-3 me-4" style={{ fontSize:"50px"}}>404</h1>
              <h4 className="pt-3" style={{ fontSize:"30px" , fontWeight:"bold"}}>Oops! You{"'"}re lost.</h4>
              <p className="text-medium-emphasis float-start" style={{ fontWeight:"30px" }}>
                The page you are looking for was not found.
              </p>
            </div>
           
          </CCol>
        </CRow>
      </CContainer>
    </div>


           
       
    )
}

export default Page404 