import '../../../src/utils/page-css/pagegupOne.css'
import { AppImage } from '../../theme/app.images';

const Footer = () => {
    return (
        <div className="main-footer">

            <div className='footer-content'>
                <img
                    className='img'
                    src={AppImage.footerLogoImage}
                />
                <div className='line' />
                <div className='footer-column'>
                    <p className='footer-heading'>Good to know</p>
                    <p className='footer-subtitle'>Pepstep is not intended to diagnose, treat, cure, or prevent any disease and that results may vary from person to person.</p>

                    <div className='footer-links'>
                        <a
                            target='_blank'
                            href={'/privacy-policy'}
                            className='footer-privacy'>Privacy Policy</a>
                        <div style={{
                            backgroundColor: 'white',
                            width: '1.2px',
                            height: '18.5px', marginLeft: '2.0rem', marginRight: '2.0rem'
                        }}></div>
                        <a
                            target='_blanks'
                            href={'/terms-of-use'}
                            className='footer-privacy'>Terms of Use</a>

                    </div>
                </div>

            </div>


        </div>
    )
}
export default Footer;