import '../../src/utils/page-css/pagegupOne.css';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import React, { useState,useEffect } from 'react';
import configJson from "../auth_config.json";
import axios from "axios"

const EligibleScreen = () => {

    const [userData, setUserData] = useState(null);
    const [productData, setProductData] = useState(null);

    async function fetchUserData() {
        const token = configJson.AUTH_MANAGEMENT_TOKEN;
        try {
          const response = await axios.get(configJson.AUTH_USER_DETAILS + localStorage.getItem("AuthUserSub"), {
            headers: {
              "Authorization": `Bearer ${token}`
            }
          });
          // console.log("hgfdsadfghgf",response.data.app_metadata.stripe_customer_id)
          setUserData(response.data.app_metadata.stripe_customer_id); // Set the response data in the state
          localStorage.setItem("stripe_customer_id",response.data.app_metadata.stripe_customer_id)
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }

    async function fetchProductData() {
        try {
          // Replace 'YOUR_STRIPE_SECRET_KEY' with your actual Stripe Secret Key
          const token = configJson.STRIPE_TOKEN;
          const response = await axios.get('https://api.stripe.com/v1/products', {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
    
          // Assuming the response contains an array of products, you can set the first product in the state
          if (response.data && response.data.data && response.data.data.length > 0) {
            setProductData(response.data.data[0]);
            // console.log("sss",response.data.data[0])
        
          }
        } catch (error) {
          console.error('Error fetching product data:', error);
        }
      }


      async function questioncompletetrue() {
        const weightData = {
          date: new Date(),
          weight: localStorage.getItem("weight")
        }
        try {
            // Replace 'YOUR_STRIPE_SECRET_KEY' with your actual Stripe Secret Key
            const token = configJson.AUTH_MANAGEMENT_TOKEN
            axios.patch(configJson.AUTH_USER_DETAILS + localStorage.getItem("AuthUserSub"), {
                "user_metadata": {
                    "questionComplete": "true",
                    "weightData": "'" + localStorage.getItem("weightData") + "'"
                    
                }

            }, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    }


      useEffect(() => {
        questioncompletetrue()
        fetchUserData()
        fetchProductData();
      }, [])
      

    const handlePayment = async () => {
        // localStorage.setItem("",productData.default_price)
      const formData = {
        cancel_url: window.location.protocol + '//' + window.location.host + '/cancelPayment',
        success_url: window.location.protocol + '//' + window.location.host + "/successPayment",
        line_items: [
          {
            price: productData.default_price,
            quantity: 1,
          },
        ],
        mode: "subscription",
        automatic_tax: {
          enabled: true,
        },
        allow_promotion_codes: true,
        customer: userData,
        subscription_data: {
          trial_period_days: 60,
        },
      
        customer_update: {
          address: "auto",
        },
        billing_address_collection: "auto",
      };
    
      try {
        const token = configJson.STRIPE_TOKEN;
        const response = await axios.post(configJson.STRIPE_SESSION, formData, {
          headers: {
            "Authorization": `Bearer ${token}`,
            'content-type': 'application/x-www-form-urlencoded'
          }
        });
    
        if (response.status === 200) {
          // Redirect to the payment page
          window.location.href = response.data.url;
        }
      } catch (error) {
        console.error('Payment Error:', error.response.data);
        // Handle payment error here (show an error message to the user, etc.)
      }
    };
    return (
        <>

            <div className='elibile-main-container'>

                <div className='eligible-colum-content-container'>
                  <p className='elibile-heading-text'>Congratulations!</p>
                  <p className='elibile-sub-text'>You have initially qualified for semaglutide assisted weight loss therapy. Please proceed to the next page to access our portal and complete payment to book a meeting with one of our practitioners.</p>
                    <div className='elibile-continue-button' onClick={()=>{handlePayment()}}>   
                        <a className='register-now-text'>CONTINUE</a>
                        <div style={{
                            backgroundColor: '#0E7CCF', width: '5.0rem', alignSelf: 'center', alignItems: 'center',
                            height: '5.0rem', borderTopRightRadius: '2.0rem', borderBottomRightRadius: '2.0rem',
                        }}>
                            <KeyboardArrowRightIcon
                                style={{
                                    alignSelf: 'center', marginTop: '0.8rem', marginLeft: '1.0rem',
                                    width: '3.0rem', height: '3.0rem', color: 'white'
                                }}
                            />

                        </div>
                    </div>
                </div>

            </div>
        </>

    )
}

export default EligibleScreen;