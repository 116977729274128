import { stringValue } from '../theme/string';
import '../utils/page-css/pagegupOne.css';
import PublicNavHeader from '../utils/public-header/PublicNavHeader';
import Footer from '../utils/footer/Footer';
import { AppImage } from '../theme/app.images';
import { useState } from 'react';
import { is } from 'date-fns/locale';







const TermsOfUse = (props) => {

    const [isTermsHideShow, setIsTermsHideShow] = useState(false);

    const func = () => {
        console.log("terms")

        setIsTermsHideShow(true);
        console.log(!isTermsHideShow);
    }


    return (

        <>
            {
                isTermsHideShow === false ? (
                    <div className='terms-zindex-container'>
                        <div className='terms-content-container'>
                            <div className='privacy-content'>
                                <div style={{
                                    flexDirection: 'row', display: 'flex',
                                    justifyContent: 'space-between', alignItems: 'center',
                                    width: '53vw', backgroundColor: '#8DB9DE'
                                }}>
                                    <a></a>
                                    <h1 className='privacy-h1-text-style'>Terms of Use for Pepstep</h1>
                                    <img
                                        onClick={func}
                                        src={AppImage.closeWhiteImg} className='close-icon-img' />

                                </div>
                                <div className='terms-divider-line'></div>

                                <p className='privacy-small-text-style'>Last updated: June 25, 2023</p>
                                <p className='privacy-mid-text-style'>{stringValue.welcomeToPepstepPlatform}</p>
                                <p className='privacy-mid-text-style'>{stringValue.allPhysicsNurse}<b>Authorized Clinicians</b>”) are independent, regulated health professionals registered to practice medicine in a Province or Territory of Canada.</p>
                                <p className='privacy-mid-text-style'>If you do not agree to these Terms, please do not access or use our Services and cancel any existing services with Pepstep.</p>
                                <h2 className='privacy-h2-text-style'>Eligibility</h2>
                                <p className='privacy-mid-text-style'>{stringValue.youMustBeAtleast18Year}</p>
                                <h2 className='privacy-h2-text-style'>Privacy Disclosure</h2>
                                <p className='privacy-mid-text-style'>{stringValue.thePersonalInformationYou}</p>
                                <h2 className='privacy-h2-text-style'>Registration and Account</h2>
                                <p className='privacy-mid-text-style'>{stringValue.toAccessSomeFeature}</p>

                                <p className='privacy-mid-text-style'>{stringValue.youUnderstandThatYour}<a href='compliance@pepstep.ca'>compliance@pepstep.ca</a></p>
                                <h2 className='privacy-h2-text-style'>License and Restrictions</h2>
                                <p className='privacy-mid-text-style'>We grant you a limited, non-exclusive, non-transferable, and revocable license to use the Services for your personal and non-commercial use only, subject to these Terms and our policies. You may not:</p>

                                <ul className='privacy-list'>
                                    <li className='privacy-list-marker'>copy, modify, distribute, sell, or lease any part of the Services or the content on the Services;</li>
                                    <li className='privacy-list-marker'>reverse engineer or attempt to extract the source code of the Services;</li>
                                    <li className='privacy-list-marker'>use the Services for any illegal, fraudulent, or harmful purpose;</li>

                                    <li className='privacy-list-marker'>interfere with or damage the Services or our servers or networks;</li>
                                    <li className='privacy-list-marker'>violate or infringe the rights of any third party, including intellectual property, privacy, or contractual rights;</li>
                                    <li className='privacy-list-marker'>post, upload, or transmit any content that is unlawful, defamatory, obscene, abusive, hateful, harassing, threatening, or otherwise objectionable;</li>

                                    <li className='privacy-list-marker'>impersonate any person or entity or misrepresent your affiliation with any person or entity;</li>
                                    <li className='privacy-list-marker'>send unsolicited or unauthorized advertising, promotional materials, spam, or any other form of solicitation through the Services;</li>
                                    <li className='privacy-list-marker'>collect or store any personal information about other users without their express consent.</li>

                                </ul>
                                <h2 className='privacy-h2-text-style'>Intellectual Property Rights</h2>
                                <p className='privacy-mid-text-style'>{stringValue.weOwnAllRight}</p>
                                <p className='privacy-mid-text-style'>{stringValue.youRetainAllRightInAndToANy}</p>

                                <h2 className='privacy-h2-text-style'>Telehealth and Electronic Communication</h2>
                                <p className='privacy-mid-text-style'>You agree to be communicated with via the following methods when interacting with Pepstep and its affiliate subsidiaries:</p>
                                <p className='privacy-mid-text-style'>Email, telephone, text messaging, social media, website portal and video conferencing (“<b>electronic communication</b>”)</p>
                                <p className='privacy-mid-text-style'>The means of being contacted by and interacting via video and audio technology (“<b>telehealth</b>"){stringValue.willBeUsedForTheDelivery}</p>
                                <p className='privacy-mid-text-style'>{stringValue.youAreResponsible}</p>
                                <p className='privacy-mid-text-style'>{stringValue.althoughThePepstep}</p>
                                <p className='privacy-mid-text-style'>{stringValue.ifYouSendAnElectronic}</p>
                                <p className='privacy-mid-text-style'>{stringValue.youAreAccountable}</p>
                                <p className='privacy-mid-text-style'>{stringValue.theAuthorizedClinicians}</p>
                                <h2 className='privacy-h2-text-style'>Platform Content</h2>
                                <p className='privacy-mid-text-style'>{stringValue.byUsingThePepstepPlatform}</p>
                                <h2 className='privacy-h2-text-style'>Payment and Fees</h2>
                                <p className='privacy-mid-text-style'>{stringValue.somefeatureOfService}</p>
                                <p className='privacy-mid-text-style'>{stringValue.weuseThirdParty}</p>
                                <h2 className='privacy-h2-text-style'>Cancellation and Refunds</h2>
                                <p className='privacy-mid-text-style'>{stringValue.youMaycancelYourSubscription}</p>
                                <p className='privacy-mid-text-style'>{stringValue.weMayCancelOrSuspend}</p>

                                <h2 className='privacy-h2-text-style'>What you need to know before using our virtual clinic</h2>
                                <p className='privacy-mid-text-style'>{stringValue.weGladYouChose}</p>
                                <p className='privacy-mid-text-style'>{stringValue.medicalDiagnosisTreatment}</p>
                                <p className='privacy-mid-text-style'>{stringValue.AsWithAnyOthermedical}</p>
                                <p className='privacy-mid-text-style'>{stringValue.thePepstepDoctorMayNot}</p>
                                <p className='privacy-mid-text-style'>{stringValue.prescriptionsForMedications}</p>
                                <p className='privacy-mid-text-style'>{stringValue.certainMedications}</p>
                                <p className='privacy-mid-text-style'>{stringValue.ihaveTheRightRefuse}</p>
                                <p className='privacy-mid-text-style'>{stringValue.iUnderstandThatPepstep}</p>
                                <p className='privacy-mid-text-style'>{stringValue.pepstepNothealthCare}</p>

                                <h2 className='privacy-h2-text-style'>How we protect your privacy and personal health information</h2>
                                <p className='privacy-mid-text-style'>{stringValue.weTakeYourPrivacy}</p>
                                <h2 className='privacy-h2-text-style'>How we ensure quality and safety of care</h2>
                                <p className='privacy-mid-text-style'>{stringValue.weWorkWithlicensed}</p>
                                <p className='privacy-mid-text-style'>{stringValue.whenYouuseOurService}</p>
                                <p className='privacy-mid-text-style'>{stringValue.pleaseNotThatTelemedicine}</p>
                                <p className='privacy-mid-text-style'>{stringValue.youAreResponsibleProviding}</p>
                                <h2 className='privacy-h2-text-style'>How we verify your identity</h2>
                                <p className='privacy-mid-text-style'>{stringValue.toUseOurServiceYouNeed}</p>
                                <p className='privacy-mid-text-style'>{stringValue.weMayAlsoAskYou}</p>

                                <h2 className='privacy-h2-text-style'>Disclaimer of Warranties</h2>
                                <p className='privacy-mid-text-style'>{stringValue.thisServiceProvided}</p>
                                <h2 className='privacy-h2-text-style'>Limitation of Liability</h2>
                                <p className='privacy-mid-text-style'>{stringValue.toTheFullest}</p>
                                <p className='privacy-mid-text-style'>Some jurisdictions do not allow the exclusion or limitation of certain damages, so some or all of the above exclusions or limitations may not apply to you.</p>
                                <h2 className='privacy-h2-text-style'>Indemnification</h2>
                                <p className='privacy-mid-text-style'>{stringValue.youAreAgreeIndemnify}</p>
                                <h2 className='privacy-h2-text-style'>Dispute Resolution</h2>
                                <p className='privacy-mid-text-style'>{stringValue.theseTermsAndDispute}</p>
                                <h2 className='privacy-h2-text-style'>How we Update These Terms</h2>
                                <p className='privacy-mid-text-style'>{stringValue.weMayReviseUpdate}</p>
                                <h2 className='privacy-h2-text-style'>Conatct us</h2>
                                <p className='privacy-mid-text-style'>If you have any questions, comments, or complaints about Terms or the services, please contact us at:</p>
                                <p className='privacy-mid-text-style'>Email: <a href='support@pepstep.com'><u>support@pepstep.com</u></a></p>
                                <p className='privacy-mid-text-style'>Address: Unit #130 200 Rivercrest Dr SE, Calgary, AB T2C 2X5, Canada</p>
                                <p className='privacy-mid-text-style'>We will attempt to respond to your inquiries within a reasonable time frame and in accordance with our legal obligations and will advise users in writing if this cannot be met.</p>
                                <div style={{ height: '2rem' }}></div>


                            </div>
                        </div>


                    </div>
                ) : (
                    <div></div>
                )
            }
        </>



    )
}
export default TermsOfUse;