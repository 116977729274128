import '../../src/utils/page-css/pagegupOne.css';
import React, { useState } from 'react';
import { useCollapse } from 'react-collapsed';
import SidebarItems from '../components/SidebarItems';
import { stringValue } from '../theme/string';




const FAQ = () => {
    const [activeItem, setActiveItem] = useState(null);
  
    const faqData = [
      {
        question: stringValue.faqWhatIsEstimated,
        answer: stringValue.faqTheEstimatedTimelineForContent,
      },
      {
        question: stringValue.faq2QustnHowCanI,
        answer: stringValue.faq2AnsToCancelYourSubscription,
      },
      {
        question: stringValue.faq3QstnIfIDecide,
        answer: stringValue.faq3AnsNoOnceYouCancel,
      },
      {
        question: stringValue.faq4QstnWhatShouldI,
        answer: stringValue.faq4AnsIfYouExperince,
      },
      {
        question: stringValue.faq5QstnAreThereAnyAdditional,
        answer: stringValue.faq5AnsPepstepDoesNotCharge,
      },
      {
        question: stringValue.faq6QstnCanIModify,
        answer: stringValue.faq6AnsYesYouCanRequest,
      },
      {
        question: stringValue.faq7QstnIsThereSpecific,
        answer: stringValue.faq7AnsYourMedicalPractitioner,
      },
      {
        question: stringValue.faq8QstnWhatShouldIDo,
        answer: stringValue.faq8AnsYouMayExperience,
      },
      {
        question:stringValue.faq9QstnHowCanIReach,
        answer:stringValue.faq9AnsYouCanReachOut,
      },
      {
        question:stringValue.faq10QstnAreThereAnyDietary,
        answer:stringValue.faq10AnsYesYourMedical,
      },
      {
        question:stringValue.faq11QstnWhatMeasureAreIn,
        answer:stringValue.faq11AnsPepstepTakesData,
      },
      {
        question:stringValue.faq12QstnIsThereOption,
        answer:stringValue.faq12AnsYesYoucanReschedule,
      },
      {
        question:stringValue.faq13QstnAreThereAnyResource,
        answer:stringValue.faq13AnsAbsolutely,
      },

    ];
  
    function handleItemClick(index) {
      setActiveItem(activeItem === index ? null : index);
    }
  
    return (
      <div className="faq-main-container">
        <p className="faq-heading-text">FAQs</p>
  
        <div style={{ marginTop: '3.0rem' }}>
          {faqData.map((faq, index) => (
            <SidebarItems
              key={index}
              title={faq.question}
              children={faq.answer}
              isExpanded={activeItem === index}
              onToggle={() => handleItemClick(index)}
            />
          ))}
          <div style={{ height: '3.0rem' }}></div>
        </div>
      </div>
    );
  };
  
  export default FAQ;