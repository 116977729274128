import { Oval } from 'react-loader-spinner'



const AppLoader = () => {

    return (
        <div className='loader-zindex-container'>
            <Oval
                height={150}
                width={150}
                color="#191F4D"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#8DB9DE"
                strokeWidth={3}
                strokeWidthSecondary={2}

            />
        </div>

    );
}
export default AppLoader;