import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import Button from 'react-bootstrap/Button';
import configJson from "../auth_config.json";
import axios from 'axios'
const Track = () => {
  // let weightDataLocal = {
  //   date: new Date() - 3,
  //   weight: 0
  // }
  const [windowWidth, setwindowWidth] = useState([0])
  const [weightData, setWeightData] = useState([]);
  const [weight, setWeight] = useState('');
  const [date, setDate] = useState(new Date());
  let [wCheck, setwCheck] = useState(0)
  let [CheckNew, setCheckNew] = useState(0)

  
  const handleSaveWeight = () => {
    if (weight) {
      const newWeightEntry = { date: date.toLocaleDateString(), weight: Number(weight) };
      setWeightData([...weightData, newWeightEntry]);
      setWeight('');
      setDate(new Date());
      setCheckNew(1)
      // updateAuthMetaData()
    }
  };

  const formatXAxis = (tickItem) => {
    const dateObject = new Date(tickItem);
    return `${dateObject.getMonth() + 1}/${dateObject.getDate()}`;
  };

  const getFutureDates = (startDate, count) => {
    const futureDates = [];
    // futureDates.push(date.setDate(date.getDate() + 1));
    for (let i = 1; i <= count; i++) {
      const nextDate = new Date(startDate);
      nextDate.setDate(startDate.getDate() + i);
      futureDates.push(nextDate);
    }
    return futureDates;
  };

  const futureDates = getFutureDates(date, 10);
  // console.log("weightData: 00" + JSON.stringify(weightData))

  
  
    
  
    function updateAuthMetaData(){
      if (CheckNew == 1) {
        const token = configJson.AUTH_MANAGEMENT_TOKEN
        axios.patch(configJson.AUTH_USER_DETAILS + localStorage.getItem("AuthUserSub"), {
            "user_metadata": {
                "weightData": "'" + JSON.stringify(weightData) + "'"
            }
        }, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        localStorage.setItem("weightData" , JSON.stringify(weightData))
      }
      
      if (wCheck == 0) {
        // alert(wCheck)
        if (localStorage.getItem("weightData")) {
          const localWeightData = JSON.parse(localStorage.getItem("weightData"))
          console.log("weightData : " + JSON.stringify(localWeightData))
          setWeightData(...weightData, 
            localWeightData.map((items) => ({
              date: new Date(items.date),
              weight: items.weight
            }) 
            ))
        }
        // console.log("weightData: " + weightData)
        setwCheck(1)
      }
      
      
  
    }  
    




  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentDate = new Date();
      if (currentDate.getDate() !== date.getDate()) {
        setDate(currentDate);
      }
    }, 1000); // Check for day change every 1 second
    
    


    return () => {
      clearInterval(intervalId);
    };
  }, [date, updateAuthMetaData()]);

  // Calculate the minimum and maximum weight values for setting Y-axis domain
  const weightValues = weightData.map((data) => data.weight).filter((weight) => weight !== null);
  const minY = weightValues.length ? Math.min(...weightValues) : 0;
  const maxY = weightValues.length ? Math.max(...weightValues) : 100;


  useEffect(() => {
    function updateSize() {
      setwindowWidth([window.innerWidth]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    console.log('windowFarman....' + windowWidth)
    return () => window.removeEventListener('resize', updateSize);
  }, [])

  

  return (
    <div style={{ maxWidth: '70.0rem', margin: '0 auto', padding: '2.0rem' }}>
      <div>
        <h1>Check-In</h1>
        <label htmlFor="weight"><h2>Please enter your current weight every 14 days to track your success.</h2></label>
      </div>
      <div style={{ flexDirection: 'row', display: 'flex' }}>
        <input
          style={{ height: '40px', fontSize: '12px', paddingLeft: '12px' }}
          type="number"
          id="weight"
          value={weight}
          placeholder='lbs'
          onChange={(e) => {
            // Check if the input value has more than 4 digits
            if (e.target.value.length <= 4) {
              setWeight(e.target.value);
            }
          }}
        />
        <div className={'save-button-dark'}
          onClick={handleSaveWeight}
        >
          <a className='signup-btn-text' >SAVE</a>
        </div>
      </div>
      <div className='liner-chart-container'>
        <LineChart
           width={windowWidth > 800 ? 500 : 300}
           height={300}
          data={weightData.concat(futureDates.map(date => ({ date: date.toLocaleDateString(), weight: null })))}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={formatXAxis} />
          <YAxis domain={[minY, maxY]} />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="weight" stroke="orange" activeDot={{ r: 8 }} />
        </LineChart>
      </div>
      <div style={{height:'10.0rem'}}></div>
    </div>
  );
};

export default Track;
