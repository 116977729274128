

export const AppImage = {

    bgImage: 'assets/images/bgImage.jpg',
    headerLogoImage: 'assets/logo.png',
    footerLogoImage: 'assets/footer-logo.png',
    pepstepVerticalLogoImage: 'assets/pepstep-vertical-logo.png',
    arrowRightIcon: 'assets/icons/right-arrow.png',
    arrowRightWhiteIcon: 'assets/icons/right-arrow-white.png',
    faqImage: 'assets/images/faq.png',
    scheduleImage: 'assets/images/shecdule.png',
    messageImage: 'assets/images/message.png',
    rightCheckIcon: 'assets/icons/check-mark.png',
    TrackIcon: 'assets/images/tracker.png',
    TrackIcons: 'assets/images/trackers.png',
    closeIcon: 'assets/icons/close-iocn.png',
    closeWhiteImg: 'assets/images/close-white-img.png',
    avatarImage: 'assets/images/avatar.png',
    avatarWomenImage: 'assets/images/woman-img.png',
    closeEyeImage: 'assets/images/close-eye.png',
    cancelSubscriptionImage: 'assets/images/cancel-subscription.png',
    paymentImage: 'assets/images/payment-img.png',
    subscriptionImage: 'assets/images/subscription.png',
    otherGenderImage: 'assets/images/circle-logo.png',


}