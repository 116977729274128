import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'

const PrivateRoute = () => {

  
    const id_token = localStorage.getItem('id_token')
    // console.log(id_token)

    let Authenticated =false;
    var url_string = window.location.href; 
    var url = new URL(url_string);
    var c = url.searchParams.get("code");
    var s = url.searchParams.get("state");

    if (c && s) {
      Authenticated = true
    }
    if (id_token) {
      Authenticated = true
    }
 
  return (
    <div>
         {Authenticated ? <Outlet /> : <Navigate to="/" />}
         </div>
  )
}

export default PrivateRoute