
export const GlobalThemeColor = {
    appColor: {
        primaryColor: '#8DB9DE',
        primaryTextColor: '#191F4D',
        redColor: '#EE374D',
        darkBlueColor: '#191F4D',
        whiteColor: '#FFFFFF',
        headingColor: '#191F4D',
        bgColor: '#F2F3F4',
        blackColor: '#000000'
    },
    media: { mobile: '1200px', tab: '998px' },



}