// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

html {
  /* overflow-x: hidden; */
  font-size: 62.5%;
  font-family: 'Nunito Sans';
  overflow-x: hidden;
}

input {
  font-size: 1.6 rem;
}

input:focus {
  font-size: 1.6rem;
}

a {
  text-decoration: none !important;
}

body {
  width: 100vw;
  height: 100vh !important;
}

li {


  list-style: none;

}

@media(max-width:998px) {

  html {
    font-size: 62.5%;
  }

}

@media(max-width:768px) {

  html {
    font-size: 45%;
  }

}

@media (max-width: 580px) {
  body {
    overflow-x: hidden;
    height: 100vh !important;
  }

}

@media screen and (-webkit-min-device-pixel-ratio:0) {

  select,
  textarea,
  input {
    font-size: 1.6rem;
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {

  select:focus,
  textarea:focus,
  input:focus {
    font-size: 1.6rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;;AAExB;;AAEA;EACE,wBAAwB;EACxB,gBAAgB;EAChB,0BAA0B;EAC1B,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,YAAY;EACZ,wBAAwB;AAC1B;;AAEA;;;EAGE,gBAAgB;;AAElB;;AAEA;;EAEE;IACE,gBAAgB;EAClB;;AAEF;;AAEA;;EAEE;IACE,cAAc;EAChB;;AAEF;;AAEA;EACE;IACE,kBAAkB;IAClB,wBAAwB;EAC1B;;AAEF;;AAEA;;EAEE;;;IAGE,iBAAiB;EACnB;AACF;;AAEA;;EAEE;;;IAGE,iBAAiB;EACnB;AACF","sourcesContent":["* {\r\n  margin: 0;\r\n  padding: 0;\r\n  box-sizing: border-box;\r\n\r\n}\r\n\r\nhtml {\r\n  /* overflow-x: hidden; */\r\n  font-size: 62.5%;\r\n  font-family: 'Nunito Sans';\r\n  overflow-x: hidden;\r\n}\r\n\r\ninput {\r\n  font-size: 1.6 rem;\r\n}\r\n\r\ninput:focus {\r\n  font-size: 1.6rem;\r\n}\r\n\r\na {\r\n  text-decoration: none !important;\r\n}\r\n\r\nbody {\r\n  width: 100vw;\r\n  height: 100vh !important;\r\n}\r\n\r\nli {\r\n\r\n\r\n  list-style: none;\r\n\r\n}\r\n\r\n@media(max-width:998px) {\r\n\r\n  html {\r\n    font-size: 62.5%;\r\n  }\r\n\r\n}\r\n\r\n@media(max-width:768px) {\r\n\r\n  html {\r\n    font-size: 45%;\r\n  }\r\n\r\n}\r\n\r\n@media (max-width: 580px) {\r\n  body {\r\n    overflow-x: hidden;\r\n    height: 100vh !important;\r\n  }\r\n\r\n}\r\n\r\n@media screen and (-webkit-min-device-pixel-ratio:0) {\r\n\r\n  select,\r\n  textarea,\r\n  input {\r\n    font-size: 1.6rem;\r\n  }\r\n}\r\n\r\n@media screen and (-webkit-min-device-pixel-ratio:0) {\r\n\r\n  select:focus,\r\n  textarea:focus,\r\n  input:focus {\r\n    font-size: 1.6rem;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
